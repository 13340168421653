import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as qs from "qs";
import axiosApi from "../../utils/httpClient";
const name = "receivedToIndividual";
const initialState = {
  receivedToIndividual: [],
  loading: false,
  error: null,
};

export const receivedToIndividualAPI = createAsyncThunk(
  "receivedToIndividual",
  async ({ search, skip, limit, country, status, stage }) => {
    var formData = {};
    if (country) {
      formData["country"] = country;
    }

    if (stage?.length > 0) {
      formData["stage"] = stage;
    }
    if (country?.length > 0) {
      formData["country"] = country;
    }
    if (status) {
      if (status == "Submited") {
        formData["status"] = "received";
      } else if (status == "Approved") {
        formData["status"] = "approved";
      } else if (status == "Rejected") {
        formData["status"] = "rejected";
      }
    }
    const data = await axiosApi.get(
      `${
        process.env.REACT_APP_BASE_URL
      }application/individual/received/?skip=${skip}&limit=${limit}${
        search != "" ? `&search=${search}` : ""
      }`,
      {
        params: formData,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      }
    );
    return data.data;
  }
);

const receivedToIndividualSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(receivedToIndividualAPI.pending, (state) => {
      state.receivedToIndividual = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(receivedToIndividualAPI.fulfilled, (state, action) => {
      state.receivedToIndividual = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(receivedToIndividualAPI.rejected, (state, action) => {
      state.receivedToIndividual = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default receivedToIndividualSlice.reducer;
