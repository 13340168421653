import React from 'react'
import { Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import BottomNav from '../BottomNav'
import { Box } from '@mui/material'

function HeaderLessLayout() {
	let store = useSelector((store) => store)
	return (
		<React.Fragment>
			<Box sx={{ pb: 7 }}>
				{store.dimension.width < 600 && <BottomNav />}
				<Outlet />
			</Box>
		</React.Fragment>
	)
}
export default HeaderLessLayout
