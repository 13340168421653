import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const name = "selectedResponseFilter";
const initialState = {
  selectedResponseFilter: "All",
  loading: false,
  error: null,
};

export const SelectResponseFilter = createAsyncThunk(
  "selectResponseFilter",
  async (filter) => {
    return filter;
  }
);

const selectResponseFilterSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(SelectResponseFilter.fulfilled, (state, action) => {
      state.selectedResponseFilter = action.payload;
      state.loading = false;
      state.error = null;
    });
  },
});

export default selectResponseFilterSlice.reducer;
