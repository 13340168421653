import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const name = "inboxIndex";
const initialState = {
  inboxIndex: 1,
  loading: false,
  error: null,
};

export const selectInboxIndex = createAsyncThunk(
  "inboxindex",
  async (index) => {
    return index;
  }
);

const selectInboxIndexSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(selectInboxIndex.pending, (state) => {
      // state.selectedTimelines = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(selectInboxIndex.fulfilled, (state, action) => {
      state.inboxIndex = action.payload;
    });
    builder.addCase(selectInboxIndex.rejected, (state, action) => {
      // state.selectedTimelines = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default selectInboxIndexSlice.reducer;
