import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const name = "profiletype1";
const initialState = {
  profiletype1: "",
  loading: false,
  error: null,
};

export const setProfileType = createAsyncThunk("profiletype1", async (type) => {
  return type;
});

const profiletypeSlice1 = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(setProfileType.fulfilled, (state, action) => {
      state.profiletype1 = action.payload;
      state.loading = false;
      state.error = null;
    });
  },
});

export default profiletypeSlice1.reducer;
