import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosApi from "../../utils/httpClient";

const name = "countries";
const initialState = {
  countries: [],
  loading: false,
  error: null,
};

export const fetchCountries = createAsyncThunk(
  "countries/fetchCountries",
  async () => {
    const data = await axiosApi.get(`${process.env.REACT_APP_BASE_URL}country`);
    return data.data;
  }
);

const countriesSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchCountries.pending, (state) => {
      state.countries = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchCountries.fulfilled, (state, action) => {
      state.countries = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(fetchCountries.rejected, (state, action) => {
      state.countries = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default countriesSlice.reducer;
