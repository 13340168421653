import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosApi from "../../utils/httpClient";

const name = "funding";
const initialState = {
  funding: [],
  loading: false,
  error: null,
};

export const fetchFunding = createAsyncThunk(
  "funding/fetchFunding",
  async () => {
    const data = await axiosApi.get(`${process.env.REACT_APP_BASE_URL}funding`);
    return data.data;
  }
);

const fundingSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchFunding.pending, (state) => {
      state.funding = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchFunding.fulfilled, (state, action) => {
      state.funding = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(fetchFunding.rejected, (state, action) => {
      state.funding = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default fundingSlice.reducer;
