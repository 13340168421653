import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosApi from "../../utils/httpClient";

const name = "industries";
const initialState = {
  industries: [],
  loading: false,
  error: null,
};

export const fetchIndustries = createAsyncThunk(
  "industries/fetchIndustries",
  async () => {
    const data = await axiosApi.get(
      `${process.env.REACT_APP_BASE_URL}industry`
    );
    return data.data;
  }
);

const industriesSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchIndustries.pending, (state) => {
      state.industries = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchIndustries.fulfilled, (state, action) => {
      state.industries = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(fetchIndustries.rejected, (state, action) => {
      state.industries = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default industriesSlice.reducer;
