/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import { NestedMenuItem } from "mui-nested-menu";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  AppBar,
  Typography,
  Button,
  Stack,
  Divider,
  Container,
  Avatar,
  Tooltip,
  IconButton,
  Popover,
  Modal,
} from "@mui/material";
import PropTypes from "prop-types";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { useTheme } from "@emotion/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import stringAvatar from "../components/Utils/stringAvatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// import { googleLogout } from "../redux/auth/google/googleSlice";
import { logout, googleLogout } from "../redux/auth/signin/signinSlice";
import { GoogleLogout } from "react-google-login";
import { getCookie } from "../utils/httpClient";

import { switchProfile } from "../redux/switchprofile/switchProfileSlice";
import { clearProfile } from "../redux/auth/profile/profileSlice";
import ConfirmationPop from "../components/ConfirmationsPopup";
import Notifications from "../components/Notifications";

import Snackbar from "../components/Snackbar";
import { selectProject } from "../redux/selectedproject/selectedProjectSlice";
import axiosApi from "../utils/httpClient";
import Slide from "@mui/material/Slide";
import { setDashboardProfile } from "../redux/dashboardProfile/dashboardSlice";
import { BiMenuAltLeft } from "react-icons/bi";
import { IoIosClose } from "react-icons/io";
function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default function HeaderNav(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [showMobileNav, setShowMobileNav] = useState(false);
  const mode = useSelector((store) => store.mode.mode);
  let dimension = useSelector((store) => store.dimension);
  const getNavColor = () => {
    if (
      location.pathname == "/landingpage" ||
      location.pathname == "/" ||
      location.pathname == "/landingpage/"
    ) {
      return "#1F212D";
    } else {
      return "#1F212D";
    }
  };
  const getBgCoor = () => {
    if (
      location.pathname == "/landingpage" ||
      location.pathname == "/landingpage/" ||
      location.pathname == "/"
    ) {
      return "#fff";
    } else {
      return "#fff";
    }
  };

  const toggleShowMobileNav = () => setShowMobileNav((prevState) => !prevState);
  return (
    <React.Fragment>
      <HideOnScroll {...props}>
        <Box
          sx={{
            display: { sm: "block", xs: "none" },
          }}
        >
          {" "}
          <AppBar
            sx={{
              alignItems: "center",
              minWidth: "100%",
              bgcolor: getBgCoor(),
            }}
            elevation={0}
          >
            <Container maxWidth="xl">
              <Box sx={{ width: "100%" }}>
                <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  alignItems="center"
                  alignContent={"center"}
                  py={3}
                  sx={{}}
                >
                  <Stack alignItems="flex-start">
                    <div className="d-flex items-center">
                      <Typography
                        variant="h4"
                        color="primary"
                        className="logo"
                        fontWeight={"600"}
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          navigate("/landingpage");
                        }}
                      >
                        BeBlossom
                      </Typography>
                    </div>
                  </Stack>
                  <Stack
                    direction={"row"}
                    justifyContent="flex-end"
                    alignItems="center"
                    textAlign="center"
                  >
                    <Stack gap={2} direction="row" alignItems={"center"}>
                      <Link to="/landingpage">
                        <Button
                          variant="text"
                          sx={{
                            color: getNavColor(),
                            fontWeight: "600",
                          }}
                        >
                          <span
                            style={{
                              borderBottom: `${
                                location.pathname == `/landingpage`
                                  ? "2px solid #ee4b6a"
                                  : "none"
                              }`,
                            }}
                          >
                            Home
                          </span>
                        </Button>
                      </Link>
                      <Link to="/landingpage/startup">
                        <Button
                          variant="text"
                          sx={{
                            color: getNavColor(),
                            fontWeight: "600",
                          }}
                        >
                          <span
                            style={{
                              borderBottom: `${
                                location.pathname.includes(
                                  `/landingpage/startup`
                                )
                                  ? "2px solid #ee4b6a"
                                  : "none"
                              }`,
                            }}
                          >
                            Startups
                          </span>
                        </Button>
                      </Link>
                      <Link to="/landingpage/investor">
                        <Button
                          variant="text"
                          sx={{
                            color: getNavColor(),
                            fontWeight: "600",
                          }}
                        >
                          <span
                            style={{
                              borderBottom: `${
                                location.pathname.includes(
                                  `/landingpage/investor`
                                )
                                  ? "2px solid #ee4b6a"
                                  : "none"
                              }`,
                            }}
                          >
                            Investors
                          </span>
                        </Button>
                      </Link>
                      <Link to="/landingpage/mentor">
                        <Button
                          variant="text"
                          sx={{
                            color: getNavColor(),
                            fontWeight: "600",
                          }}
                        >
                          <span
                            style={{
                              borderBottom: `${
                                location.pathname.includes(
                                  `/landingpage/mentor`
                                )
                                  ? "2px solid #ee4b6a"
                                  : "none"
                              }`,
                            }}
                          >
                            Mentors
                          </span>
                        </Button>
                      </Link>
                      <Link to="/landingpage/expert">
                        <Button
                          variant="text"
                          sx={{
                            color: getNavColor(),
                            fontWeight: "600",
                          }}
                        >
                          <span
                            style={{
                              borderBottom: `${
                                location.pathname.includes(
                                  `/landingpage/expert`
                                )
                                  ? "2px solid #ee4b6a"
                                  : "none"
                              }`,
                            }}
                          >
                            Experts
                          </span>
                        </Button>
                      </Link>
                      <Link to="/landingpage/partnership">
                        <Button
                          variant="text"
                          sx={{
                            color: getNavColor(),
                            fontWeight: "600",
                          }}
                        >
                          <span
                            style={{
                              borderBottom: `${
                                location.pathname.includes(
                                  `/landingpage/partnership`
                                )
                                  ? "2px solid #ee4b6a"
                                  : "none"
                              }`,
                            }}
                          >
                            Partners
                          </span>
                        </Button>
                      </Link>
                      <Link to="/aboutus">
                        <Button
                          variant="text"
                          sx={{
                            color: getNavColor(),
                            fontWeight: "600",
                          }}
                        >
                          <span
                            style={{
                              borderBottom: `${
                                location.pathname.includes(`/aboutus`)
                                  ? "2px solid #ee4b6a"
                                  : "none"
                              }`,
                            }}
                          >
                            About Us
                          </span>
                        </Button>
                      </Link>
                      <Link to="/discussion">
                        <Button
                          variant="text"
                          sx={{
                            color: getNavColor(),
                            fontWeight: "600",
                          }}
                        >
                          <span
                            style={{
                              borderBottom: `${
                                location.pathname.includes(`/discussion`)
                                  ? "2px solid #ee4b6a"
                                  : "none"
                              }`,
                            }}
                          >
                            Discussion
                          </span>
                        </Button>
                      </Link>

                      {!localStorage.getItem("token") ? (
                        <React.Fragment>
                          <Link to="/signin">
                            <Button variant="outlined" color="primary">
                              Sign in
                            </Button>
                          </Link>
                          <Link to="/register">
                            <Button variant="contained">Get Started</Button>
                          </Link>
                        </React.Fragment>
                      ) : (
                        <Link to="/welcome">
                          <Button variant="contained">Go to Home</Button>
                        </Link>
                      )}
                    </Stack>
                    {dimension.width > 600 && <></>}
                  </Stack>
                </Stack>
              </Box>
            </Container>
          </AppBar>
        </Box>
      </HideOnScroll>
      <Box
        sx={{
          display: { xs: "block", sm: "none" },
          position: "fixed",
          right: 0,
          left: 0,
          top: 0,
          background: getBgCoor(),
          zIndex: "10",
        }}
      >
        <Box sx={{}}>
          <Stack
            justifyContent={"space-between"}
            alignItems="center"
            alignContent="center"
            direction="row"
            sx={{
              height: "60px",
              background: getBgCoor(),
              px: 3,
            }}
          >
            {" "}
            <Typography
              variant="h4"
              color="primary"
              className="logo"
              fontWeight={"600"}
              sx={{
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/landingpage");
                setShowMobileNav(false);
              }}
            >
              BeBlossom
            </Typography>
            {!showMobileNav ? (
              <BiMenuAltLeft
                color="#ee4b6a"
                size={25}
                onClick={toggleShowMobileNav}
              />
            ) : (
              <IoIosClose
                color="#ee4b6a"
                size={28}
                onClick={toggleShowMobileNav}
              />
            )}
          </Stack>
        </Box>

        {showMobileNav && (
          <Stack
            className="mobile-menu"
            spacing={1}
            sx={{
              background: getBgCoor(),
            }}
          >
            <Link to="/landingpage">
              <Button
                variant="text"
                sx={{
                  color: getNavColor(),
                  fontWeight: "600",
                  fontSize: "1.3rem",
                }}
                onClick={toggleShowMobileNav}
              >
                <span
                  style={{
                    borderBottom: `${
                      location.pathname == `/landingpage`
                        ? "2px solid #ee4b6a"
                        : "none"
                    }`,
                  }}
                >
                  Home
                </span>
              </Button>
            </Link>
            <Link to="/landingpage/startup">
              <Button
                variant="text"
                sx={{
                  color: getNavColor(),
                  fontWeight: "600",
                  fontSize: "1.3rem",
                }}
                onClick={toggleShowMobileNav}
              >
                <span
                  style={{
                    borderBottom: `${
                      location.pathname.includes(`/landingpage/startup`)
                        ? "2px solid #ee4b6a"
                        : "none"
                    }`,
                  }}
                >
                  Startups
                </span>
              </Button>
            </Link>
            <Link to="/landingpage/investor">
              <Button
                variant="text"
                sx={{
                  color: getNavColor(),
                  fontWeight: "600",
                  fontSize: "1.3rem",
                }}
                onClick={toggleShowMobileNav}
              >
                <span
                  style={{
                    borderBottom: `${
                      location.pathname.includes(`/landingpage/investor`)
                        ? "2px solid #ee4b6a"
                        : "none"
                    }`,
                  }}
                >
                  Investors
                </span>
              </Button>
            </Link>
            <Link to="/landingpage/mentor">
              <Button
                variant="text"
                sx={{
                  color: getNavColor(),
                  fontWeight: "600",
                  fontSize: "1.3rem",
                }}
                onClick={toggleShowMobileNav}
              >
                <span
                  style={{
                    borderBottom: `${
                      location.pathname.includes(`/landingpage/mentor`)
                        ? "2px solid #ee4b6a"
                        : "none"
                    }`,
                  }}
                >
                  Mentors
                </span>
              </Button>
            </Link>
            <Link to="/landingpage/expert">
              <Button
                variant="text"
                sx={{
                  color: getNavColor(),
                  fontWeight: "600",
                  fontSize: "1.3rem",
                }}
                onClick={toggleShowMobileNav}
              >
                <span
                  style={{
                    borderBottom: `${
                      location.pathname.includes(`/landingpage/expert`)
                        ? "2px solid #ee4b6a"
                        : "none"
                    }`,
                  }}
                >
                  Experts
                </span>
              </Button>
            </Link>
            <Link to="/landingpage/partnership">
              <Button
                variant="text"
                sx={{
                  color: getNavColor(),
                  fontWeight: "600",
                  fontSize: "1.3rem",
                }}
                onClick={toggleShowMobileNav}
              >
                <span
                  style={{
                    borderBottom: `${
                      location.pathname.includes(`/landingpage/partnership`)
                        ? "2px solid #ee4b6a"
                        : "none"
                    }`,
                  }}
                >
                  Partners
                </span>
              </Button>
            </Link>
            <Link to="/aboutus">
              <Button
                variant="text"
                sx={{
                  color: getNavColor(),
                  fontWeight: "600",
                  fontSize: "1.3rem",
                }}
                onClick={toggleShowMobileNav}
              >
                <span
                  style={{
                    borderBottom: `${
                      location.pathname.includes(`/aboutus`)
                        ? "2px solid #ee4b6a"
                        : "none"
                    }`,
                  }}
                >
                  About Us
                </span>
              </Button>
            </Link>
            <Link to="/discussion">
              <Button
                variant="text"
                sx={{
                  color: getNavColor(),
                  fontWeight: "600",
                  fontSize: "1.3rem",
                }}
                onClick={toggleShowMobileNav}
              >
                <span
                  style={{
                    borderBottom: `${
                      location.pathname.includes(`/discussion`)
                        ? "2px solid #ee4b6a"
                        : "none"
                    }`,
                  }}
                >
                  Discussions
                </span>
              </Button>
            </Link>

            {!localStorage.getItem("token") ? (
              <React.Fragment>
                <Link to="/signin">
                  <Button
                    variant="outlined"
                    color="primary"
                    sx={{
                      fontSize: "1.3rem",
                      minWidth: "200px",
                    }}
                    onClick={toggleShowMobileNav}
                  >
                    Sign in
                  </Button>
                </Link>
                <Link to="/register">
                  <Button
                    variant="contained"
                    sx={{
                      fontSize: "1.3rem",
                      minWidth: "200px",
                      mt: 1,
                    }}
                    onClick={toggleShowMobileNav}
                  >
                    Get Started
                  </Button>
                </Link>
              </React.Fragment>
            ) : (
              <Link to="/">
                <Button
                  variant="contained"
                  sx={{
                    fontSize: "1.3rem",
                    minWidth: "200px",
                  }}
                  onClick={toggleShowMobileNav}
                >
                  Go to Home
                </Button>
              </Link>
            )}
          </Stack>
        )}
      </Box>
    </React.Fragment>
  );
}
