import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosApi from "../../utils/httpClient";

const name = "interestsSent";
const initialState = {
  interestsSent: [],
  loading: false,
  error: null,
};

export const interestsSentAPI = createAsyncThunk(
  "interestssent",
  async ({ search, skip, limit, id, type }) => {
    const data = await axiosApi.get(
      `${
        process.env.REACT_APP_BASE_URL
      }application/project-send?skip=${skip}&limit=${limit}${
        search != "" ? `&search=${search}` : ""
      }&id=${id}&model=${type}`
    );
    return data.data;
  }
);

const interestsSentSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(interestsSentAPI.pending, (state) => {
      state.interestsSent = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(interestsSentAPI.fulfilled, (state, action) => {
      state.interestsSent = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(interestsSentAPI.rejected, (state, action) => {
      state.interestsSent = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default interestsSentSlice.reducer;
