import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const name = "selectedSkills";
const initialState = {
  selectedSkills: [],
  loading: false,
  error: null,
};

export const selectSkills = createAsyncThunk(
  "skills/selected",
  async (skills) => {
    return skills;
  }
);

const selectedSkillsSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(selectSkills.pending, (state) => {
      state.selectedSkills = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(selectSkills.fulfilled, (state, action) => {
      state.selectedSkills = action.payload;
    });
    builder.addCase(selectSkills.rejected, (state, action) => {
      state.selectedSkills = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default selectedSkillsSlice.reducer;
