import { Button, Icon, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import UpcomingIcon from "@mui/icons-material/Upcoming";
import AlarmOffIcon from "@mui/icons-material/AlarmOff";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TodayIcon from "@mui/icons-material/Today";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
const Index = () => {
  const mode = useSelector((store) => store.mode.mode);
  const dimension = useSelector((store) => store.dimension);
  const [searchParams, setSearchParams] = useSearchParams();
  const tabs = [
    {
      id: 0,
      icon: <MailOutlineIcon />,
      title: "Inbox",
    },
    {
      id: 1,
      icon: <TodayIcon />,
      title: "Today",
    },
    {
      id: 2,
      icon: <UpcomingIcon />,
      title: "Upcoming",
    },
    {
      id: 3,
      icon: <AlarmOffIcon />,
      title: "Past",
    },
    {
      id: 4,
      icon: <AccessTimeIcon />,
      title: "Anytime",
    },
    {
      id: 5,
      icon: <DoneAllIcon />,
      title: "Completed",
    },
  ];
  useEffect(() => {
    if (!searchParams.get("filter")) {
      if (dimension.width > 600) {
        searchParams.set("filter", "inbox");
        setSearchParams(searchParams);
      }
    }
  }, []);
  return (
    <React.Fragment>
      <Stack direction="column" spacing={3}>
        {tabs.map((item) => (
          <Stack
            key={item.id}
            direction="row"
            spacing={1}
            alignItems="center"
            className={
              mode
                ? searchParams.get("filter")?.toLowerCase() ==
                  item.title.toLowerCase()
                  ? "light-1"
                  : "light-2"
                : searchParams.get("filter")?.toLowerCase() ==
                  item.title.toLowerCase()
                ? "dark-8"
                : "dark-2"
            }
            sx={{
              cursor: "pointer",
              width: "max-content",
            }}
            onClick={() => {
              if (searchParams.get("list")) {
                searchParams.delete("list");
              }
              if (searchParams.get("group")) {
                searchParams.delete("group");
              }
              searchParams.set("filter", item.title.toLowerCase());
              setSearchParams(searchParams);
            }}
          >
            {item.icon}
            <Typography fontSize="16px">{item.title}</Typography>
          </Stack>
        ))}
      </Stack>
    </React.Fragment>
  );
};

export default Index;
