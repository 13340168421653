import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const name = "skip";
const initialState = {
  skip: 1,
  loading: false,
  error: null,
};

export const setSkip = createAsyncThunk("pagination/skip", async (skip) => {
  return skip;
});

const skipSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(setSkip.fulfilled, (state, action) => {
      state.skip = action.payload;
      state.loading = false;
      state.error = null;
    });
  },
});

export default skipSlice.reducer;
