import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosApi from "../../utils/httpClient";
import * as qs from "qs";
const name = "starred";
const initialState = {
  starred: [],
  loading: false,
  error: null,
  success: false,
};

export const fetchStarredMessages = createAsyncThunk(
  "conversations/starred",
  async ({ id, model }) => {
    var formData = {};
    // formData["_id"] = id;
    // formData["model"] = model;
    const data = await axiosApi.get(
      `${process.env.REACT_APP_BASE_URL}chat/stared-messages/?skip=1&limit=10`
      // {
      //   params: formData,
      //   paramsSerializer: (params) => {
      //     return qs.stringify(params, { arrayFormat: "repeat" });
      //   },
      // }
    );
    return data.data;
  }
);

export const removeStar = createAsyncThunk(
  "conversations/removeStar",
  async ({ id }) => {
    return { id };
  }
);

const fetchStarredMessagesSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchStarredMessages.pending, (state) => {
      state.starred = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchStarredMessages.fulfilled, (state, action) => {
      state.starred = action.payload;
      state.loading = false;
      state.error = null;
      state.success = true;
    });
    builder.addCase(fetchStarredMessages.rejected, (state, action) => {
      state.starred = [];
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(removeStar.fulfilled, (state, action) => {
      const filteredLists = state.starred?.data?.messages.filter(
        (item) => item._id !== action.payload.id
      );

      state.starred.data.messages = filteredLists;
      state.loading = false;
      state.error = null;
    });
  },
});

export default fetchStarredMessagesSlice.reducer;
