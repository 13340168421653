import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const name = "inboxProfile";
const initialState = {
  inboxProfile: "All",
  loading: false,
  error: null,
};

export const setInboxProfile = createAsyncThunk(
  "setInboxProfile",
  async (profile) => {
    return profile;
  }
);

const inboxProfileSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(setInboxProfile.fulfilled, (state, action) => {
      state.inboxProfile = action.payload;
      state.loading = false;
      state.error = null;
    });
  },
});

export default inboxProfileSlice.reducer;
