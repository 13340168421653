import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getCookie } from "./httpClient";

import { useSelector } from "react-redux";
import Loader from "../components/Loader";

function StartupProtectedRoute({ children }) {
  const isAuthenticated = getCookie("token");

  const profile = useSelector((store) => store.profile);
  let location = useLocation();
  if (profile?.success == true) {
    if (
      profile?.data?.institution == null &&
      profile?.data?.individual == null
    ) {
      return (
        <Navigate
          to="/investor-create/type-of-investor"
          //   state={{ from: location }} replace
        />
      );
    } else {
      return children;
    }
  } else {
    return <Loader message="Please wait!!!" />;
  }
}

export default StartupProtectedRoute;
