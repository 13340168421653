import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosApi from "../../utils/httpClient";

const name = "products";
const initialState = {
  products: [],
  loading: false,
  error: null,
};

export const fetchProducts = createAsyncThunk(
  "products/fetchProducts",
  async () => {
    const data = await axiosApi.get("https://fakestoreapi.com/products");
    return data.data;
    // const data = await Products.getAllProducts()
    // let products = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    // return products
  }
);

const productsSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchProducts.pending, (state) => {
      state.products = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      state.products = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(fetchProducts.rejected, (state, action) => {
      state.products = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default productsSlice.reducer;
