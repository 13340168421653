import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axiosApi from "../../utils/httpClient";
import { Stack, Typography, Modal, Box } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { fetchNote } from "../../redux/cardnotes/cardNote/cardNoteSlice";
import CloseIcon from "@mui/icons-material/Close";
import Loader from "../Loader";
const Index = ({ onClose, id, model }) => {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [defaultValue, setDefaultValue] = useState("");
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const closeModal = () => setOpenModal(false);
  const note = useSelector((store) => store.note.note);
  const cardNote = useSelector((store) => store.cardNote.cardNote);
  const notes = useSelector((store) => store.notes.notes);
  const selectedCardNote = useSelector((store) => store.selectedCardNote);
  const [title, setTitle] = useState("");
  const mode = useSelector((store) => store.mode.mode);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
    width: 400,
    bgcolor: mode ? "#333345" : "#fff",
    border: "2px solid #333345",

    boxShadow: 24,
    p: 3,
  };

  const getNote = async () => {
    setLoading(true);
    try {
      const response = await axiosApi.get(
        `${process.env.REACT_APP_BASE_URL}profile-note/${id}`
      );
      setLoading(false);
      if (response?.data?.data?.note) {
        setValue(response?.data?.data?.note);
        setDefaultValue(response?.data?.data?.note);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const updateNote = async () => {
    const response = await axiosApi.patch(
      `${process.env.REACT_APP_BASE_URL}profile-note/${id}`,
      {
        note: value,
      }
    );
  };
  useEffect(() => {
    if (value) {
      if (value != defaultValue) {
        updateNote();
      }
    }
  }, [value]);

  useEffect(() => {
    getNote();

    return () => {
      setValue("");
    };
  }, []);

  return (
    <React.Fragment>
      <Loader loading={loading} message={"Loading!"} />
      <Box className={mode ? "dark-bg-1" : "light-bg-1"}>
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={2}
          sx={{
            height: "480px",
          }}
        >
          <ReactQuill
            placeholder="Add some notes"
            theme="snow"
            value={value}
            onChange={setValue}
            style={{
              width: "100%",
            }}
          />
          <Typography fontWeight={"500"} fontSize="large" color="secondary">
            <CloseIcon
              fontSize="large"
              sx={{
                mr: 2,
                mt: 2,
                cursor: "pointer",
              }}
              onClick={onClose}
            />
          </Typography>
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default Index;
