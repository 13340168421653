import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosApi from "../../../utils/httpClient";

const name = "bookmarksProject";
const initialState = {
  bookmarksProject: [],
  loading: false,
  error: null,
};

export const bookmarksProjectAPI = createAsyncThunk(
  "bookmarks/project",
  async ({ search, skip, limit, id, type }) => {
    const data = await axiosApi.get(
      `${
        process.env.REACT_APP_BASE_URL
      }bookmark/projects?skip=${skip}&limit=${limit}${
        search != "" ? `&search=${search}` : ""
      }&id=${id}&model=${type}`
    );
    return data.data;
  }
);

const bookmarksProjectSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(bookmarksProjectAPI.pending, (state) => {
      state.bookmarksProject = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(bookmarksProjectAPI.fulfilled, (state, action) => {
      state.bookmarksProject = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(bookmarksProjectAPI.rejected, (state, action) => {
      state.bookmarksProject = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default bookmarksProjectSlice.reducer;
