const Index = ({ color }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="30" rx="4" fill={color} />
      <path
        d="M8.125 22.6389H21.875"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.1111 12.2349H8.88889C8.46875 12.2349 8.125 12.5786 8.125 12.9988V19.5835C8.125 20.0036 8.46875 20.3474 8.88889 20.3474H10.1111C10.5312 20.3474 10.875 20.0036 10.875 19.5835V12.9988C10.875 12.5786 10.5312 12.2349 10.1111 12.2349Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.6111 9.79797H14.3889C13.9687 9.79797 13.625 10.1417 13.625 10.5619V19.5834C13.625 20.0035 13.9687 20.3473 14.3889 20.3473H15.6111C16.0312 20.3473 16.375 20.0035 16.375 19.5834V10.5619C16.375 10.1417 16.0312 9.79797 15.6111 9.79797Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.1111 7.36121H19.8889C19.4688 7.36121 19.125 7.70496 19.125 8.12509V19.5834C19.125 20.0036 19.4688 20.3473 19.8889 20.3473H21.1111C21.5312 20.3473 21.875 20.0036 21.875 19.5834V8.12509C21.875 7.70496 21.5312 7.36121 21.1111 7.36121Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Index;
