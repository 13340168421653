import { Box, Typography, Stack, Button, TextField } from "@mui/material";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axiosApi from "../../../../../../utils/httpClient";
import AddTagBlock from "../../../../AddTagBlock";
import {
  addGroup,
  groupsAPI,
} from "../../../../../../redux/tasks/groups/groupsSlice";
const Index = ({ onClose }) => {
  const mode = useSelector((store) => store.mode.mode);
  const [groupName, setGroupName] = useState("");
  const dispatch = useDispatch();

  const createGroup = async () => {
    const formData = {};

    formData["name"] = groupName;
    const resp = await axiosApi.post(`/group`, formData);
    onClose();
    await dispatch(addGroup({ data: resp.data.data }));
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      createGroup();
    }
  };
  return (
    <React.Fragment>
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{
            background: "#ee4b6a",
            width: "100%",
            py: 1,
            px: 2,
            color: "#fff",
          }}
        >
          <Stack direction="row" spacing={2}>
            <LibraryAddIcon />
            <Typography fontSize="16px">Create Group</Typography>
          </Stack>{" "}
          <CloseIcon
            onClick={onClose}
            sx={{
              cursor: "pointer",
            }}
          />
        </Stack>
        <Stack
          spacing={3}
          sx={{
            p: 2,
          }}
        >
          <TextField
            value={groupName}
            type="text"
            id="description"
            name="description"
            fullWidth
            placeholder="Enter group name"
            InputProps={{
              maxLength: 300,
              classes: {
                input: `${mode ? "inputFieldDark" : "inputFieldLight"}`,
              },
              className: `${mode ? "dark-bg-2 " : "light-bg-2 "}`,
            }}
            onChange={(e) => {
              setGroupName(e.target.value);
            }}
            onKeyDown={handleKeyDown}
          />
          {/* <AddTagBlock
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
          /> */}
          <Stack
            direction="row"
            justifyContent="flex-end"
            sx={{
              width: "100%",
            }}
          >
            <Button
              color="primary"
              variant="contained"
              disabled={!groupName?.length > 0}
              onClick={createGroup}
            >
              Create Group
            </Button>
          </Stack>
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default Index;
