import { configureStore } from "@reduxjs/toolkit";
import modeReducer from "./redux/darkmode/modeSlice";
import dimensionsReducer from "./redux/dimensions/dimensionsSlice";
import productsReducer from "./redux/products/productsSlice";
import signinReducer from "./redux/auth/signin/signinSlice";
import profileReducer from "./redux/auth/profile/profileSlice";
import googleReducer from "./redux/auth/google/googleSlice";
import industriesReducer from "./redux/industries/industriesSlice";
import skillsReducer from "./redux/skills/skillsSlice";
import jobcategoriesReducer from "./redux/jobcategories/jobcategoriesSlice";
import eventcategoriesReducer from "./redux/eventcategories/eventsCategoriesSlice";
import eventkeywordsReducer from "./redux/eventkeywords/eventsKeywordsSlice";
import eventkindsReducer from "./redux/eventkinds/eventsKindsSlice";
import timelinesReducer from "./redux/timelines/timelinesSlice";
import selectedIndustriesReducer from "./redux/industries/selected/selectedIndustriesSlice";
import selectedJobCategoriesReducer from "./redux/jobcategories/selected/selectedJobCategoriesSlice";
import selectedSkillsReducer from "./redux/skills/selected/selectedSkillsSlice";
import selectedTimelinesReducer from "./redux/timelines/selected/selectedTimelinesSlice";
import startUpReducer from "./redux/startup/startUpSlice";
import fundingReducer from "./redux/funding/fundingSlice";
import investmentStageReducer from "./redux/investmentStage/investmentStageSlice";
import projectTypeReducer from "./redux/projecttype/projectTypeSlice";
import selectedProjectTypeReducer from "./redux/projecttype/selected/selectedProjectTypeSlice";
import selectedFundingSliceReducer from "./redux/funding/selected/selectedFundingSlice";
import selectedInvestmentStageReducer from "./redux/investmentStage/selected/selectedInvestmentStageSlice";
import limitReducer from "./redux/pagination/limit/limitSlice";
import worklocationReducer from "./redux/worklocation/worklocationSlice";
import availbilityReducer from "./redux/availbility/availbilitySlice";
import profiletypeReducer from "./redux/profiletype/profiletypeSlice";
import profiletypeReducer1 from "./redux/profiletype1/profiletypeSlice";
import profiletypeReducer2 from "./redux/profiletype2/profiletypeSlice";
import jobtypeReducer from "./redux/jobtype/jobtypeSlice";
import skipReducer from "./redux/pagination/skip/skipSlice";
import searchReducer from "./redux/search/searchSlice";
import exploreInvestorReducer from "./redux/explore/investor/exploreInvestorSlice";
import exploreExpertReducer from "./redux/explore/expert/exploreExpertsSlice";
import exploreMentorReducer from "./redux/explore/mentor/exploreMentorSlice";
import exploreStartupReducer from "./redux/explore/startup/exploreStartupSlice";
import exploreJobsReducer from "./redux/explore/job/exploreJobsSlice";
import exploreEventsReducer from "./redux/explore/event/exploreEventsSlice";
import bookmarksStartupReducer from "./redux/bookmarks/startup/startupBookmarkSlice";
import bookmarksInvestorReducer from "./redux/bookmarks/investor/investorBookmarkSlice";
import countriesReducer from "./redux/countries/countriesSlice";
import selectedCountriesReducer from "./redux/countries/selected/selectedCountriesSlice";
import investorTypesReducer from "./redux/investortype/investortypeSlice";
import selectedInvestorTypesReducer from "./redux/investortype/selected/selectedInvestorTypesSlice";
import institutionTypeReducer from "./redux/institutiontype/institutionTypeSlice";
import investorTypeFilterReducer from "./redux/investorTypeFilter/investorTypeFilterSlice";
import responseFilterReducer from "./redux/responseFilter/responseFilterSlice";
import interestsSentReducer from "./redux/interestsSent/interestsSentSlice";
import applicationsSentReducer from "./redux/applicationSent/applicationSentSlice";
import switchProfileReducer from "./redux/switchprofile/switchProfileSlice";
import receivedInterestsReducer from "./redux/receivedInterests/receivedInterestsSlice";
import applicationsReceivedReducer from "./redux/receivedApplications/receivedApplicationsSlice";
import conversationsReducer from "./redux/conversations/conversationsSlice";
import messagesReducer from "./redux/messages/messagesSlice";
import selectConversationReducer from "./redux/selectConversation/selectConversationSlice";
import priceReducer from "./redux/price/priceSlice";
import selectedPriceReducer from "./redux/price/selected/selectedPriceSlice";
import myjobsReducer from "./redux/myprojects/myprojectsSlice";
import myeventsReducer from "./redux/myevents/myeventsSlice";
import groupsReducer from "./redux/tasks/groups/groupsSlice";
import listsReducer from "./redux/tasks/lists/listsSlice";
import grouplistsReducer from "./redux/tasks/groups/lists/listsSlice";
import tasksReducer from "./redux/tasks/tasks/tasksSlice";
import exploreProjectsReducer from "./redux/explore/projects/exploreProjectsSlice";
import projectBookmarkReducer from "./redux/bookmarks/projects/projectBookmarkSlice";
import selectedProjectReducer from "./redux/selectedproject/selectedProjectSlice";
import selectedJobReducer from "./redux/selectedJob/selectedJobSlice";
import selectedEventReducer from "./redux/selectedEvent/selectedJobSlice";
import starredMessagesReducer from "./redux/starredmessages/starredMessagesSlice";
import inboxIndexReducer from "./redux/inboxindex/inboxIndexSlice";
import appliedJobsReducer from "./redux/appliedJobs/appliedJobsSlice";
import receivedJobsReducer from "./redux/receivedJobs/receivedJobsSlice";
import interestsSentEventsReducer from "./redux/interestsSentEvents/interestsSentEventsSlice";
import receivedEventsReducer from "./redux/receivedEvents/receivedEventsSlice";
import dashboardProfileReducer from "./redux/dashboardProfile/dashboardSlice";
import receivedToCompanyReducer from "./redux/receivedToCompany/receivedToCompanySlice";
import receivedToEventReducer from "./redux/receivedToEvent/receivedToEventSlice";
import sentToCompanyReducer from "./redux/sentToCompany/sentToCompanySlice";
import sentToEventReducer from "./redux/sentToEvent/sentToEventSlice";
import sentToIndividualReducer from "./redux/sentToIndividual/sentToIndividualSlice";
import receivedToIndividualReducer from "./redux/receivedToIndividual/receivedToIndividualSlice";
import receivedToExpertReducer from "./redux/receivedToExpert/receivedToExpertSlice";
import receivedToMentorReducer from "./redux/receivedToMentor/receivedToMentorSlice";
import sentToExpertReducer from "./redux/sentToExpert/sentToExpertSlice";
import sentToMentorReducer from "./redux/sentToMentor/sentToMentorSlice";
import bookmarksMentorReducer from "./redux/bookmarks/mentor/mentorBookmarkSlice";
import bookmarksExpertReducer from "./redux/bookmarks/expert/expertBookmarkSlice";
import bookmarksEventReducer from "./redux/bookmarks/event/eventBookmarkSlice";
import bookmarksJobReducer from "./redux/bookmarks/job/jobBookmarkSlice";
import selectedEventsKeywordsReducer from "./redux/eventkeywords/selected/selectedEventsKeywordsSlice";
import selectedEventsCategoriesReducer from "./redux/eventcategories/selected/selectedEventsCategoriesSlice";
import selectedEventsKindsReducer from "./redux/eventkinds/selected/selectedEventsKindsSlice";
import inboxProfileReducer from "./redux/inboxProfile/inboxProfileSlice";
import fetchNotesReducer from "./redux/notes/fetchNotes/fetchNotesSlice";
import selectNoteReducer from "./redux/notes/selectNote/selectNoteSlice";
import cardNoteReducer from "./redux/cardnotes/cardNote/cardNoteSlice";
import selectCardNoteReducer from "./redux/cardnotes/selectCardNote/selectCardNoteSlice";

export default configureStore({
  reducer: {
    mode: modeReducer,
    dimension: dimensionsReducer,
    products: productsReducer,
    signin: signinReducer,
    profile: profileReducer,
    google: googleReducer,
    industires: industriesReducer,
    skills: skillsReducer,
    timelines: timelinesReducer,
    selectedTimelines: selectedTimelinesReducer,
    startUp: startUpReducer,
    funding: fundingReducer,
    investmentStage: investmentStageReducer,
    selectedIndustries: selectedIndustriesReducer,
    selectedJobCategories: selectedJobCategoriesReducer,
    selectedSkills: selectedSkillsReducer,
    selectedFunding: selectedFundingSliceReducer,
    selectedInvestmentStage: selectedInvestmentStageReducer,
    limit: limitReducer,
    worklocation: worklocationReducer,
    availbility: availbilityReducer,
    profiletype: profiletypeReducer,

    profiletype1: profiletypeReducer1,
    profiletype2: profiletypeReducer2,

    jobtype: jobtypeReducer,
    skip: skipReducer,
    search: searchReducer,
    exploreInvestor: exploreInvestorReducer,
    exploreProjects: exploreProjectsReducer,
    bookmarksStartup: bookmarksStartupReducer,
    bookmarksInvestor: bookmarksInvestorReducer,
    exploreStartup: exploreStartupReducer,
    countries: countriesReducer,
    selectedCountries: selectedCountriesReducer,
    investortypes: investorTypesReducer,
    selectedInvestorTypes: selectedInvestorTypesReducer,
    institutionType: institutionTypeReducer,
    selectedInvestorFilter: investorTypeFilterReducer,
    selectedResponseFilter: responseFilterReducer,
    interestsSent: interestsSentReducer,
    applicationsSent: applicationsSentReducer,
    switch: switchProfileReducer,
    receivedInterests: receivedInterestsReducer,
    receivedApplications: applicationsReceivedReducer,
    conversations: conversationsReducer,
    messages: messagesReducer,
    conversation: selectConversationReducer,
    projectType: projectTypeReducer,
    selectedProjectType: selectedProjectTypeReducer,
    price: priceReducer,
    selectedPrice: selectedPriceReducer,
    myjobs: myjobsReducer,
    myevents: myeventsReducer,
    groups: groupsReducer,
    lists: listsReducer,
    grouplists: grouplistsReducer,
    tasks: tasksReducer,
    bookmarksProject: projectBookmarkReducer,
    selectedProject: selectedProjectReducer,
    selectedJob: selectedJobReducer,
    selectedEvent: selectedEventReducer,
    starredMessages: starredMessagesReducer,
    inboxIndex: inboxIndexReducer,
    exploreExperts: exploreExpertReducer,
    exploreMentors: exploreMentorReducer,
    jobcategories: jobcategoriesReducer,
    eventcategories: eventcategoriesReducer,
    eventkeywords: eventkeywordsReducer,
    exploreJobs: exploreJobsReducer,
    exploreEvents: exploreEventsReducer,
    eventkinds: eventkindsReducer,
    appliedJobs: appliedJobsReducer,
    receivedJobs: receivedJobsReducer,
    interestsSentEvents: interestsSentEventsReducer,
    receivedEvents: receivedEventsReducer,
    dashboardProfile: dashboardProfileReducer,
    receivedToCompany: receivedToCompanyReducer,
    receivedToEvent: receivedToEventReducer,
    sentToCompany: sentToCompanyReducer,
    sentToEvent: sentToEventReducer,
    sentToIndividual: sentToIndividualReducer,
    receivedToIndividual: receivedToIndividualReducer,
    receivedToExpert: receivedToExpertReducer,
    receivedToMentor: receivedToMentorReducer,
    sentToExpert: sentToExpertReducer,
    sentToMentor: sentToMentorReducer,
    bookmarksMentor: bookmarksMentorReducer,
    bookmarksExpert: bookmarksExpertReducer,
    bookmarksEvent: bookmarksEventReducer,
    selectedEventsKeywords: selectedEventsKeywordsReducer,
    selectedEventsCategories: selectedEventsCategoriesReducer,
    selectedEventsKinds: selectedEventsKindsReducer,
    inboxProfile: inboxProfileReducer,
    notes: fetchNotesReducer,
    note: selectNoteReducer,
    cardNote: cardNoteReducer,
    selectedCardNote: selectCardNoteReducer,
    bookmarksJob: bookmarksJobReducer,
  },
});
