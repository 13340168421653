import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as qs from "qs";
import axiosApi from "../../utils/httpClient";
const name = "interestsSentEvents";
const initialState = {
  interestsSentEvents: [],
  loading: false,
  error: null,
};

export const interestsSentEventsAPI = createAsyncThunk(
  "interestsSentEvents",
  async ({ search, skip, limit, stat }) => {
    var formData = {};
    if (search) {
      formData["search"] = search;
    }
    const data = await axiosApi.get(
      `${process.env.REACT_APP_BASE_URL}event/interests-send?skip=${skip}&limit=${limit}`,
      {
        params: formData,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      }
    );
    return data.data;
  }
);

const interestsSentEventsSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(interestsSentEventsAPI.pending, (state) => {
      state.interestsSentEvents = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(interestsSentEventsAPI.fulfilled, (state, action) => {
      state.interestsSentEvents = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(interestsSentEventsAPI.rejected, (state, action) => {
      state.interestsSentEvents = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default interestsSentEventsSlice.reducer;
