import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const name = "institutionType";
const initialState = {
  institutionType: "",
  institutionTypeId: "",
  loading: false,
  error: null,
};

export const selectInstitutionType = createAsyncThunk(
  "institutiontype",
  async ({ type, id }) => {
    return [type, id];
  }
);

const institutionTypeSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(selectInstitutionType.fulfilled, (state, action) => {
      state.institutionType = action.payload[0];
      state.institutionTypeId = action.payload[1];
      state.loading = false;
      state.error = null;
    });
  },
});

export default institutionTypeSlice.reducer;
