import axios from "axios";

export function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

const token = getCookie("token");
const profileToken = getCookie("profileToken");

const refreshToken = getCookie("refreshToken");

const axiosApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "X-API-KEY": profileToken ? profileToken : "",
  },
});

axiosApi.interceptors.request.use(
  (config) => {
    if (localStorage?.getItem("token")) {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

//response interceptor to refresh token on receiving token expired error
axiosApi.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      localStorage.getItem("refreshToken") &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}auth/refresh/`,
        {
          refreshToken: localStorage.getItem("refreshToken"),
        }
      );
      if (res.status === 200) {
        localStorage.setItem("token", res.data.data.accessToken);

        return axios(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosApi;
