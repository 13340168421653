import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	startUp: {}
}

export const startUpSlice = createSlice({
	name: 'startUp',
	initialState,
	reducers: {
		addStartUp: (state, action) => {
			state.startUp = action.payload
		}
	}
})

export const { addStartUp } = startUpSlice.actions

export default startUpSlice.reducer
