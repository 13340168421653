import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const name = "selectedevent";
const initialState = {
  eventName: "all",
  eventId: "all",
  loading: false,
  error: null,
};

export const selectEvent = createAsyncThunk(
  "event/select",
  async ({ name, id }) => {
    return { name, id };
  }
);

const selecteventSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(selectEvent.pending, (state) => {
      // state.selectedTimelines = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(selectEvent.fulfilled, (state, action) => {
      state.eventName = action.payload.name;
      state.eventId = action.payload.id;
    });
    builder.addCase(selectEvent.rejected, (state, action) => {
      // state.selectedTimelines = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default selecteventSlice.reducer;
