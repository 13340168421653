import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomSnackBar({
  open,
  message,
  onClose,
  notificationClick,
}) {
  const mode = useSelector((store) => store.mode.mode);
  const dimension = useSelector((store) => store.dimension);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    onClose();
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={600000}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      sx={{
        maxWidth: dimension.width > 600 ? "40%" : "100%",
        zIndex: "999999999999999999999",
        marginBottom: dimension.width <= 600 ? "50px" : "inherit",
      }}
    >
      <Alert
        color={"primary"}
        onClose={onClose}
        sx={{ width: "100%", cursor: "pointer" }}
        onClick={() => {
          notificationClick();
          onClose();
        }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: message,
          }}
        />
      </Alert>
    </Snackbar>
  );
}
