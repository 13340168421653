import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as qs from "qs";
import axiosApi from "../../utils/httpClient";
const name = "receivedToCompany";
const initialState = {
  receivedToCompany: [],
  loading: false,
  error: null,
};

export const receivedToCompanyAPI = createAsyncThunk(
  "receivedToCompany",
  async ({
    search,
    skip,
    limit,
    type,
    country,
    investorprofile,
    status,
    skills,
    profiletype,
  }) => {
    var formData = {};
    let model;
    if (type == "Investors") {
      if (investorprofile == "All") {
        model = "investor";
      }
      if (investorprofile == "Individual") {
        model = "individual";
      }
      if (investorprofile == "Institutional") {
        model = "institution";
      }
    }

    if (type == "Experts") {
      model = "expert";
      if (skills) {
        formData["skill"] = skills;
      }
      if (profiletype) {
        formData["type"] = profiletype;
      }
    }

    if (type == "Mentors") {
      model = "mentor";
      if (skills) {
        formData["skill"] = skills;
      }
    }
    if (country) {
      formData["country"] = country;
    }
    if (status) {
      if (status == "Submited") {
        formData["status"] = "received";
      } else if (status == "Approved") {
        formData["status"] = "approved";
      } else if (status == "Rejected") {
        formData["status"] = "rejected";
      }
    }
    formData["model"] = model;
    if (search) {
      formData["search"] = search;
    }

    const data = await axiosApi.get(
      `${process.env.REACT_APP_BASE_URL}application/company/received/?skip=${skip}&limit=${limit}`,
      {
        params: formData,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      }
    );
    return data.data;
  }
);

const receivedToCompanySlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(receivedToCompanyAPI.pending, (state) => {
      state.receivedToCompany = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(receivedToCompanyAPI.fulfilled, (state, action) => {
      state.receivedToCompany = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(receivedToCompanyAPI.rejected, (state, action) => {
      state.receivedToCompany = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default receivedToCompanySlice.reducer;
