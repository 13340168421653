import {
  Box,
  Button,
  Divider,
  Modal,
  Grid,
  Stack,
  Typography,
  Popover,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import React, { useState } from "react";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import Icon1 from "../../../../assets/icons/Card/3";
import SkillIcon from "../../../../assets/icons/skill";
import {
  useNavigate,
  Link,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import axiosApi from "../../../../utils/httpClient";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import {
  selectConversation,
  setDirectMessagefromCard,
} from "../../../../redux/selectConversation/selectConversationSlice";
import CardNotes from "../../../CardNotes";
import CardMore from "../../Popups/CardMore";
import { selectNote } from "../../../../redux/cardnotes/selectCardNote/selectCardNoteSlice";
import ApplyMessage from "../../../ApplyMessage";
import { removeBookmark } from "../../../../redux/bookmarks/mentor/mentorBookmarkSlice";
import useQueryString from "../../../../utils/hooks/useQueryString";
const Index = ({ value, bookmarked, data, onClose, fromBookmark }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const queryString = useQueryString({ type: "entry1", tab: "mentors" });
  const [description, setDescription] = useState("");
  const [mouseHover, setMouseHover] = useState(false);
  const conversation = useSelector((store) => store.conversation);
  const [status, setStatus] = useState(data?.interest?.status);
  const mode = useSelector((store) => store.mode.mode);
  const profile = useSelector((store) => store.profile);
  const [openPopup, setOpenPopup] = useState(false);
  const closePopup = () => {
    setOpenPopup(false);
  };
  const getColorOfIndustry = (stage) => {
    if (stage == "Scaling") {
      return "#F56F65";
    }
    if (stage == "Early revenue") {
      return "#6B43D4";
    } else {
      return "#00C0CC";
    }
  };

  const [isBookmarked, setIsBookmarked] = useState(data?.bookmark);
  const [isApplicationSend, setIsApplicationSend] = useState(
    data?.applicationRecieved
  );

  const bookmarkHandler = async () => {
    try {
      const response = await axiosApi.patch(
        `${process.env.REACT_APP_BASE_URL}bookmark/mentor/${data._id}`
      );
      if (fromBookmark) {
        dispatch(removeBookmark({ id: data._id }));
      }
      await setIsBookmarked(!isBookmarked);
    } catch (error) {}
  };

  const applyHanlder = async () => {
    try {
      const response = await axiosApi.patch(
        `${process.env.REACT_APP_BASE_URL}application/mentor/${data._id}`,
        {
          description: description,
        }
      );
      setStatus("received");
    } catch (error) {}
  };

  const directMessageHandler = async ({ chatpage }) => {
    try {
      const response = await axiosApi.get(
        `${process.env.REACT_APP_BASE_URL}chat/check-chat/${data?._id}`
      );
      if (response?.data?.data) {
        await navigate(`/inbox?conversation=${response?.data?.data?._id}`);
      } else {
        await navigate(`/inbox?model=Mentor&modelId=${data?._id}`);
      }
    } catch (error) {}
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
    width: "1200px",
    height: "500px",
    maxHeight: "500px",
    margin: "auto",
    border: "none",
  };
  const [openModal, setOpenModal] = useState(false);
  const closeModal = () => {
    setOpenModal(false);
  };
  const style1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
    width: 600,
    bgcolor: mode ? "#333345" : "#fff",
    border: "2px solid #333345",

    boxShadow: 24,
    p: 5,
  };

  const onApplyMessageCancel = () => {
    closeModal();
  };
  const onApplyMessageSubmit = (message) => {
    closeModal();
    applyHanlder();
  };
  return (
    <React.Fragment>
      <Modal
        open={openModal}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style1}>
          <ApplyMessage
            description={description}
            setDescription={setDescription}
            onCancel={onApplyMessageCancel}
            onSubmit={onApplyMessageSubmit}
          />
        </Box>
      </Modal>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="dark-bg-1">
          <CardNotes onClose={handleClose} id={data?._id} model={"mentor"} />
        </Box>
      </Modal>
      <Box
        onMouseOver={() => {
          setMouseHover(true);
        }}
        onMouseLeave={() => {
          setMouseHover(false);
        }}
        sx={{
          height: "300px",
          cursor: "pointer",
          boxShadow: mouseHover ? "0.3rem 0.3rem #ee4b6a" : "",
          transition: "all .14s ease-out",
        }}
        p={3}
        className={mode ? "dark-bg-5" : "light-bg-5"}
        onClick={() => {
          if (onClose) {
            onClose();
          }
          navigate({
            pathname: `/mentor-detail/${data?._id}`,
            search: queryString,
          });
        }}
      >
        <Stack
          direction="column"
          gap={2}
          justifyContent="space-between"
          sx={{
            height: "100%",
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            sx={{
              width: "100%",
            }}
          >
            <>
              {data?.profileUrl ? (
                <img
                  src={data?.profileUrl}
                  style={{
                    height: "100px",
                    aspectRatio: "1/1",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <Box
                  height={"100px"}
                  sx={{
                    aspectRatio: "1/1",
                  }}
                  className={mode ? "dark-bg-6" : "light-bg-6"}
                  p={3}
                >
                  <Stack
                    direction="column"
                    justifyContent="center"
                    sx={{
                      height: "100%",
                    }}
                  >
                    <Typography
                      fontWeight={"bold"}
                      variant="h4"
                      color="secondary"
                      align="center"
                      sx={{
                        textTransform: "uppercase",
                      }}
                    >
                      {data?.firstName?.slice(0, 2)}
                    </Typography>
                  </Stack>
                </Box>
              )}
            </>

            <Box
              sx={{
                width: "100%",
              }}
            >
              <Stack
                sx={{
                  width: "100%",
                }}
                direction="row"
                justifyContent="space-between"
                spacing={1}
              >
                <Stack
                  direction="column"
                  spacing={1}
                  sx={{
                    width: "100%",
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent={"space-between"}
                    sx={{
                      width: "100%",
                      height: "30px",
                    }}
                  >
                    <Typography
                      color="secondary"
                      variant="body1"
                      sx={{
                        WebkitLineClamp: 1,
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      {data?.firstName + " " + data?.lastName}
                    </Typography>
                    {mouseHover && (
                      <Stack direction="row">
                        <Typography
                          color="secondary"
                          variant="body2"
                          sx={{
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            bookmarkHandler();
                          }}
                        >
                          {isBookmarked ? (
                            <BookmarkIcon />
                          ) : (
                            <BookmarkBorderOutlinedIcon />
                          )}
                        </Typography>
                        <MoreVertIcon
                          style={{
                            cursor: "pointer",
                          }}
                          ref={anchorEl}
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpenPopup(true);
                            setAnchorEl(e.currentTarget);
                          }}
                        />
                        <Popover
                          open={openPopup}
                          anchorEl={anchorEl}
                          onClose={closePopup}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          // transformOrigin={{
                          //   vertical: "top",
                          //   horizontal: "right",
                          // }}
                          sx={{
                            maxWidth: "70%",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <CardMore
                            onOpen={(e) => {
                              e.stopPropagation();
                              dispatch(
                                selectNote({
                                  model: "mentor",
                                  id: data?._id,
                                })
                              );
                              handleOpen();
                              closePopup();
                            }}
                          />
                        </Popover>
                      </Stack>
                    )}
                  </Stack>
                  <Stack direction="column">
                    <Stack
                      direction="row"
                      spacing={1}
                      justifyContent="flex-start"
                      alignItems={"center"}
                    >
                      <Typography className={mode ? "dark-4" : "light-4"}>
                        <ApartmentOutlinedIcon fontSize="10px" />
                      </Typography>
                      <Typography
                        variant="body2"
                        className={mode ? "dark-4" : "light-4"}
                      >
                        <span
                          style={{
                            verticalAlign: "middle",
                            WebkitLineClamp: 1,
                            display: "-webkit-box",
                            overflow: "hidden",
                            WebkitBoxOrient: "vertical",
                            WebkitBoxPack: "end",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {data?.company?.length > 8
                            ? data?.company?.slice(0, 8) + "..."
                            : data?.company}
                        </span>
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={1}
                      justifyContent="flex-start"
                      alignItems={"center"}
                    >
                      <Typography className={mode ? "dark-4" : "light-4"}>
                        <LocationOnOutlinedIcon fontSize="10px" />
                      </Typography>
                      <Typography
                        variant="body2"
                        className={mode ? "dark-4" : "light-4"}
                      >
                        <span
                          style={{
                            verticalAlign: "middle",
                            WebkitLineClamp: 1,
                            display: "-webkit-box",
                            overflow: "hidden",
                            WebkitBoxOrient: "vertical",
                            WebkitBoxPack: "end",
                          }}
                        >
                          {data?.city + ", " + data?.country}
                        </span>
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={1}
                      alignContent="center"
                      alignItems="center"
                    >
                      <Typography className={mode ? "dark-4" : "light-4"}>
                        <BadgeOutlinedIcon fontSize="10px" />
                      </Typography>
                      <Typography
                        variant="body2"
                        className={mode ? "dark-4" : "light-4"}
                        sx={{
                          WebkitLineClamp: 1,
                          display: "-webkit-box",
                          overflow: "hidden",
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {data?.role?.length > 10
                          ? data?.role?.slice(0, 10) + "..."
                          : data?.role}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                <Box>
                  {" "}
                  {/* <Stack direction="row" spacing={1}>
                    <Typography
                      color={data?.noteId ? "primary" : "secondary"}
                      variant="body2"
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        dispatch(
                          selectNote({
                            model: "mentor",
                            id: data?._id,
                          })
                        );
                        handleOpen();
                      }}
                    >
                      <TextSnippetIcon />
                    </Typography>
                    <Typography
                      color="secondary"
                      variant="body2"
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        bookmarkHandler();
                      }}
                    >
                      {isBookmarked ? (
                        <BookmarkIcon />
                      ) : (
                        <BookmarkBorderOutlinedIcon />
                      )}
                    </Typography>
                  </Stack> */}
                </Box>
              </Stack>
            </Box>
          </Stack>
          <Stack
            direction="row"
            gap={1}
            divider={<Divider orientation="vertical" flexItem />}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="body2">
                <SkillIcon color={"#ee4b6a"} />
              </Typography>
              <Typography color="secondary" variant="body2">
                {data?.skill?.map(
                  (item, index) =>
                    `${item.skill} ${
                      data?.skill?.length - 1 !== index ? " | " : ""
                    }`
                )}
              </Typography>
            </Stack>
          </Stack>
          <Grid container direction="row" justifyContent="center" spacing={2}>
            <Grid item xs={6}>
              {status ? (
                <Button color="warning" variant="contained" fullWidth>
                  Interest Sent
                </Button>
              ) : (
                <Button
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenModal(true);
                  }}
                >
                  Interested
                </Button>
              )}
              {/* {isApplicationSend ? (
                <Button color="warning" variant="contained" fullWidth>
                  Application Sent
                </Button>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  onClick={applyHanlder}
                >
                  Apply
                </Button>
              )} */}
            </Grid>
            {data?.dm && (
              <Grid item xs={6}>
                <Button
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  onClick={(e) => {
                    e.stopPropagation();
                    directMessageHandler({ chatpage: true });
                  }}
                >
                  Direct Message
                </Button>
              </Grid>
            )}
          </Grid>
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default Index;
