import { useSearchParams } from "react-router-dom";
import {
  Box,
  Modal,
  Stack,
  Typography,
  Divider,
  Popover,
  TextField,
} from "@mui/material";
import ConfirmationPop from "../../../ConfirmationsPopup";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateListData,
  deleteList,
  addList,
} from "../../../../redux/tasks/lists/listsSlice";
import {
  updateGroupListData,
  deleteGroupList,
  addGroupList,
} from "../../../../redux/tasks/groups/lists/listsSlice";
import useClickOutside from "../../../../utils/useClickOutside";
import DoneIcon from "@mui/icons-material/Done";
import axiosApi from "../../../../utils/httpClient";
const Index = ({ data }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const mode = useSelector((store) => store.mode.mode);
  const [moveToGroup, setMoveToGroup] = useState(null);
  const groups = useSelector((store) => store.groups.groups);
  const [moveMouseHover, setMoveMouseHover] = useState(false);
  const [listName, setListName] = useState("");
  const [listName1, setListName1] = useState("");
  const [edit, setEdit] = useState(false);
  const listNameRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const handleOpenDelete = () => setDeleteOpen(true);
  const handleCloseDelete = () => {
    setDeleteOpen(false);
    setOpenPopup(false);
  };
  const [openPopup, setOpenPopup] = useState(false);
  const closePopup = () => {
    setOpenPopup(false);
  };
  const [openMovePopup, setOpenMovePopup] = useState(false);
  const closeMovePopup = () => {
    setOpenMovePopup(false);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
    maxHeight: "500px",
    margin: "auto",
    border: "none",
    p: 2,
  };

  const moveList = async (id) => {
    try {
      const resp = await axiosApi.patch(`list/${data?._id}`, {
        group: id === -1 ? null : id,
      });
      if (id === -1) {
        await dispatch(addList({ data: resp.data.data }));
      } else {
        if (searchParams.get("group") === id) {
          dispatch(addGroupList({ data: resp.data.data }));
        }
      }
      if (data?.group) {
        dispatch(deleteGroupList({ id: data?._id }));
      } else {
        dispatch(deleteList({ id: data?._id }));
      }
    } catch (err) {}
  };

  useClickOutside(listNameRef, () => {
    setEdit(false);
  });

  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };
  const updateList = async () => {
    if (listName1) {
      const formData = {};
      formData["name"] = listName;
      const resp = await axiosApi.patch(`/list/${data._id}`, formData);
      if (data?.group) {
        await dispatch(
          updateGroupListData({
            id: data._id,
            data: resp.data.data,
          })
        );
      } else {
        await dispatch(
          updateListData({
            id: data._id,
            data: resp.data.data,
          })
        );
      }
    }
  };

  const listUpdate = debounce(function (e) {
    setListName1(e.target.value);
  }, 1000);
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      updateList();
      setEdit(false);
    }
  };
  useEffect(() => {
    setListName(data?.name);
  }, [data]);

  const listDelete = async () => {
    const resp = await axiosApi.delete(`list/${data?._id}`);
    if (data._id == searchParams.get("list")) {
      searchParams.delete("list");
      setSearchParams(searchParams);
    }
    await handleCloseDelete();
    if (data?.group) {
      await dispatch(deleteGroupList({ id: data._id }));
    } else {
      await dispatch(deleteList({ id: data._id }));
    }
  };

  return (
    <React.Fragment>
      <Modal
        open={deleteOpen}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={mode ? "dark-bg-1" : "light-bg-2"}>
          <ConfirmationPop
            onSubmit={listDelete}
            onCancel={handleCloseDelete}
            message={"Do you want to delete the list?"}
            title={"Delete List"}
          />
        </Box>
      </Modal>
      <Stack direction="column" spacing={2}>
        <Stack
          key={data._id}
          direction="row"
          spacing={2}
          aligndatas="center"
          justifyContent={"space-between"}
          className={
            mode
              ? data._id == searchParams.get("list")
                ? "light-1"
                : "light-2"
              : data._id == searchParams.get("list")
              ? "dark-8"
              : "dark-2"
          }
          sx={{
            cursor: "pointer",
            width: "100%",
          }}
          onClick={() => {
            if (!edit) {
              if (searchParams.get("filter")) {
                searchParams.delete("filter");
              }
              if (!data?.group) {
                if (searchParams.get("group")) {
                  searchParams.delete("group");
                }
              }

              if (searchParams.get("list") == data._id) {
                searchParams.delete("list");
                setSearchParams(searchParams);
              } else {
                searchParams.set("list", data._id);
                setSearchParams(searchParams);
              }
            }
          }}
        >
          {edit ? (
            <TextField
              ref={listNameRef}
              value={listName}
              type="text"
              id="description"
              name="description"
              placeholder="Enter list name"
              InputProps={{
                style: {
                  height: "30px",
                },
                maxLength: 300,
                classes: {
                  input: `${mode ? "inputFieldDark" : "inputFieldLight"}`,
                },
                className: `${mode ? "dark-bg-2 " : "light-bg-2 "}`,
              }}
              onChange={(e) => {
                e.stopPropagation();
                setListName(e.target.value);
                listUpdate(e);
              }}
              onKeyDown={handleKeyDown}
            />
          ) : (
            <Typography fontSize="16px">{data.name}</Typography>
          )}
          <Stack direction="row" spacing={1}>
            <MoreVertIcon
              onClick={(e) => {
                e.stopPropagation();
                setOpenPopup(true);
                setAnchorEl(e.currentTarget);
              }}
            />
            <Popover
              open={openPopup}
              anchorEl={anchorEl}
              onClose={closePopup}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              sx={{
                maxWidth: "70%",
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Stack
                className={mode ? "dark-bg-9" : "light-bg-9"}
                direction="column"
                spacing={1}
                divider={<Divider orientation="horizontal" />}
                sx={{
                  p: 1,
                }}
              >
                <Typography
                  fontSize="12px"
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenPopup(false);
                    handleOpenDelete();
                  }}
                >
                  Delete
                </Typography>
                <Typography
                  fontSize="12px"
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenPopup(false);
                    setEdit(true);
                  }}
                >
                  Rename
                </Typography>
                <Typography
                  onMouseOver={() => {
                    setMoveMouseHover(true);
                  }}
                  onMouseLeave={() => {
                    setMoveMouseHover(false);
                  }}
                  fontSize="12px"
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenMovePopup(true);
                    setAnchorEl1(e.currentTarget);
                  }}
                >
                  Move
                </Typography>
                <Popover
                  open={openMovePopup}
                  anchorEl={anchorEl1}
                  onClose={closeMovePopup}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  sx={{
                    maxWidth: "70%",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Stack
                    className={mode ? "dark-bg-8" : "light-bg-9"}
                    direction="column"
                    spacing={1}
                    divider={<Divider orientation="horizontal" />}
                    sx={{
                      p: 1,
                    }}
                  >
                    <Typography
                      fontSize="12px"
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenMovePopup(false);
                        setOpenPopup(false);
                        moveList(-1);
                      }}
                    >
                      Lists
                    </Typography>
                    {groups &&
                      groups?.length > 0 &&
                      groups?.map((item) => (
                        <Stack direction="row" spacing={2} alignItems="center">
                          <Typography
                            fontSize="12px"
                            sx={{
                              cursor: "pointer",
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setOpenMovePopup(false);
                              setOpenPopup(false);
                              moveList(item._id);
                            }}
                          >
                            {item?.name}
                          </Typography>
                          {data?.group === item._id && <DoneIcon />}
                        </Stack>
                      ))}
                  </Stack>
                </Popover>
              </Stack>
            </Popover>
          </Stack>
        </Stack>
      </Stack>
    </React.Fragment>
  );
};
export default Index;
