import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as qs from "qs";
import axiosApi from "../../../utils/httpClient";
const name = "exploreExperts";
const initialState = {
  exploreExperts: [],
  loading: false,
  error: null,
};

export const exploreExpertsAPI = createAsyncThunk(
  "exploreExperts",
  async ({
    skip,
    limit,
    profileType,
    country,
    industries,
    stage,
    skills,
    search,
  }) => {
    var formData = {};
    // formData["id"] = id;
    formData["skip"] = skip;
    // formData["model"] = model;
    // formData["status"] = status;
    formData["limit"] = limit;
    if (profileType) {
      formData["type"] = profileType;
    }
    if (country) {
      formData["country"] = country;
    }
    if (industries) {
      formData["industries"] = industries;
    }
    if (stage) {
      formData["stage"] = stage;
    }
    if (skills) {
      formData["skills"] = skills;
    }
    if (search) {
      formData["search"] = search;
    }

    const data = await axiosApi.get(
      `${process.env.REACT_APP_BASE_URL}expert/`,
      {
        params: formData,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      }
    );
    return data.data;
  }
);

const exploreExpertsSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(exploreExpertsAPI.pending, (state) => {
      state.exploreExperts = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(exploreExpertsAPI.fulfilled, (state, action) => {
      state.exploreExperts = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(exploreExpertsAPI.rejected, (state, action) => {
      state.exploreExperts = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default exploreExpertsSlice.reducer;
