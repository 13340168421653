import {
  Box,
  Button,
  Checkbox,
  Grid,
  ListItemText,
  Modal,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";

const Index = ({ onClose, mine, onOpen }) => {
  const mode = useSelector((store) => store.mode.mode);
  const [reportUserModalOpen, setReportUserModalOpen] = useState(false);
  const reportUserClose = () => {
    setReportUserModalOpen(false);
  };
  const [blockModalOpen, setBlockModalOpen] = useState(false);
  const blockClose = () => {
    setBlockModalOpen(false);
  };

  return (
    <React.Fragment>
      <Box
        p={1}
        spacing={1}
        className={mode ? "dark-bg-9" : "light-bg-9"}
        sx={{
          width: "max-content",
        }}
      >
        <Stack direction="column" spacing={2}>
          <Typography
            variant="body2"
            color="secondary"
            sx={{
              cursor: "pointer",
            }}
            onClick={onOpen}
          >
            Notes
          </Typography>
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default Index;
