import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getCookie } from "./httpClient";

import { useSelector, useDispatch } from "react-redux";
import Loader from "../components/Loader";
import { profileAPI } from "../redux/auth/profile/profileSlice";
import { useEffect } from "react";

function HomeRedirect({ children }) {
  const isAuthenticated = getCookie("token");
  const dispatch = useDispatch();

  const profile = useSelector((store) => store.profile);

  const getProfile = () => {
    dispatch(profileAPI());
  };
  useEffect(() => {
    getProfile();
  }, []);

  if (localStorage.getItem("token")) {
    if (localStorage.getItem("profile")) {
      if (localStorage.getItem("profile") == "Startup") {
        return <Navigate to="/search/investors?mode=discover" />;
      } else if (localStorage.getItem("profile") == "Investor") {
        return <Navigate to="/search/companies?mode=discover" />;
      } else if (localStorage.getItem("profile") == "Mentor") {
        return <Navigate to="/search/companies?mode=discover" />;
      } else if (localStorage.getItem("profile") == "Expert") {
        return <Navigate to="/search/companies?mode=discover" />;
      } else {
        return <Navigate to="/welcome" />;
      }
    } else {
      if (profile?.data?.user?.whichProfile) {
        if (profile?.data?.user?.whichProfile == "Company") {
          return <Navigate to="/search/investors?mode=discover" />;
        } else if (profile?.data?.user?.whichProfile == "Investor") {
          return <Navigate to="/search/companies?mode=discover" />;
        } else if (profile?.data?.user?.whichProfile == "Mentor") {
          return <Navigate to="/search/companies?mode=discover" />;
        } else if (profile?.data?.user?.whichProfile == "Expert") {
          return <Navigate to="/search/companies?mode=discover" />;
        } else if (
          profile?.data?.company?.status === "submitted" ||
          profile?.data?.company?.status === "rejected"
        ) {
          return (
            <Navigate
              to={`/application-status/company/${profile?.data?.company?._id}`}
            />
          );
        } else if (
          profile?.data?.individual?.status === "submitted" ||
          profile?.data?.individual?.status === "rejected"
        ) {
          return (
            <Navigate
              to={`/application-status/individual/${profile?.data?.individual?._id}`}
            />
          );
        } else if (
          profile?.data?.institution?.status === "submitted" ||
          profile?.data?.institution?.status === "rejected"
        ) {
          return (
            <Navigate
              to={`/application-status/institution/${profile?.data?.institution?._id}`}
            />
          );
        } else if (
          profile?.data?.mentor?.status === "submitted" ||
          profile?.data?.mentor?.status === "rejected"
        ) {
          return (
            <Navigate
              to={`/application-status/mentor/${profile?.data?.mentor?._id}`}
            />
          );
        } else if (
          profile?.data?.expert?.status === "submitted" ||
          profile?.data?.expert?.status === "rejected"
        ) {
          return (
            <Navigate
              to={`/application-status/expert/${profile?.data?.expert?._id}`}
            />
          );
        } else {
          return <Navigate to="/welcome" />;
        }
      }
    }
  } else {
    return children;
  }
}

export default HomeRedirect;
