import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import HeaderNav from "../LandingHeader";
import BottomNav from "../BottomNav";
import { Container, Stack, Box } from "@mui/material";
import Cookies from "../../components/Cookies";
import { profileAPI } from "../../redux/auth/profile/profileSlice";
import Footer from "../../components/Footer";
function MainLayout() {
  let store = useSelector((store) => store);

  const profile = useSelector((store) => store.profile);

  const signin = useSelector((store) => store.signin);
  const dispatch = useDispatch();
  const [showCookie, setShowCookie] = useState(null);
  useEffect(() => {
    setShowCookie(localStorage.getItem("cookieAccept"));
  }, [localStorage]);
  return (
    <React.Fragment>
      <Box
        className="land-font"
        sx={{
          background: "#fff",
        }}
      >
        <Stack direction="column">
          <HeaderNav />
        </Stack>
        {showCookie ? null : (
          <Box
            sx={{
              position: "fixed",
              bottom: "50px",
              right: { sm: "50px", xs: "10px" },

              zIndex: "1000",
            }}
          >
            <Cookies onAction={() => setShowCookie(true)} />
          </Box>
        )}
        <Outlet />
      </Box>
    </React.Fragment>
  );
}
export default MainLayout;
