import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import InvestorCard from "../dashboard/startup/Card";
import MentorCard from "../dashboard/mentor/Card";
import StartupCard from "../dashboard/investor/Card";
import CloseIcon from "@mui/icons-material/Close";
import axiosApi from "../../utils/httpClient";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
const Index = ({ onClose }) => {
  const [invData, setInvData] = useState({});
  const mode = useSelector((store) => store.mode.mode);
  const dimension = useSelector((store) => store.dimension);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [mentorData, setMentorData] = useState({});
  const [companyData, setCompanyData] = useState({});
  const getMatchData = async () => {
    const response = await axiosApi.get(`/match`);
    if (localStorage.getItem("profile") == "Startup") {
      setInvData(response?.data?.data?.individual);
      setMentorData(response?.data?.data?.mentor);
    } else if (
      localStorage.getItem("profile") == "Investor" ||
      localStorage.getItem("profile") == "Mentor"
    ) {
      setCompanyData(response?.data?.data?.company);
    }
  };

  useEffect(() => {
    getMatchData();
  }, []);

  return (
    <React.Fragment>
      <Stack
        sx={{
          mt: { sm: 15, xs: 4 },
          mb: { sm: 0, xs: 10 },
        }}
        style={{
          height: dimension.width > 600 ? "calc(100vh - 150px)" : "max-content",
        }}
      >
        <Stack
          className={mode ? "dark-bg-2" : "light-bg-2"}
          sx={{
            height: "100%",
            width: "100%",
            p: { sm: 2, xs: 1 },
          }}
        >
          {" "}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              width: "100%",
            }}
          >
            <Typography
              className={mode ? "dark-4" : "light-4"}
              variant="h5"
              sx={{
                textTransform: "uppercase",
              }}
            >
              Match
            </Typography>
            <CloseIcon
              className={mode ? "dark-4" : "light-4"}
              sx={{
                cursor: "pointer",
              }}
              onClick={() => {
                if (searchParams.get("entry")) {
                  navigate(`${searchParams.get("entry")}`);
                } else {
                  navigate(`/`);
                }
              }}
            />
          </Stack>
          <Grid
            container
            sx={{
              pt: 4,
              px: { sm: 5 },
            }}
            columnSpacing={5}
          >
            {localStorage.getItem("profile") == "Startup" && (
              <>
                {invData?.firstName && (
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={2}>
                      <Typography color="secondary" fontSize={{ sm: "20px" }}>
                        Investor
                      </Typography>
                      <InvestorCard
                        data={invData}
                        type="Individual"
                        onClose={onClose}
                      />
                    </Stack>
                  </Grid>
                )}
                {mentorData?.firstName && (
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={2}>
                      <Typography color="secondary" fontSize={{ sm: "20px" }}>
                        Mentor
                      </Typography>
                      <MentorCard data={mentorData} onClose={onClose} />
                    </Stack>
                  </Grid>
                )}
              </>
            )}
            {localStorage.getItem("profile") == "Investor" && (
              <>
                {companyData?.companyName && (
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={2}>
                      <Typography color="secondary" fontSize={{ sm: "20px" }}>
                        Company
                      </Typography>
                      <StartupCard data={companyData} onClose={onClose} />
                    </Stack>
                  </Grid>
                )}
              </>
            )}
            {localStorage.getItem("profile") == "Mentor" && (
              <>
                {companyData?.companyName && (
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={2}>
                      <Typography color="secondary" fontSize={{ sm: "20px" }}>
                        Company
                      </Typography>
                      <StartupCard data={companyData} onClose={onClose} />
                    </Stack>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Stack>
      </Stack>
    </React.Fragment>
  );
};

export default Index;
