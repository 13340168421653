import { Button, Stack, Typography } from "@mui/material";
import React from "react";
const Index = ({ onSubmit, onCancel, message, title }) => {
  return (
    <React.Fragment>
      <Stack
        direction="column"
        spacing={1}
        justifyContent="center"
        alignItems={"center"}
        alignContent="center"
      >
        <Typography variant="h6" color="secondary" fontWeight={"500"}>
          {title}
        </Typography>
        <Typography variant="body1" color="secondary">
          {message}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        spacing={5}
        justifyContent={"center"}
        alignItems="center"
        alignContent="center"
        sx={{
          mt: 5,
        }}
      >
        <Button
          variant="outlined"
          color="secondary"
          sx={{
            px: 1,
            py: 1,
            minWidth: "100px",
          }}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          sx={{
            px: 1,
            py: 1,
            minWidth: "100px",
          }}
          variant="contained"
          color="primary"
          onClick={() => {
            onSubmit();
          }}
        >
          Submit
        </Button>
      </Stack>
    </React.Fragment>
  );
};

export default Index;
