import { useLocation, useSearchParams } from "react-router-dom";

const useQueryString = ({ type, close, tab }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  let queryString = "?";

  for (const entry of searchParams.entries()) {
    const [param, value] = entry;
    if (close) {
      if (param !== type) {
        queryString = queryString + `${param}=${value}&`;
      }
    } else {
      queryString = queryString + `${param}=${value}&`;
    }
  }
  queryString =
    queryString +
    `${tab ? "tab=" + tab + "&" : ""}${
      searchParams.get(type) ? "" : type + "=" + location.pathname
    }`;
  return queryString;
};
export default useQueryString;
