import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const name = "conversation";
const initialState = {
  conversation: "",
  directMessageCond: false,
  loading: false,
  error: null,
};

export const selectConversation = createAsyncThunk(
  "conversation",
  async (id) => {
    return id;
  }
);

export const setDirectMessagefromCard = createAsyncThunk(
  "setDirectMessagefromCard",
  async (cond) => {
    return cond;
  }
);

const selectConversationSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(selectConversation.fulfilled, (state, action) => {
      state.conversation = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(setDirectMessagefromCard.fulfilled, (state, action) => {
      state.directMessageCond = action.payload;
      state.loading = false;
      state.error = null;
    });
  },
});

export default selectConversationSlice.reducer;
