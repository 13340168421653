import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as qs from "qs";
import axiosApi from "../../../../utils/httpClient";
const name = "grouplists";
const initialState = {
  grouplists: [],
  loading: false,
  error: null,
};

export const groupListsAPI = createAsyncThunk("grouplists", async (id) => {
  const data = await axiosApi.get(
    `${process.env.REACT_APP_BASE_URL}group/${id}`
  );

  return data?.data?.data?.lists;
});
export const addGroupList = createAsyncThunk(
  "grouplists/addList",
  async ({ data }) => {
    return { data };
  }
);

export const updateGroupListData = createAsyncThunk(
  "grouplists/updateListData",
  async ({ id, data }) => {
    return { id, data };
  }
);

export const deleteGroupList = createAsyncThunk(
  "grouplists/deleteList",
  async ({ id }) => {
    return { id };
  }
);

const listsSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(groupListsAPI.pending, (state) => {
      state.grouplists = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(groupListsAPI.fulfilled, (state, action) => {
      state.grouplists = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(groupListsAPI.rejected, (state, action) => {
      state.grouplists = [];
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(updateGroupListData.fulfilled, (state, action) => {
      const objIndex = state.grouplists.findIndex(
        (obj) => obj._id == action.payload.id
      );
      state.grouplists[objIndex] = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(deleteGroupList.fulfilled, (state, action) => {
      const filteredLists = state.grouplists.filter(
        (item) => item._id !== action.payload.id
      );
      state.grouplists = filteredLists;
      state.loading = false;
      state.error = null;
    });

    builder.addCase(addGroupList.fulfilled, (state, action) => {
      state.grouplists = [action.payload.data, ...state.grouplists];
      state.loading = false;
      state.error = null;
    });
  },
});

export default listsSlice.reducer;
