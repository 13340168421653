import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getCookie } from "./httpClient";

import { useSelector } from "react-redux";

function MultiInvestorAccount({ children }) {
  const isAuthenticated = getCookie("token");

  const profile = useSelector((store) => store.profile);
  let location = useLocation();

  if (
    (profile?.data?.institution &&
      profile?.data?.institution?.status &&
      profile?.data?.institution?.status == "approved") ||
    (profile?.data?.individual &&
      profile?.data?.individual?.status &&
      profile?.data?.individual?.status == "approved")
  ) {
    return (
      <Navigate
        to="/search/companies?mode=discover"
        //   state={{ from: location }} replace
      />
    );
  } else {
    return children;
  }
}

export default MultiInvestorAccount;
