import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosApi from "../../utils/httpClient";

const name = "eventkinds";
const initialState = {
  eventkinds: [],
  loading: false,
  error: null,
};

export const fetchEventkinds = createAsyncThunk(
  "eventkinds/fetchEventkinds",
  async () => {
    const data = await axiosApi.get(
      `${process.env.REACT_APP_BASE_URL}event-kind`
    );
    return data.data;
  }
);

const eventkindsSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchEventkinds.pending, (state) => {
      state.eventkinds = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchEventkinds.fulfilled, (state, action) => {
      state.eventkinds = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(fetchEventkinds.rejected, (state, action) => {
      state.eventkinds = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default eventkindsSlice.reducer;
