import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const name = "selectedInvestorTypes";
const initialState = {
  selectedInvestorTypes: [],
  loading: false,
  error: null,
};

export const selectInvestorTypes = createAsyncThunk(
  "countries/selectInvestorTypes",
  async (investortypes) => {
    return investortypes;
  }
);

const selectedInvestorTypesSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(selectInvestorTypes.fulfilled, (state, action) => {
      state.selectedInvestorTypes = action.payload;
      state.loading = false;
      state.error = null;
    });
  },
});

export default selectedInvestorTypesSlice.reducer;
