import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as qs from "qs";
import axiosApi from "../../utils/httpClient";
const name = "myevents";
const initialState = {
  myevents: [],
  loading: false,
  error: null,
};

export const myeventsAPI = createAsyncThunk(
  "myevents",
  async ({ skip, limit, id, status }) => {
    var formData = {};

    formData["skip"] = skip;
    formData["status"] = status;

    formData["limit"] = limit;

    const data = await axiosApi.get(
      `${process.env.REACT_APP_BASE_URL}event/my/events/`,
      {
        params: formData,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      }
    );
    return data.data;
  }
);

const myeventsSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(myeventsAPI.pending, (state) => {
      state.myevents = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(myeventsAPI.fulfilled, (state, action) => {
      state.myevents = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(myeventsAPI.rejected, (state, action) => {
      state.myevents = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default myeventsSlice.reducer;
