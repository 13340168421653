import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as qs from "qs";
import axiosApi from "../../../utils/httpClient";
const name = "cardNote";
const initialState = {
  cardNote: [],
  loading: false,
  error: null,
};

export const fetchNote = createAsyncThunk(
  "fetchNote",
  async ({ model, id }) => {
    const data = await axiosApi.get(
      `${process.env.REACT_APP_BASE_URL}note/${model}/${id}`
    );
    if (data?.data) {
      return data.data;
    } else {
      return {};
    }
  }
);

const fetchNoteSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchNote.pending, (state) => {
      state.cardNote = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchNote.fulfilled, (state, action) => {
      state.cardNote = action.payload.data ? action.payload.data : {};
      state.loading = false;
      state.error = null;
    });
    builder.addCase(fetchNote.rejected, (state, action) => {
      state.cardNote = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default fetchNoteSlice.reducer;
