import {
  Container,
  Box,
  Typography,
  Stack,
  Button,
  ListItem,
  List,
} from "@mui/material";
import React, { useState } from "react";
import Footer from "../../components/Footer";
import { useAos } from "../../utils/hooks/useAos";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
const Index = () => {
  const [filter, setFilter] = useState("Privacy");
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <React.Fragment>
      <Box
        sx={{
          background: "#fff",
          width: "100%",
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            py: 10,
          }}
        >
          <Box
            sx={{
              backgroundImage: `url("/assets/privacy.jpg")`,
              backgroundSize: "cover",
              width: "100%",
              height: { sm: "400px", xs: "300px" },
              p: { sm: 10, xs: 3 },
              mt: { sm: 5 },
            }}
          ></Box>
          <Stack
            spacing={2}
            sx={{
              mt: { sm: 5, xs: 3 },
            }}
          >
            <Typography
              data-aos="fade-up"
              className="dark-8"
              fontWeight="500"
              fontSize={{ sm: "40px", xs: "26px" }}
              sx={{
                maxWidth: { sm: "60%" },
                lineHeight: { sm: "45px" },
              }}
            >
              Legal <br /> Information
            </Typography>
            <Typography
              data-aos="fade-up"
              className="dark-8"
              fontSize="18px"
              sx={{
                maxWidth: { sm: "50%" },
                lineHeight: { sm: "23px" },
              }}
            >
              This page contains information pertaining to Beblossom's privacy
              policy, terms of use and cookie policy for accessing Beblossom's
              products and services.
            </Typography>
          </Stack>
          <Stack
            className="hide-scrollbar"
            direction="row"
            spacing={2}
            alignItems="center"
            alignContent="center"
            sx={{
              mt: 5,
              width: "100%",
              overflow: "scroll",
            }}
          >
            <Button
              color={
                location.pathname.includes("/legal-links/privacy-policy")
                  ? "primary"
                  : "primary"
              }
              variant={
                location.pathname.includes("/legal-links/privacy-policy")
                  ? "contained"
                  : "outlined"
              }
              sx={{
                minWidth: "max-content",
              }}
              onClick={() => {
                navigate("/legal-links/privacy-policy");
              }}
            >
              Privacy
            </Button>
            <Button
              color={
                location.pathname.includes("/legal-links/terms-and-conditions")
                  ? "primary"
                  : "primary"
              }
              variant={
                location.pathname.includes("/legal-links/terms-and-conditions")
                  ? "contained"
                  : "outlined"
              }
              sx={{
                minWidth: "max-content",
              }}
              onClick={() => {
                navigate("/legal-links/terms-and-conditions");
              }}
            >
              Terms & Conditions
            </Button>
            <Button
              color={
                location.pathname.includes("/legal-links/cookie-policy")
                  ? "primary"
                  : "primary"
              }
              variant={
                location.pathname.includes("/legal-links/cookie-policy")
                  ? "contained"
                  : "outlined"
              }
              sx={{
                minWidth: "max-content",
              }}
              onClick={() => {
                navigate("/legal-links/cookie-policy");
              }}
            >
              Cookie Policy
            </Button>
          </Stack>
          <Outlet />
        </Container>
        <Footer />
      </Box>
    </React.Fragment>
  );
};

export default Index;
