import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MentorProtectedRoute from "../../utils/mentorProtectedRoutes";
const steps = [
  {
    label: "Become Mentor",
  },
  {
    label: "Mentor Details",
  },

  {
    label: "Review",
  },
];

const Index = () => {
  const mode = useSelector((store) => store.mode.mode);
  const dimension = useSelector((store) => store.dimension);
  let location = useLocation();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  let { id } = useParams();

  // const handleNext = () => {
  // 	setActiveStep(activeStep + 1)
  // }

  React.useEffect(() => {
    if (location.pathname === "/mentor-create/become-mentor") {
      setActiveStep(0);
    } else if (location.pathname === `/mentor-create/mentor-details`) {
      setActiveStep(1);
    } else if (location.pathname === `/mentor-create/mentor-review/${id}`) {
      setActiveStep(2);
    } else if (location.pathname === `/startup-create/review/${id}`) {
      setActiveStep(3);
    }
  }, [location]);

  const handleReset = () => {
    setActiveStep(0);
  };
  return (
    <React.Fragment>
      {dimension.width > 600 ? (
        <Grid
          p={5}
          container
          spacing={1}
          className={mode ? "dark-bg-1" : "light-bg-"}
          mt={10}
          alignContent="center"
        >
          <Grid item xs={2} alignItems="center">
            <Stack direction="row" gap={4}>
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel>{step.label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <Divider orientation="vertical" flexItem />
            </Stack>
          </Grid>

          <Grid item xs={10}>
            <Outlet />
          </Grid>
        </Grid>
      ) : (
        <React.Fragment>
          <Typography
            color="secondary"
            onClick={() => {
              navigate(-1);
            }}
            sx={{
              mt: 2,
            }}
          >
            <ArrowBackIcon />
          </Typography>

          <Box mt={2}>
            <Stepper activeStep={activeStep} orientation="horizontal">
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel>{""}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          <Box mt={2}>
            <Outlet />
          </Box>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Index;
