import { Divider, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
const Index = ({ onAction }) => {
  const [index, setIndex] = useState(0);
  return (
    <React.Fragment>
      <Stack
        data-aos="fade-up"
        sx={{
          background: "#fff",
          width: { sm: "550px", xs: "100%" },
          border: "0.1px solid #ee4b6a",
          //   px: 2,
          //   py: 1,
          ml: { sm: 0, xs: "10px" },
        }}
      >
        <Stack
          direction="row"
          sx={{
            width: "100%",
            height: { sm: "60px" },
          }}
          alignItems="center"
          //   spacing={3}
          justifyContent="space-between"
          divider={
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                borderColor: "#ee4b6a",
              }}
            />
          }
        >
          <Stack
            justifyContent="center"
            sx={{
              width: { sm: "350px" },
              pl: "10px",
            }}
          >
            <Typography fontWeight="600" fontSize="16px" className="dark-8">
              Cookies
            </Typography>
            <Typography fontSize="12px" className="dark-8">
              The website uses cookies for analysis purpose.
            </Typography>
          </Stack>

          <Typography
            fontWeight="600"
            align="center"
            fontSize="40px"
            // className="dark-8"
            sx={{
              cursor: "pointer",
              width: { sm: "100px", xs: "50px" },
              background: "white",
              background:
                "linear-gradient(to left, white 50%, #ee4b6a 50%) right",
              backgroundSize: "200%",
              transition: "1s ease-out",
              backgroundPosition: index == 1 ? "left" : "right",
              color: index == 1 ? "#fff" : "#262736",
            }}
            onClick={() => {
              localStorage.setItem("cookieAccept", true);
              onAction();
            }}
            onMouseEnter={() => {
              setIndex(1);
            }}
            onMouseLeave={() => {
              setIndex(0);
            }}
          >
            {index == 1 ? (
              <Stack
                justifyContent={"center"}
                sx={{
                  height: "60px",
                }}
              >
                {" "}
                <Typography
                  align="center"
                  fontSize="20px"
                  sx={{
                    verticalAlign: "center",
                  }}
                  display="inline"
                >
                  Reject{" "}
                </Typography>
              </Stack>
            ) : (
              <CloseOutlinedIcon />
            )}
          </Typography>
          <Typography
            fontWeight="600"
            align="center"
            fontSize="40px"
            // className="dark-8"
            sx={{
              cursor: "pointer",
              width: { sm: "100px", xs: "50px" },
              background: "white",
              background:
                "linear-gradient(to left, white 50%, #ee4b6a 50%) right",
              backgroundSize: "200%",
              transition: "1s ease-out",
              backgroundPosition: index == 2 ? "left" : "right",
              color: index == 2 ? "#fff" : "#262736",
            }}
            onClick={() => {
              localStorage.setItem("cookieAccept", true);
              onAction();
            }}
            onMouseEnter={() => {
              setIndex(2);
            }}
            onMouseLeave={() => {
              setIndex(0);
            }}
          >
            {index == 2 ? (
              <Stack
                justifyContent={"center"}
                sx={{
                  height: "60px",
                }}
              >
                {" "}
                <Typography
                  align="center"
                  fontSize="20px"
                  sx={{
                    verticalAlign: "center",
                  }}
                  display="inline"
                >
                  Accept{" "}
                </Typography>
              </Stack>
            ) : (
              <DoneIcon />
            )}
          </Typography>
        </Stack>
      </Stack>
    </React.Fragment>
  );
};

export default Index;
