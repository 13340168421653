import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosApi from "../../../utils/httpClient";

const name = "bookmarksMentor";
const initialState = {
  bookmarksMentor: [],
  loading: false,
  error: null,
};

export const bookmarksMentorAPI = createAsyncThunk(
  "bookmarks/mentor",
  async ({ search, skip, limit, id, type }) => {
    const data = await axiosApi.get(
      `${
        process.env.REACT_APP_BASE_URL
      }bookmark/mentors?skip=${skip}&limit=${limit}${
        search != "" ? `&search=${search}` : ""
      }`
    );
    return data.data;
  }
);
export const removeBookmark = createAsyncThunk(
  "bookmarks/removeBookmarkMentor",
  async ({ id }) => {
    return id;
  }
);

const bookmarksMentorSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(bookmarksMentorAPI.pending, (state) => {
      state.bookmarksMentor = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(bookmarksMentorAPI.fulfilled, (state, action) => {
      state.bookmarksMentor = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(bookmarksMentorAPI.rejected, (state, action) => {
      state.bookmarksMentor = [];
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(removeBookmark.fulfilled, (state, action) => {
      const filteredBookmarks = state.bookmarksMentor?.companies.filter(
        (obj) => obj._id !== action.payload
      );
      state.bookmarksMentor.companies = filteredBookmarks;
      state.loading = false;
      state.error = null;
    });
  },
});

export default bookmarksMentorSlice.reducer;
