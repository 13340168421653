import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const name = "selectedInvestmentStage";
const initialState = {
  selectedInvestmentStage: [],
  loading: false,
  error: null,
};

export const selectInvestmentStage = createAsyncThunk(
  "investmentStage/selected",
  async (investmentStage) => {
    return investmentStage;
  }
);

const selectedInvestmentStageSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(selectInvestmentStage.pending, (state) => {
      state.selectedInvestmentStage = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(selectInvestmentStage.fulfilled, (state, action) => {
      state.selectedInvestmentStage = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(selectInvestmentStage.rejected, (state, action) => {
      state.selectedInvestmentStage = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default selectedInvestmentStageSlice.reducer;
