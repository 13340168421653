import { Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const Index = ({ data, onSelect }) => {
  const mode = useSelector((store) => store.mode.mode);
  return (
    <Stack
      className={mode ? "dark-bg-3" : "light-bg-3"}
      sx={{
        px: "5px",
        py: "3px",
        width: "max-content",
        cursor: "pointer",
      }}
      onClick={onSelect}
    >
      <Typography fontSize="12px">{data.tag}</Typography>
    </Stack>
  );
};
export default Index;
