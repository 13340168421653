import React, { useState } from "react";
import { Stack, Typography, Box, TextField, Button } from "@mui/material";
import { useSelector } from "react-redux";
import consts from "../../utils/consts/consts";
const Index = ({ onSubmit, onCancel, description, setDescription }) => {
  const [message, setMessage] = useState("");
  const mode = useSelector((store) => store.mode.mode);
  return (
    <React.Fragment>
      <Stack direction="column" spacing={2}>
        <Typography
          id="modal-modal-title"
          variant="h5"
          align="center"
          color="secondary"
          fontWeight={"500"}
        >
          Enter your message
        </Typography>
        <Typography
          variant="body1"
          align={"center"}
          sx={{
            color: "#E2E2E2",
          }}
        >
          Type a message along with your application for the company
        </Typography>
        <Box
          sx={{
            position: "relative",
          }}
        >
          <TextField
            style={{
              border: "1px solid #ffffff",
            }}
            type="text"
            id="description"
            name="description"
            fullWidth
            multiline
            rows={6}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            placeholder="Enter your message here"
            InputProps={{
              maxLength: 1000,
              classes: {
                input: `${mode ? "inputFieldDark" : "inputFieldLight"}`,
              },
              className: `${mode ? "dark-bg-2 " : "light-bg-2 "}`,
            }}
            error={description?.length > consts.applymessage}
            helperText={
              description?.length > consts.applymessage
                ? `Your message can't exceed ${consts.applymessage} characters.`
                : ""
            }
          />
          <Typography
            variant="caption"
            sx={{
              color: mode ? "gray" : "gray",
              position: "absolute",
              bottom: "5px",
              right: "5px",
            }}
          >
            {description?.length}/{consts.applymessage}
          </Typography>
        </Box>
        <Stack direction="row" justifyContent={"center"} spacing={2}>
          <Button
            variant="outlined"
            color="secondary"
            sx={{
              minWidth: "120px",
              py: 1,
            }}
            onClick={() => {
              onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={!description?.length > 0}
            variant="contained"
            color="primary"
            sx={{
              minWidth: "120px",
              py: 1,
            }}
            onClick={() => {
              onSubmit(message);
            }}
          >
            Submit
          </Button>
        </Stack>
      </Stack>
    </React.Fragment>
  );
};

export default Index;
