const consts = {
  companyName: 50,
  description: 1500,
  jobDescription: 1500,
  firstName: 25,
  lastName: 25,
  eventTitle: 50,
  role: 50,
  applymessage: 1500,
};

export default consts;
