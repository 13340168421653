import { Box, Divider, Stack, Typography, Modal } from "@mui/material";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import React from "react";
import { useSelector } from "react-redux";

const Index = ({
  onClose,
  handleOpenGroup,
  handleOpenList,
  handleOpenTask,
}) => {
  const mode = useSelector((store) => store.mode.mode);

  return (
    <React.Fragment>
      <Box>
        <Stack
          direction="row"
          spacing={2}
          sx={{
            background: "#ee4b6a",
            width: "100%",
            py: 1,
            px: 2,
            color: "#fff",
          }}
        >
          <LibraryAddIcon />
          <Typography fontSize="16px">Add New</Typography>
        </Stack>
        <Stack
          direction="column"
          spacing={2}
          sx={{
            py: 2,
            px: 2,
          }}
          divider={<Divider orientation="horizontal" />}
        >
          <Typography
            fontSize="14px"
            color="secondary"
            sx={{
              cursor: "pointer",
            }}
            onClick={() => {
              handleOpenTask();
              onClose();
            }}
          >
            Task
          </Typography>
          <Typography
            fontSize="14px"
            color="secondary"
            sx={{
              cursor: "pointer",
            }}
            onClick={() => {
              handleOpenList();
              onClose();
            }}
          >
            List
          </Typography>
          <Typography
            fontSize="14px"
            color="secondary"
            sx={{
              cursor: "pointer",
            }}
            onClick={() => {
              handleOpenGroup();
              onClose();
            }}
          >
            Group
          </Typography>
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default Index;
