import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as qs from "qs";
import axiosApi from "../../utils/httpClient";
const name = "myjobs";
const initialState = {
  myjobs: [],
  loading: false,
  error: null,
};

export const myjobsAPI = createAsyncThunk(
  "myjobs",
  async ({ skip, limit, id, status }) => {
    var formData = {};

    formData["skip"] = skip;
    formData["status"] = status;

    formData["limit"] = limit;

    const data = await axiosApi.get(
      `${process.env.REACT_APP_BASE_URL}job/my-jobs/`,
      {
        params: formData,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      }
    );
    return data.data;
  }
);

const myjobsSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(myjobsAPI.pending, (state) => {
      state.myjobs = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(myjobsAPI.fulfilled, (state, action) => {
      state.myjobs = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(myjobsAPI.rejected, (state, action) => {
      state.myjobs = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default myjobsSlice.reducer;
