import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as qs from "qs";
import axiosApi from "../../utils/httpClient";
const name = "sentToEvent";
const initialState = {
  sentToEvent: [],
  loading: false,
  error: null,
};

export const sentToEventAPI = createAsyncThunk(
  "sentToEvent",
  async ({
    search,
    skip,
    limit,
    id,
    status,
    country,
    eventKind,
    eventCategory,
  }) => {
    var formData = {};
    if (country) {
      formData["country"] = country;
    }
    if (status) {
      if (status == "Submited") {
        formData["status"] = "received";
      } else if (status == "Approved") {
        formData["status"] = "approved";
      } else if (status == "Rejected") {
        formData["status"] = "rejected";
      }
    }
    if (eventCategory) {
      formData["category"] = eventCategory;
    }
    if (eventKind) {
      formData["kind"] = eventKind;
    }
    if (search) {
      formData["search"] = search;
    }
    const data = await axiosApi.get(
      `${process.env.REACT_APP_BASE_URL}application/event/applied/?skip=${skip}&limit=${limit}`,
      {
        params: formData,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      }
    );
    return data.data;
  }
);

const sentToEventSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(sentToEventAPI.pending, (state) => {
      state.sentToEvent = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(sentToEventAPI.fulfilled, (state, action) => {
      state.sentToEvent = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(sentToEventAPI.rejected, (state, action) => {
      state.sentToEvent = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default sentToEventSlice.reducer;
