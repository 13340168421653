import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const name = "selectedProjectType";
const initialState = {
  selectedProjectType: [],
  loading: false,
  error: null,
};

export const selectProjectType = createAsyncThunk(
  "projectType/selected",
  async (projectType) => {
    return projectType;
  }
);

const selectedProjectTypeSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(selectProjectType.pending, (state) => {
      state.selectedProjectType = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(selectProjectType.fulfilled, (state, action) => {
      state.selectedProjectType = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(selectProjectType.rejected, (state, action) => {
      state.selectedProjectType = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default selectedProjectTypeSlice.reducer;
