import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosApi from "../../utils/httpClient";
import * as qs from "qs";
const name = "conversations";
const initialState = {
  data: [],
  filtered: [],
  loading: false,
  error: null,
  success: false,
};

export const fetchConversations = createAsyncThunk(
  "conversations/fetchConversations",
  async ({ id, model, type }) => {
    let data;
    if (type == "inv-proj") {
      var formData = {};
      formData["id"] = id;
      data = await axiosApi.get(
        `${process.env.REACT_APP_BASE_URL}chat/companies-get-all/${id}`
      );
    } else if (type == "all") {
      var formData = {};
      formData["model"] = "job";
      data = await axiosApi.get(
        `${process.env.REACT_APP_BASE_URL}chat/conversations`,
        {
          params: formData,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
          },
        }
      );
    } else if (type == "single-job") {
      data = await axiosApi.get(
        `${process.env.REACT_APP_BASE_URL}chat/job/${id}`
      );
    } else {
      var formData = {};
      formData["id"] = id;
      if (model == "All") {
        formData["model"] = "All";
      } else if (model == "Investors") {
        formData["model"] = "Investor";
      } else if (model == "Mentors") {
        formData["model"] = "Mentor";
      } else if (model == "Experts") {
        formData["model"] = "Expert";
      }

      data = await axiosApi.get(
        `${process.env.REACT_APP_BASE_URL}chat/conversations`,
        {
          params: formData,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
          },
        }
      );
    }
    return data.data.data;
  }
);

export const searchConversations = createAsyncThunk(
  "conversations/searchConversations",
  async ({ query }, { getState }) => {
    const state = getState();
    const conversations = state.conversations.data;

    if (query.length > 0) {
      const queryTerm = query.toLowerCase();
      const results = conversations.filter((item) => {
        if (
          item.users?.model == "Individual" ||
          item.users?.model == "Mentor" ||
          item.users?.model == "Expert"
        ) {
          return (
            item.users?.firstName.toLowerCase().includes(queryTerm) ||
            item.users?.lastName.toLowerCase().includes(queryTerm)
          );
        } else if (
          item.users?.model == "Company" ||
          item.users?.model == "Institution"
        ) {
          return item.users?.name.toLowerCase().includes(queryTerm);
        }
      });

      return results;
    } else {
      return conversations;
    }
  }
);

export const updateConversation = createAsyncThunk(
  "conversations/updateConversation",
  async ({ data }, { getState }) => {
    return data;
  }
);

export const newConversation = createAsyncThunk(
  "conversations/newConversation",
  async ({ result }, { getState }) => {
    const state = getState();

    return { data: result };
  }
);

export const increaseReadCount = createAsyncThunk(
  "conversations/increaseReadCount",
  async ({ data }, { getState }) => {
    const state = getState();
    const selectedConversation = state.conversation.conversation;

    return { data: data, conversation: selectedConversation };
  }
);

export const decreaseReadCount = createAsyncThunk(
  "conversations/decreaseReadCount",
  async ({ data }, { getState }) => {
    const state = getState();
    const selectedConversation = state.conversation.conversation;

    return { data: data, conversation: selectedConversation };
  }
);

const conversationsSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchConversations.pending, (state) => {
      state.data = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchConversations.fulfilled, (state, action) => {
      state.data = action.payload;
      state.filtered = action.payload;
      state.loading = false;
      state.error = null;
      state.success = true;
    });
    builder.addCase(fetchConversations.rejected, (state, action) => {
      state.data = [];
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(newConversation.fulfilled, (state, action) => {
      if (
        state.data?.filter((item) => item._id === action.payload.data?._id)
          ?.length < 1
      ) {
        state.data = [action.payload.data, ...state.data];
        state.filtered = [action.payload.data, ...state.filtered];
      }
      state.loading = false;
      state.error = null;
    });

    builder.addCase(searchConversations.fulfilled, (state, action) => {
      state.filtered = action.payload;
      state.loading = false;
      state.error = null;
      state.success = true;
    });
    builder.addCase(updateConversation.fulfilled, (state, action) => {
      const objIndex = state.data.findIndex(
        (obj) => obj._id == action.payload.chatId
      );
      state.filtered[objIndex].latestMessage = action.payload;
      if (state.filtered[0] !== action.payload.chatId) {
        const latestConv = state.filtered.find(
          (i) => i._id === action.payload.chatId
        );
        const remainingConvs = state.filtered.filter(
          (item) => item._id !== action.payload.chatId
        );
        state.filtered = [latestConv, ...remainingConvs];
      }
      state.loading = false;
      state.error = null;
    });
    builder.addCase(increaseReadCount.fulfilled, (state, action) => {
      const objIndex = state.data.findIndex(
        (obj) => obj._id == action.payload.data.chatId
      );

      if (action.payload.data.chatId !== action.payload.conversation) {
        state.filtered[objIndex].unread = state.filtered[objIndex].unread + 1;
      }
      state.loading = false;
      state.error = null;
    });
    builder.addCase(decreaseReadCount.fulfilled, (state, action) => {
      const objIndex = state.data.findIndex(
        (obj) => obj._id == action.payload.data.chatId
      );

      if (action.payload.data.chatId === action.payload.conversation) {
        state.filtered[objIndex].unread = state.filtered[objIndex].unread - 1;
      }
      state.loading = false;
      state.error = null;
    });
  },
});

export default conversationsSlice.reducer;
