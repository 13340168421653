import { Stack } from "@mui/material";
import React, { useEffect } from "react";
import Filter from "./Filter";
import Groups from "./Groups";
import Lists from "./List";
import { groupsAPI } from "../../../../redux/tasks/groups/groupsSlice";
import { listsAPI } from "../../../../redux/tasks/lists/listsSlice";
import { useDispatch } from "react-redux";
const Index = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(groupsAPI());
    dispatch(listsAPI());
  }, []);

  return (
    <React.Fragment>
      <Stack
        className="thin-scrollbar"
        direction="column"
        spacing={4}
        style={{
          height: "calc(100% - 60px)",
          overflowY: "auto",
        }}
        sx={{
          pr: 1,
        }}
      >
        <Filter />
        <Groups />
        <Lists />
      </Stack>
    </React.Fragment>
  );
};

export default Index;
