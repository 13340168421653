import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const name = "selectedNote";
const initialState = {
  id: "",
  model: "",
  loading: false,
  error: null,
};

export const selectNote = createAsyncThunk(
  "select/cardnote",
  async ({ id, model }) => {
    return { id, model };
  }
);

const selectNoteSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(selectNote.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(selectNote.fulfilled, (state, action) => {
      state.id = action.payload.id;
      state.model = action.payload.model;
    });
    builder.addCase(selectNote.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default selectNoteSlice.reducer;
