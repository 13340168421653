import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as qs from "qs";
import axiosApi from "../../utils/httpClient";
const name = "appliedJobs";
const initialState = {
  appliedJobs: [],
  loading: false,
  error: null,
};

export const appliedJobsAPI = createAsyncThunk(
  "appliedJobs",
  async ({ search, skip, limit }) => {
    var formData = {};
    if (search) {
      formData["search"] = search;
    }

    const data = await axiosApi.get(
      `${process.env.REACT_APP_BASE_URL}application/job/applied?skip=${skip}&limit=${limit}`,
      {
        params: formData,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      }
    );
    return data.data;
  }
);

const appliedJobsSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(appliedJobsAPI.pending, (state) => {
      state.appliedJobs = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(appliedJobsAPI.fulfilled, (state, action) => {
      state.appliedJobs = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(appliedJobsAPI.rejected, (state, action) => {
      state.appliedJobs = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default appliedJobsSlice.reducer;
