import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const name = "selectedIndustries";
const initialState = {
  selectedIndustries: [],
  loading: false,
  error: null,
};

export const selectIndustries = createAsyncThunk(
  "industries/selected",
  async (industries) => {
    return industries;
  }
);

const selectedIndustriesSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(selectIndustries.pending, (state) => {
      state.selectedIndustries = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(selectIndustries.fulfilled, (state, action) => {
      state.selectedIndustries = action.payload;
    });
    builder.addCase(selectIndustries.rejected, (state, action) => {
      state.selectedIndustries = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default selectedIndustriesSlice.reducer;
