import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const name = "selectedEventsKinds";
const initialState = {
  selectedEventsKinds: [],
  loading: false,
  error: null,
};

export const selectEventsKinds = createAsyncThunk(
  "eventskinds/selected",
  async (kinds) => {
    return kinds;
  }
);

const selectedEventsKindsSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(selectEventsKinds.pending, (state) => {
      state.selectedEventsKinds = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(selectEventsKinds.fulfilled, (state, action) => {
      state.selectedEventsKinds = action.payload;
    });
    builder.addCase(selectEventsKinds.rejected, (state, action) => {
      state.selectedEventsKinds = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default selectedEventsKindsSlice.reducer;
