const Index = ({ color }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="30" rx="4" fill={color} />
      <path
        d="M19.0504 11.2875C21.2871 13.5242 21.2871 17.1541 19.0504 19.3908C16.8137 21.6275 13.1837 21.6275 10.947 19.3908C8.71036 17.1541 8.71036 13.5242 10.947 11.2875C13.1837 9.05082 16.8137 9.05082 19.0504 11.2875Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.5627 23.8367C9.72941 23.1034 8.12524 21.7742 7.0619 19.9317C6.0169 18.1258 5.66857 16.1183 5.91607 14.2025"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.36328 8.10666C10.9216 6.88749 12.8741 6.16333 15.0008 6.16333C17.0816 6.16333 18.9974 6.86915 20.5374 8.04249"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.4375 23.8367C20.2708 23.1034 21.875 21.7742 22.9383 19.9317C23.9833 18.1258 24.3317 16.1183 24.0842 14.2025"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Index;
