import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as qs from "qs";
import axiosApi from "../../../utils/httpClient";
const name = "groups";
const initialState = {
  groups: [],
  loading: false,
  error: null,
};

export const groupsAPI = createAsyncThunk("groups", async () => {
  const data = await axiosApi.get(`${process.env.REACT_APP_BASE_URL}group`);
  return data.data;
});
export const updateGroupData = createAsyncThunk(
  "group/updateGroupData",
  async ({ id, name }) => {
    return { id, name };
  }
);
export const addGroup = createAsyncThunk("group/addGroup", async ({ data }) => {
  return { data };
});

const groupsSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(groupsAPI.pending, (state) => {
      state.groups = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(groupsAPI.fulfilled, (state, action) => {
      state.groups = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(groupsAPI.rejected, (state, action) => {
      state.groups = [];
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(updateGroupData.fulfilled, (state, action) => {
      const objIndex = state.groups.findIndex(
        (obj) => obj._id == action.payload.id
      );
      state.groups[objIndex].name = action.payload.name;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(addGroup.fulfilled, (state, action) => {
      state.groups = [action.payload.data, ...state.groups];
      state.loading = false;
      state.error = null;
    });
  },
});

export default groupsSlice.reducer;
