import Startup from "./startup";
import Investor from "./investor";
import Mentor from "./mentor";
import Expert from "./expert";
import { useSelector } from "react-redux";
import React from "react";

const Index = () => {
  const currentProfile = useSelector((store) => store.switch.profile);

  return (
    <>
      <React.Fragment>
        {localStorage.getItem("profile") == "Startup" && <Startup />}
        {localStorage.getItem("profile") == "Investor" && <Investor />}
        {localStorage.getItem("profile") == "Mentor" && <Mentor />}
        {localStorage.getItem("profile") == "Expert" && <Expert />}
      </React.Fragment>
    </>
  );
};

export default Index;
