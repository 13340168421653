import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosApi from "../../utils/httpClient";

const name = "eventcategories";
const initialState = {
  eventcategories: [],
  loading: false,
  error: null,
};

export const fetchEventcategories = createAsyncThunk(
  "eventcategories/fetchEventcategories",
  async () => {
    const data = await axiosApi.get(
      `${process.env.REACT_APP_BASE_URL}event-category`
    );
    return data.data;
  }
);

const eventcategoriesSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchEventcategories.pending, (state) => {
      state.eventcategories = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchEventcategories.fulfilled, (state, action) => {
      state.eventcategories = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(fetchEventcategories.rejected, (state, action) => {
      state.eventcategories = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default eventcategoriesSlice.reducer;
