import React from 'react'
import Box from '@mui/material/Box'
import Backdrop from '@mui/material/Backdrop'
import { CircularProgress, Stack, Typography } from '@mui/material'
const Loader = ({ loading, message }) => {
	return (
		<div>
			{loading && (
				<Backdrop
					open={loading}
					sx={{
						zIndex: '1000000000000000',
						backgroundColor: 'rgba(0, 0, 0, 0.9)'
					}}
				>
					<Stack alignItems="center" direction="column" spacing={2}>
						<Box
							sx={{
								textAlign: 'center'
							}}
						>
							<CircularProgress />
						</Box>
						<Box>
							<Typography variant="body1" color="secondary">
								{message}
							</Typography>
						</Box>
					</Stack>
				</Backdrop>
			)}
		</div>
	)
}

export default Loader
