import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const name = "search";
const initialState = {
  search: "",
  loading: false,
  error: null,
};

export const setSearch = createAsyncThunk("search", async (search) => {
  return search;
});

const searchSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(setSearch.pending, (state) => {
      state.search = "";
      state.loading = true;
      state.error = null;
    });
    builder.addCase(setSearch.fulfilled, (state, action) => {
      state.search = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(setSearch.rejected, (state, action) => {
      state.search = "";
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default searchSlice.reducer;
