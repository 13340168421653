import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as qs from "qs";
import axiosApi from "../../../utils/httpClient";
const name = "exploreJobs";
const initialState = {
  exploreJobs: [],
  loading: false,
  error: null,
};

export const exploreJobsAPI = createAsyncThunk(
  "exploreJobs",
  async ({ skip, limit, country, skills, category, type, search }) => {
    var formData = {};
    // formData["id"] = id;
    formData["skip"] = skip;
    // formData["model"] = model;
    // formData["status"] = status;
    formData["limit"] = limit;
    if (skills) {
      formData["skills"] = skills;
    }
    if (country) {
      formData["country"] = country;
    }
    if (category) {
      formData["category"] = category;
    }
    if (type) {
      formData["type"] = type;
    }
    if (search) {
      formData["search"] = search;
    }

    const data = await axiosApi.get(`${process.env.REACT_APP_BASE_URL}job/`, {
      params: formData,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    });
    return data.data;
  }
);

const exploreJobsSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(exploreJobsAPI.pending, (state) => {
      state.exploreJobs = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(exploreJobsAPI.fulfilled, (state, action) => {
      state.exploreJobs = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(exploreJobsAPI.rejected, (state, action) => {
      state.exploreJobs = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default exploreJobsSlice.reducer;
