import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
const Index = ({ setSelectedGroup, selectedGroup }) => {
  const groups = useSelector((store) => store.groups.groups);
  const [searchParams, setSearchParams] = useSearchParams();
  const noneGroup = {
    _id: -1,
    name: "None",
  };

  return (
    <React.Fragment>
      <Stack direction="column">
        <Typography fontSize="14px">Add to Group</Typography>
        <FormControl>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedGroup}
            onChange={(e) => {
              setSelectedGroup(e.target.value);
            }}
          >
            {" "}
            <MenuItem value={noneGroup._id}>{noneGroup.name}</MenuItem>
            {groups &&
              groups?.length > 0 &&
              groups.map((item) => (
                <MenuItem value={item._id}>{item.name}</MenuItem>
              ))}
          </Select>
        </FormControl>
      </Stack>
    </React.Fragment>
  );
};

export default Index;
