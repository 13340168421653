/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { useDispatch } from 'react-redux'
import { height, width } from '../../redux/dimensions/dimensionsSlice'

function Dimension() {
	const dispatch = useDispatch()
	const updateDimensions = () => {
		dispatch(width(window.innerWidth))
		dispatch(height(window.innerHeight))
	}
	React.useEffect(() => {
		window.addEventListener('resize', updateDimensions)
		return () => window.removeEventListener('resize', updateDimensions)
	}, [])

	return null
}

export default Dimension
