import {
  Checkbox,
  Typography,
  TextField,
  Stack,
  Modal,
  Box,
} from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import React, { useEffect, useState } from "react";
import axiosApi from "../../../../utils/httpClient";
import { updateTask } from "../../../../redux/tasks/tasks/tasksSlice";
import Tag from "./Tag";
import {
  updateTaskData,
  deleteTask,
} from "../../../../redux/tasks/tasks/tasksSlice";
import { useDispatch, useSelector } from "react-redux";
import leftTime from "../../../../utils/leftTime";
import MoreVertIcon from "@mui/icons-material/MoreVert";
const Index = ({ data, selectedTask, setSelectedTask }) => {
  const dispatch = useDispatch();
  const [taskName, setTaskName] = useState("");
  const [expand, setExpand] = useState(false);
  const [description, setDescription] = useState("");
  const mode = useSelector((store) => store.mode.mode);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    bottom: "60px",
    right: "60px",
    outline: "none",
    width: "500px",
    margin: "auto",
    border: "none",
  };
  const statusChange = async () => {
    const status = data?.status == "completed" ? "created" : "completed";

    const resp = axiosApi.patch(`task/${data._id}`, {
      status: status,
    });
    if (status === "completed") {
      await dispatch(deleteTask({ id: data?._id }));
    }
    dispatch(
      updateTask({
        id: data._id,
        status: status,
      })
    );
  };

  return (
    <React.Fragment>
      <Stack
        spacing={2}
        sx={{
          p: 1,
          border: `1px solid #77788c`,
        }}
        onClick={(e) => {
          setSelectedTask(data._id);
        }}
      >
        <Stack
          direction="row"
          justifyContent={"space-between"}
          sx={{
            height: "100%",
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Checkbox
              checked={data?.status == "completed" ? true : false}
              sx={{
                p: 0,
              }}
              onClick={(e) => {
                e.stopPropagation();
                statusChange();
              }}
            />
            <Typography fontSize="12px" className={"dark-2"}>
              Mark as complete
            </Typography>
          </Stack>
          {data.dueDate && (
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              className={mode ? "dark-3" : "light-2"}
            >
              <CalendarMonthIcon fontSize="small" />
              <Typography fontSize="12px">
                {leftTime(new Date(data.dueDate))}
              </Typography>
            </Stack>
          )}
        </Stack>
        <Stack spacing={1}>
          <Typography fontWeight={"500"} fontSize="16px">
            {data.name}
          </Typography>
          {data.group ? (
            <Typography fontSize="12px" className={mode ? "dark-2" : "light-2"}>
              {data?.group}
              {data?.list && " ⮞ " + data?.list}
            </Typography>
          ) : (
            <>
              {data?.list && (
                <Typography
                  fontSize="12px"
                  fontWeight="500"
                  className={mode ? "dark-2" : "light-2"}
                >
                  {data?.list}
                </Typography>
              )}
            </>
          )}
        </Stack>
        {expand && data?.description && (
          <TextField
            value={description}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => {
              e.stopPropagation();
              setDescription(e.target.value);
            }}
            type="text"
            id="description"
            name="description"
            fullWidth
            multiline
            rows={2}
            placeholder="Enter task name"
            InputProps={{
              style: {
                padding: "0rem",
                fontSize: "12px",
              },
              maxLength: 300,
              classes: {
                input: `dark-2 input-nopadding ${
                  mode ? "inputFieldDark" : "inputFieldLight"
                }`,
              },
              className: `${mode ? "dark-bg-2 " : "light-bg-2 "}`,
            }}
          />
        )}
        {data?.tags && data?.tags?.length > 0 && (
          <Stack direction="row" spacing={1}>
            {data?.tags &&
              data?.tags?.length > 0 &&
              data?.tags?.map((item) => <Tag data={item} />)}
          </Stack>
        )}
      </Stack>
    </React.Fragment>
  );
};

export default Index;
