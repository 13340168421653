import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as qs from "qs";
import axiosApi from "../../../utils/httpClient";
const name = "tasks";
const initialState = {
  tasks: [],
  loading: false,
  error: null,
};

export const tasksAPI = createAsyncThunk(
  "tasks",
  async ({ filter, search, list }) => {
    var formData = {};
    if (filter) {
      if (filter == "past") {
        formData["status"] = "passed";
      } else if (filter == "inbox") {
        formData["status"] = "inbox";
      } else if (filter == "upcoming") {
        formData["status"] = "upcoming";
      } else if (filter == "anytime") {
        formData["status"] = "anytime";
      } else if (filter == "completed") {
        formData["status"] = "completed";
      } else if (filter == "today") {
        formData["status"] = "today";
      }
    }
    if (list) {
      formData["list"] = list;
    }
    if (search) {
      formData["search"] = search;
    }

    const data = await axiosApi.get(`${process.env.REACT_APP_BASE_URL}task/`, {
      params: formData,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    });
    return data.data;
  }
);

export const tasksByListAPI = createAsyncThunk(
  "tasksbylist",
  async ({ id }) => {
    const data = await axiosApi.get(
      `${process.env.REACT_APP_BASE_URL}list/${id}`,
      {}
    );

    return data.data;
  }
);

export const updateTask = createAsyncThunk(
  "tasks/updateTask",
  async ({ id, status }) => {
    return { id, status };
  }
);
export const updateTaskData = createAsyncThunk(
  "tasks/updateTaskData",
  async ({ id, data }) => {
    return { id, data };
  }
);
export const addTask = createAsyncThunk("tasks/addTask", async ({ data }) => {
  return { data };
});
export const deleteTask = createAsyncThunk(
  "tasks/deleteTask",
  async ({ id }) => {
    return { id };
  }
);

const tasksSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(tasksAPI.pending, (state) => {
      state.tasks = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(tasksAPI.fulfilled, (state, action) => {
      state.tasks = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(tasksAPI.rejected, (state, action) => {
      state.tasks = [];
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(tasksByListAPI.pending, (state) => {
      state.tasks = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(tasksByListAPI.fulfilled, (state, action) => {
      state.tasks = action.payload.data.tasks;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(tasksByListAPI.rejected, (state, action) => {
      state.tasks = [];
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(updateTask.fulfilled, (state, action) => {
      const objIndex = state.tasks.findIndex(
        (obj) => obj._id == action.payload.id
      );
      state.tasks[objIndex].status = action.payload.status;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(updateTaskData.fulfilled, (state, action) => {
      const objIndex = state.tasks.findIndex(
        (obj) => obj._id == action.payload.id
      );
      state.tasks[objIndex] = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(deleteTask.fulfilled, (state, action) => {
      const filteredTasks = state.tasks.filter(
        (item) => item._id !== action.payload.id
      );
      state.tasks = filteredTasks;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(addTask.fulfilled, (state, action) => {
      state.tasks = [action.payload.data, ...state.tasks];
      state.loading = false;
      state.error = null;
    });
  },
});

export default tasksSlice.reducer;
