import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as qs from "qs";
import axiosApi from "../../utils/httpClient";
const name = "sentToIndividual";
const initialState = {
  sentToIndividual: [],
  loading: false,
  error: null,
};

export const sentToIndividualAPI = createAsyncThunk(
  "sentToIndividual",
  async ({ search, skip, limit, type, status, country }) => {
    var formData = {};
    if (status) {
      if (status == "Submited") {
        formData["status"] = "received";
      } else if (status == "Approved") {
        formData["status"] = "approved";
      } else if (status == "Rejected") {
        formData["status"] = "rejected";
      }
    }
    if (country) {
      formData["country"] = country;
    }
    if (search) {
      formData["search"] = search;
    }
    if (type == "Individual") {
      const data = await axiosApi.get(
        `${process.env.REACT_APP_BASE_URL}application/individual/applied/?skip=${skip}&limit=${limit}`,
        {
          params: formData,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
          },
        }
      );
      return data.data;
    } else if (type == "Institutional") {
      const data = await axiosApi.get(
        `${process.env.REACT_APP_BASE_URL}application/institution/applied/?skip=${skip}&limit=${limit}`,
        {
          params: formData,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
          },
        }
      );
      return data.data;
    } else if (type == "All" || !type) {
      const data = await axiosApi.get(
        `${process.env.REACT_APP_BASE_URL}application/company/applied/investors?skip=${skip}&limit=${limit}`,
        {
          params: formData,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
          },
        }
      );
      return data.data;
    }
  }
);

const sentToIndividualSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(sentToIndividualAPI.pending, (state) => {
      state.sentToIndividual = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(sentToIndividualAPI.fulfilled, (state, action) => {
      state.sentToIndividual = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(sentToIndividualAPI.rejected, (state, action) => {
      state.sentToIndividual = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default sentToIndividualSlice.reducer;
