import {
  InputAdornment,
  InputLabel,
  Stack,
  Typography,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import axiosApi from "../../../utils/httpClient";
import Tag from "../RightBlock/Tag";
import SelectedTag from "../RightBlock/SelectedTag";
const Index = ({ setSelectedTags, selectedTags }) => {
  const [tags, setTags] = useState([]);
  const mode = useSelector((store) => store.mode.mode);
  const [search, setSearch] = useState("");
  const [query, setQuery] = useState("");
  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };
  const searchUpdate = debounce(function (e) {
    setQuery(e.target.value);
  }, 1000);
  const getTags = async () => {
    const resp = await axiosApi.get(`tags?search=${query}`);
    setTags(resp.data.data);
  };
  useEffect(() => {
    if (query.length > 0) {
      getTags();
    }
    if (query == "") {
      setTags([]);
    }
  }, [query]);
  const onSelectTag = (tag) => {
    setSelectedTags([...selectedTags, tag]);
  };
  const onRemoveTag = (tag) => {
    const filteredTags = selectedTags.filter((item) => item._id != tag._id);
    setSelectedTags(filteredTags);
  };
  const newTag = async () => {
    const resp = await axiosApi.post(`tags`, {
      tag: query.toLowerCase(),
    });
    setSelectedTags([...selectedTags, resp?.data?.data]);
    setQuery("");
  };
  return (
    <React.Fragment>
      <Stack>
        <InputLabel shrink htmlFor="dueDate">
          <Typography color="secondary" variant="body1">
            Add Tags
          </Typography>
        </InputLabel>
        {selectedTags && selectedTags?.length > 0 && (
          <Stack direction="row" spacing={1} sx={{ pb: 2 }}>
            {selectedTags &&
              selectedTags?.length > 0 &&
              selectedTags.map((item) => (
                <SelectedTag
                  removeTag={() => {
                    onRemoveTag(item);
                  }}
                  data={item}
                />
              ))}
          </Stack>
        )}

        <TextField
          value={search}
          type="text"
          id="searchTerm"
          name="searchTerm"
          fullWidth
          placeholder="add tag"
          onChange={(e) => {
            setSearch(e.target.value);
            searchUpdate(e);
          }}
          InputProps={{
            classes: {
              input: `${mode ? "inputFieldDark" : "inputFieldLight"}`,
            },
            className: `${mode ? "dark-bg-2 " : "light-bg-2 "}`,
            endAdornment: (
              <InputAdornment position="end">
                <Stack direction="row" alignItems="center" spacing={1}>
                  {search?.length > 0 && (
                    <CloseIcon
                      sx={{
                        color: "gray",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        setSearch("");
                        setQuery("");
                      }}
                    />
                  )}
                </Stack>
              </InputAdornment>
            ),
          }}
        />
        {((tags && tags.length > 0) || query.length > 0) && (
          <Stack direction="row" spacing={1} sx={{ pt: 1 }}>
            {tags &&
              tags.length > 0 &&
              tags.map((item) => (
                <>
                  {selectedTags.filter((t) => t._id == item._id)?.length <
                    1 && (
                    <Tag
                      data={item}
                      onSelect={() => {
                        onSelectTag(item);
                      }}
                    />
                  )}
                </>
              ))}

            {tags.length < 1 && query.length > 0 && (
              <Tag data={{ tag: query }} onSelect={newTag} />
            )}
          </Stack>
        )}
      </Stack>
    </React.Fragment>
  );
};
export default Index;
