import stringToColor from './stringToColor'

export default function stringAvatar(name) {
	let upperCase = name.toUpperCase()
	if (upperCase) {
		return {
			sx: {
				bgcolor: stringToColor(upperCase)
			},
			children: `${upperCase.split(' ')[0][0]}`
		}
	}
}
