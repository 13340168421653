import { InputAdornment, Stack, Box, TextField } from "@mui/material";
import SelectedTag from "../../../SelectedTag";
import React, { useEffect } from "react";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import axiosApi from "../../../../../../utils/httpClient";
import { useSelector } from "react-redux";
import Tag from "../../../Tag";

const Index = ({ selectedTags, setSelectedTags }) => {
  const [tags, setTags] = useState([]);
  const mode = useSelector((store) => store.mode.mode);
  const [search, setSearch] = useState("");
  const [query, setQuery] = useState("");

  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };
  const searchUpdate = debounce(function (e) {
    setQuery(e.target.value);
  }, 1000);

  const onSelectTag = (tag) => {
    setSearch("");
    setQuery("");
    setSelectedTags([...selectedTags, tag]);
  };
  const onRemoveTag = (tag) => {
    const filteredTags = selectedTags.filter((item) => item._id != tag._id);
    setSelectedTags(filteredTags);
  };
  const getTags = async () => {
    const resp = await axiosApi.get(`tags?search=${query}`);
    setTags(resp.data.data);
  };

  const newTag = async () => {
    const resp = await axiosApi.post(`tags`, {
      tag: query.toLowerCase(),
    });
    setSelectedTags([...selectedTags, resp?.data?.data]);
    setQuery("");
  };
  useEffect(() => {
    if (query.length > 0) {
      getTags();
    }
    if (query == "") {
      setTags([]);
    }
  }, [query]);

  return (
    <React.Fragment>
      <Stack
        spacing={1}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          {selectedTags &&
            selectedTags?.length > 0 &&
            selectedTags?.map((item) => (
              <SelectedTag
                removeTag={() => {
                  onRemoveTag(item);
                }}
                data={item}
              />
            ))}
          <TextField
            value={search}
            type="text"
            id="searchTerm"
            name="searchTerm"
            fullWidth
            placeholder="add tag"
            onChange={(e) => {
              setSearch(e.target.value);
              searchUpdate(e);
            }}
            InputProps={{
              style: {
                padding: "0rem",
                fontSize: "12px",
              },
              classes: {
                input: `input-nopadding ${
                  mode ? "inputFieldDark" : "inputFieldLight"
                }`,
              },
              className: `${mode ? "dark-bg-2 " : "light-bg-2 "}`,
              endAdornment: (
                <InputAdornment position="end">
                  <Stack direction="row" alignItems="center" spacing={1}>
                    {search?.length > 0 && (
                      <CloseIcon
                        sx={{
                          color: "gray",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          setSearch("");
                          setQuery("");
                        }}
                      />
                    )}
                  </Stack>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        {tags?.length > 0 && (
          <Stack direction="row" spacing={1}>
            {tags?.length > 0 &&
              tags?.map((item) => (
                <Tag
                  data={item}
                  onSelect={() => {
                    onSelectTag(item);
                  }}
                />
              ))}
          </Stack>
        )}

        {tags.length < 1 && query.length > 0 && (
          <Tag data={{ tag: query }} onSelect={newTag} />
        )}
      </Stack>
    </React.Fragment>
  );
};

export default Index;
