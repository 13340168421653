import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getCookie } from "./httpClient";
import { profileAPI } from "../redux/auth/profile/profileSlice";
import { useSelector, useDispatch } from "react-redux";

function MultiStartupAccount({ children }) {
  const dispatch = useDispatch();
  const profile = useSelector((store) => store.profile);
  let location = useLocation();

  useEffect(() => {
    dispatch(profileAPI());
  }, []);

  if (
    profile?.data?.company &&
    profile?.data?.company?.status &&
    profile?.data?.company?.status == "approved"
  ) {
    return (
      <Navigate
        to={"/search/investors?mode=discover"}
        replace
        state={{ from: location }}
      />
    );
  } else if (
    profile?.data?.company &&
    profile?.data?.company?.status &&
    profile?.data?.company?.status == "submitted"
  ) {
    return (
      <Navigate
        to={`/application-status/company/${profile?.data?.company?._id}`}
        replace
        state={{ from: location }}
      />
    );
  } else if (
    profile?.data?.company &&
    profile?.data?.company?.status &&
    profile?.data?.company?.status == "rejected"
  ) {
    return (
      <Navigate
        to={`/application-status/company/${profile?.data?.company?._id}`}
        replace
        state={{ from: location }}
      />
    );
  } else {
    return children;
  }
}

export default MultiStartupAccount;
