import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosApi from "../../../utils/httpClient";

const name = "google";

const initialState = {
  data: {},
  loading: false,
  message: "",
  error: null,
  success: false,
};

export const googleAPI = createAsyncThunk("auth/google", async (token) => {
  const response = await axiosApi.post(
    `${process.env.REACT_APP_BASE_URL}auth/google`,
    {
      token: token,
    }
  );
  return response.data;
});

export const googleLogout = createAsyncThunk(
  "auth/googleLogout",
  async () => {}
);

const googleSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(googleAPI.pending, (state) => {
      state.loading = true;
      state.message = "Logging In!!! Please wait!";
      state.error = null;
      state.success = false;
    });
    builder.addCase(googleAPI.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.message = action.payload.message;
      state.error = null;
      state.success = true;
    });
    builder.addCase(googleAPI.rejected, (state, action) => {
      state.loading = false;
      state.message = "";
      state.error = action.error.message;
      state.success = false;
    });
    builder.addCase(googleLogout.fulfilled, (state, action) => {
      state.data = {};
      state.loading = false;
      state.message = "";
      state.error = null;
      state.success = false;
    });
  },
});

export default googleSlice.reducer;
