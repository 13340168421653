/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import { NestedMenuItem } from "mui-nested-menu";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  AppBar,
  Typography,
  Button,
  Stack,
  Divider,
  Container,
  Avatar,
  Tooltip,
  IconButton,
  Popover,
  Modal,
} from "@mui/material";
import PropTypes from "prop-types";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { useTheme } from "@emotion/react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import stringAvatar from "../components/Utils/stringAvatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// import { googleLogout } from "../redux/auth/google/googleSlice";
import { logout, googleLogout } from "../redux/auth/signin/signinSlice";
import { GoogleLogout } from "react-google-login";
import { getCookie } from "../utils/httpClient";
import { selectConversation } from "../redux/selectConversation/selectConversationSlice";
import { switchProfile } from "../redux/switchprofile/switchProfileSlice";
import { fetchMessages, newMessage } from "../redux/messages/messagesSlice";
import {
  updateConversation,
  increaseReadCount,
  fetchConversations,
} from "../redux/conversations/conversationsSlice";

import { clearProfile } from "../redux/auth/profile/profileSlice";
import ConfirmationPop from "../components/ConfirmationsPopup";
import Notifications from "../components/Notifications";
import { SocketContext } from "../context/socket";
import Snackbar from "../components/Snackbar";
import { selectProject } from "../redux/selectedproject/selectedProjectSlice";
import axiosApi from "../utils/httpClient";
import Slide from "@mui/material/Slide";
import useQueryString from "../utils/hooks/useQueryString";

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default function HeaderNav(props) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [snackOpen, setSnackOpen] = useState(false);
  const inboxProfile = useSelector((store) => store.inboxProfile?.inboxProfile);
  const inboxIndex = useSelector((store) => store.inboxIndex?.inboxIndex);
  const snackClose = () => setSnackOpen(false);
  const socket = useContext(SocketContext);

  const navigate = useNavigate();
  const location = useLocation();
  const queryString = useQueryString({ type: "entry" });
  const conversations = useSelector((store) => store.conversations);
  const selectedJob = useSelector((store) => store.selectedJob);
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const [notificationItem, setNotificationItem] = useState();
  const mode = useSelector((store) => store.mode.mode);
  let dimension = useSelector((store) => store.dimension);
  const messages = useSelector((store) => store.messages?.messages);
  const theme = useTheme();
  const [openModal, setOpenModal] = useState(false);
  const closeModal = () => setOpenModal(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popupIndex, setPopupIndex] = useState(0);
  const conversation = useSelector((store) => store.conversation.conversation);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const handlePopoverClose = () => {
    setPopupIndex(0);
  };

  const open = Boolean(anchorEl);
  const [fullName, setFullName] = React.useState(null);

  const [both, setBoth] = useState(false);
  const name = getCookie("fullName");
  const profile = useSelector((store) => store.profile);
  const currentProfile = useSelector((store) => store.switch.profile);

  const [sock, setSock] = useState(socket);

  React.useEffect(() => {
    setFullName(name);
  }, [name]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const switchProfileHandler = async (type) => {
    if (type == "Startup") {
      localStorage.setItem("profile", "Startup");

      const resp = await axiosApi.get(`auth/switch-profile?to=Company`);

      await navigate(`/search/investors?mode=discover`);
    } else if (type == "Investor") {
      localStorage.setItem("profile", "Investor");
      const tt = (await profile?.data?.individual)
        ? "Individual"
        : "Institution";
      const resp = await axiosApi.get(`auth/switch-profile?to=${tt}`);

      await navigate(`/search/companies?mode=discover`);
    } else {
      localStorage.setItem("profile", "Mentor");
      const resp = await axiosApi.get(`auth/switch-profile?to=Mentor`);

      await navigate(`/search/companies?mode=discover`);
    }
  };
  const getConversations = () => {
    if (
      localStorage.getItem("profile") == "Investor" &&
      searchParams.get("inboxIndex") == 2
    ) {
      dispatch(
        fetchConversations({
          type: "inv-proj",
          // model: currentProfile == "Startup" ? "company" : getType(),
          id:
            localStorage.getItem("profile") == "Startup"
              ? profile?.data?.company?._id
              : getType() == "Individual"
              ? profile?.data?.individual?._id
              : profile?.data?.institution?._id,
        })
      );
    } else {
      if (searchParams.get("inboxIndex") == 1) {
        dispatch(
          fetchConversations({
            model: inboxProfile,
          })
        );
      } else {
        dispatch(
          fetchConversations({
            type: selectedJob?.jobId == "all" ? "all" : "single-job",
            id: selectedJob?.jobId == "all" ? "" : selectedJob?.jobId,
          })
        );
      }
    }
  };

  useEffect(() => {
    if (profile?.data) {
      let id;
      if (localStorage.getItem("profile") == "Startup") {
        const a = profile;

        id = profile?.data?.company?._id;
      } else {
        if (profile?.data?.individual) {
          id = profile?.data?.individual?._id;
        } else {
          id = profile?.data?.institution?._id;
        }
      }

      socket.emit("start-notifications", { _id: `${id}` });
    }
  }, [profile]);

  const notificationListener = async (notification) => {
    setNotificationItem(notification);
    setSnackOpen(true);
  };

  const notificationClick = () => {
    // if (notificationItem.type === "message") {
    //
    //   dispatch(selectConversation(notificationItem?.chatId));
    //   navigate(`/inbox`);
    // }
  };
  const [readMs, setReadMs] = useState({});
  useEffect(() => {
    if (readMs.chatId == searchParams.get("conversation")) {
      socket.emit(
        "messageread",
        {
          _id: readMs?._id,
          chatId: readMs?.chatId,
        },
        function (responseData) {}
      );
    }
  }, [readMs]);

  window.addEventListener("online", () => setIsOnline(true));
  window.addEventListener("offline", () => setIsOnline(false));
  const notificationTrigger = (confirmation) => {
    if (!window.location.pathname.includes("/inbox")) {
      const convData = conversations?.data?.filter(
        (item) => item?._id == confirmation?.chatId
      );

      if (convData?.length > 0) {
        if (!convData[0]?.mute) {
          setNotificationItem(confirmation);
          setSnackOpen(true);
        }
      }
    }
  };

  const socketCalls = () => {
    if (localStorage.getItem("token")) {
      socket.disconnect();
      socket.connect();
    }
    socket.on("connect", (res) => {
      console.log("connected to socket");
    });
    socket.on("disconnect", (res) => {
      console.log("disconnected from socket", res);
    });
    socket.on("connect_failed", function () {});
    socket.emit(
      "startchat",
      {
        payload: "let us see if this worketh",
      },
      function (responseData) {}
    );
    socket.on("startchat", function (confirmation) {});
    socket.on("notification", function (confirmation) {});
    socket.on("message", function (confirmation) {
      if (!window.location.pathname.includes("/inbox")) {
        if (!confirmation?.mute) {
          setNotificationItem(confirmation);
          setSnackOpen(true);
        }
      }
      dispatch(updateConversation({ data: confirmation }));
      dispatch(increaseReadCount({ data: confirmation }));

      dispatch(
        newMessage({
          result: confirmation,
          selectedConversation: searchParams.get("conversation"),
        })
      );
      setReadMs(confirmation);
      socket.emit(
        "messagedeliver",
        {
          _id: confirmation?._id,
          chatId: confirmation?.chatId,
        },
        function (responseData) {}
      );
    });
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      getConversations();
      socketCalls();
    }
  }, [localStorage.getItem("token")]);
  const getType = () => {
    if (profile?.data?.institution) {
      return "Institution";
    }
    if (profile?.data?.individual) {
      return "Individual";
    }
  };
  useEffect(() => {
    const undeliverMessages = messages?.messages?.filter(
      (obj) => obj?.deliver == false
    );

    const callDeliverSocket = (data) => {
      socket.emit(
        "messagedeliver",
        {
          _id: data?._id,
          chatId: data?.chatId,
        },
        function (responseData) {}
      );
    };
    undeliverMessages?.map((item) => {
      if (localStorage.getItem("profile") == "Startup") {
        if (item?.sender !== profile?.data?.company?._id) {
          callDeliverSocket(item);
        }
      }
      if (localStorage.getItem("profile") == "Expert") {
        if (item?.sender !== profile?.data?.expert?._id) {
          callDeliverSocket(item);
        }
      }
      if (localStorage.getItem("profile") == "Mentor") {
        if (item?.sender !== profile?.data?.mentor?._id) {
          callDeliverSocket(item);
        }
      }
      if (localStorage.getItem("profile") == "Investor") {
        if (getType() !== "Individual") {
          if (item?.sender !== profile?.data?.individual?._id) {
            callDeliverSocket(item);
          }
        } else {
          if (item?.sender !== profile?.data?.institution?._id) {
            callDeliverSocket(item);
          }
        }
      }
    });
  }, [messages]);

  const responseGoogleLogoutSuccess = (response) => {};

  const responseGoogleLogoutFail = () => {};

  const logoutHandle = async () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("profile");
    setCompanyProfile(false);
    setInvestorProfile(false);
    setMentorProfile(false);
    setExpertProfile(false);

    dispatch(logout());
    dispatch(googleLogout());
    dispatch(clearProfile());
    socket.disconnect();
    closeModal();
  };

  const [companyProfile, setCompanyProfile] = useState(false);
  const [investorProfile, setInvestorProfile] = useState(false);
  const [mentorProfile, setMentorProfile] = useState(false);
  const [expertProfile, setExpertProfile] = useState(false);

  const checkProfiles = async () => {
    if (profile?.success) {
      if (
        profile?.data?.company?.status == "approved"
        // profile?.data?.company?.status == "rejected" ||
        // profile?.data?.company?.status == "submitted"
      ) {
        setCompanyProfile(true);
      }
      if (
        profile?.data?.individual?.status === "approved" ||
        profile?.data?.institution?.status === "approved"
      ) {
        setInvestorProfile(true);
      }
      if (profile?.data?.mentor?.status === "approved") {
        setMentorProfile(true);
      }
      if (profile?.data?.expert?.status == "approved") {
        setExpertProfile(true);
      }
    }

    if (!localStorage.getItem("profile")) {
      if (
        profile?.data?.user?.whichProfile &&
        profile?.data?.user?.whichProfile != "User"
      ) {
        const wp = profile?.data?.user?.whichProfile;
        if (wp == "Individual" || wp == "Institution") {
          localStorage.setItem("profile", "Investor");
        } else if (wp == "Company") {
          localStorage.setItem("profile", "Startup");
        } else {
          localStorage.setItem("profile", wp);
        }
      } else {
        if (profile?.data?.company?.status == "approved") {
          localStorage.setItem("profile", "Startup");
          const resp = await axiosApi.get(`auth/switch-profile?to=Company`);
        } else if (
          profile?.data?.individual?.status === "approved" ||
          profile?.data?.institution?.status === "approved"
        ) {
          localStorage.setItem("profile", "Investor");
          const tt = (await profile?.data?.individual)
            ? "Individual"
            : "Institution";
          const resp = await axiosApi.get(`auth/switch-profile?to=${tt}`);
        } else if (profile?.data?.mentor?.status === "approved") {
          localStorage.setItem("profile", "Mentor");
          const resp = await axiosApi.get(`auth/switch-profile?to=Mentor`);
        } else if (profile?.data?.expert?.status == "approved") {
          localStorage.setItem("profile", "Expert");
          const resp = await axiosApi.get(`auth/switch-profile?to=Expert`);
        }
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      checkProfiles();
    }
  }, [profile]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
    width: 400,
    bgcolor: mode ? "#333345" : "#fff",
    border: "2px solid #333345",

    boxShadow: 24,
    p: 3,
  };

  const user = JSON.parse(localStorage.getItem("user"));
  const homeHandler = () => {
    if (localStorage.getItem("token")) {
      if (localStorage.getItem("profile")) {
        if (localStorage.getItem("profile") == "Startup") {
          navigate(`/search/investors?mode=discover`);
        } else if (localStorage.getItem("profile") == "Investor") {
          navigate(`/search/companies?mode=discover`);
        } else if (localStorage.getItem("profile") == "Mentor") {
          navigate(`/search/companies?mode=discover`);
        } else if (localStorage.getItem("profile") == "Expert") {
          navigate(`/search/companies?mode=discover`);
        }
      } else {
        if (profile?.data?.user?.whichProfile) {
          if (profile?.data?.user?.whichProfile == "Company") {
            navigate(`/search/investors?mode=discover`);
          } else if (profile?.data?.user?.whichProfile == "Investor") {
            navigate(`/search/companies?mode=discover`);
          } else if (profile?.data?.user?.whichProfile == "Mentor") {
            navigate(`/search/companies?mode=discover`);
          } else if (profile?.data?.user?.whichProfile == "Expert") {
            navigate(`/search/companies?mode=discover`);
          } else if (
            profile?.data?.company?.status === "submitted" ||
            profile?.data?.company?.status === "rejected"
          ) {
            navigate(
              `/application-status/company/${profile?.data?.company?._id}`
            );
          } else if (
            profile?.data?.individual?.status === "submitted" ||
            profile?.data?.individual?.status === "rejected"
          ) {
            navigate(
              `/application-status/individual/${profile?.data?.individual?._id}`
            );
          } else if (
            profile?.data?.institution?.status === "submitted" ||
            profile?.data?.institution?.status === "rejected"
          ) {
            navigate(
              `/application-status/institution/${profile?.data?.institution?._id}`
            );
          } else if (
            profile?.data?.mentor?.status === "submitted" ||
            profile?.data?.mentor?.status === "rejected"
          ) {
            navigate(
              `/application-status/mentor/${profile?.data?.mentor?._id}`
            );
          } else if (
            profile?.data?.expert?.status === "submitted" ||
            profile?.data?.expert?.status === "rejected"
          ) {
            navigate(
              `/application-status/expert/${profile?.data?.expert?._id}`
            );
          } else {
            navigate(`/welcome`);
          }
        } else {
          navigate(`/welcome`);
        }
      }
    } else {
      navigate(`/`);
    }
  };
  const bookmarkLink = () => {
    if (localStorage.getItem("profile") === "Startup") {
      return `/bookmarks?type=Investors`;
    } else if (localStorage.getItem("profile") === "Investor") {
      return `/bookmarks?type=Companies`;
    } else if (localStorage.getItem("profile") === "Mentor") {
      return `/bookmarks?type=Companies`;
    } else if (localStorage.getItem("profile") === "Expert") {
      return `/bookmarks?type=Companies`;
    }
  };
  return (
    <React.Fragment>
      <Snackbar
        open={snackOpen}
        message={"New Message!"}
        onClose={snackClose}
        notificationClick={notificationClick}
      />
      <Modal
        open={openModal}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ConfirmationPop
            onSubmit={logoutHandle}
            onCancel={closeModal}
            message={"Do you want to logout?"}
            title={"Logout"}
          />
        </Box>
      </Modal>
      <HideOnScroll {...props}>
        <AppBar
          sx={{
            alignItems: "center",
            minWidth: "100%",
            bgcolor: theme.palette.background.default,
          }}
          elevation={0}
        >
          <Container maxWidth="lg">
            <Box sx={{ width: "100%" }}>
              <Stack
                direction={"row"}
                justifyContent="space-between"
                alignItems="center"
                alignContent={"center"}
                py={3}
                sx={{}}
              >
                <Stack alignItems="flex-start">
                  <div className="d-flex items-center">
                    <Typography
                      variant="h4"
                      color="primary"
                      className="logo"
                      fontWeight={"600"}
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={homeHandler}
                    >
                      BeBlossom
                    </Typography>
                  </div>
                </Stack>
                <Stack
                  direction={"row"}
                  justifyContent="flex-end"
                  alignItems="center"
                  textAlign="center"
                >
                  <Stack gap={2} direction="row" alignItems={"center"}>
                    {localStorage.getItem("token") && (
                      <>
                        {localStorage.getItem("profile") == "Startup" && (
                          <React.Fragment>
                            <Link to="/search/investors?mode=discover">
                              <Button variant="text" color="secondary">
                                <span
                                  style={{
                                    borderBottom: `${
                                      location.pathname.includes(
                                        `/search/investors`
                                      ) ||
                                      searchParams.get("tab") === "investors"
                                        ? "2px solid #ee4b6a"
                                        : "none"
                                    }`,
                                  }}
                                >
                                  Investors
                                </span>
                              </Button>
                            </Link>
                            <Link to="/search/mentor?mode=discover">
                              <Button variant="text" color="secondary">
                                <span
                                  style={{
                                    borderBottom: `${
                                      location.pathname.includes(
                                        `/search/mentor`
                                      ) || searchParams.get("tab") === "mentors"
                                        ? "2px solid #ee4b6a"
                                        : "none"
                                    }`,
                                  }}
                                >
                                  Mentors
                                </span>
                              </Button>
                            </Link>
                            <Link to="/search/expert">
                              <Button variant="text" color="secondary">
                                <span
                                  style={{
                                    borderBottom: `${
                                      location.pathname.includes(
                                        `/search/expert`
                                      ) || searchParams.get("tab") === "experts"
                                        ? "2px solid #ee4b6a"
                                        : "none"
                                    }`,
                                  }}
                                >
                                  Experts
                                </span>
                              </Button>
                            </Link>
                          </React.Fragment>
                        )}
                        {(localStorage.getItem("profile") == "Investor" ||
                          localStorage.getItem("profile") == "Expert" ||
                          localStorage.getItem("profile") == "Mentor") && (
                          <Link to="/search/companies?mode=discover">
                            <Button variant="text" color="secondary">
                              <span
                                style={{
                                  borderBottom: `${
                                    location.pathname.includes(
                                      `/search/companies`
                                    ) || searchParams.get("tab") === "companies"
                                      ? "2px solid #ee4b6a"
                                      : "none"
                                  }`,
                                }}
                              >
                                Companies
                              </span>
                            </Button>
                          </Link>
                        )}
                        {(localStorage.getItem("profile") == "Startup" ||
                          localStorage.getItem("profile") == "Investor" ||
                          localStorage.getItem("profile") == "Expert" ||
                          localStorage.getItem("profile") == "Mentor") && (
                          <Link to="/search/events?status=active">
                            <Button variant="text" color="secondary">
                              <span
                                style={{
                                  borderBottom: `${
                                    location.pathname.includes(
                                      `/search/events`
                                    ) || searchParams.get("tab") === "events"
                                      ? "2px solid #ee4b6a"
                                      : "none"
                                  }`,
                                }}
                              >
                                Events
                              </span>
                            </Button>
                          </Link>
                        )}
                        {(localStorage.getItem("profile") == "Startup" ||
                          localStorage.getItem("profile") == "Investor" ||
                          localStorage.getItem("profile") == "Expert" ||
                          localStorage.getItem("profile") == "Mentor") && (
                          <Link to="/search/tools">
                            <Button variant="text" color="secondary">
                              <span
                                style={{
                                  borderBottom: `${
                                    location.pathname.includes(
                                      `/search/tools`
                                    ) || searchParams.get("tab") === "tools"
                                      ? "2px solid #ee4b6a"
                                      : "none"
                                  }`,
                                }}
                              >
                                Tools
                              </span>
                            </Button>
                          </Link>
                        )}
                        {(localStorage.getItem("profile") == "Startup" ||
                          localStorage.getItem("profile") == "Investor" ||
                          localStorage.getItem("profile") == "Expert" ||
                          localStorage.getItem("profile") == "Mentor") && (
                          <Link to="/Blogs">
                            <Button variant="text" color="secondary">
                              <span
                                style={{
                                  borderBottom: `${
                                    location.pathname.includes(`/Blogs`) ||
                                    searchParams.get("tab") === "blogs"
                                      ? "2px solid #ee4b6a"
                                      : "none"
                                  }`,
                                }}
                              >
                                Blogs
                              </span>
                            </Button>
                          </Link>
                        )}
                        {(localStorage.getItem("profile") === "Startup" ||
                          localStorage.getItem("profile") === "Investor" ||
                          localStorage.getItem("profile") === "Expert" ||
                          localStorage.getItem("profile") === "Mentor") && (
                          <Link to="/discussion">
                            <Button variant="text" color="secondary">
                              <span
                                style={{
                                  borderBottom: `${
                                    location.pathname.includes(`/discussion`) ||
                                    searchParams.get("tab") === "discussion"
                                      ? "2px solid #ee4b6a"
                                      : "none"
                                  }`,
                                }}
                              >
                                Discussion
                              </span>
                            </Button>
                          </Link>
                        )}
                        {(localStorage.getItem("profile") == "Mentor" ||
                          localStorage.getItem("profile") == "Expert") && (
                          <Link to="/search/jobs?status=active">
                            <Button variant="text" color="secondary">
                              <span
                                style={{
                                  borderBottom: `${
                                    location.pathname.includes(
                                      `/search/jobs`
                                    ) || searchParams.get("tab") === "jobs"
                                      ? "2px solid #ee4b6a"
                                      : "none"
                                  }`,
                                }}
                              >
                                Jobs
                              </span>
                            </Button>
                          </Link>
                        )}
                        {/* {localStorage.getItem("profile") == "Investor" && (
                        <Link to="/search/projects">
                          <Button variant="text" color="secondary">
                            <span
                              style={{
                                borderBottom: `${
                                  location.pathname.includes(`/search/projects`)
                                    ? "2px solid #ee4b6a"
                                    : "none"
                                }`,
                              }}
                            >
                              Marketplace
                            </span>
                          </Button>
                        </Link>
                      )} */}
                      </>
                    )}

                    {localStorage.getItem("token") ? (
                      <>
                        {localStorage.getItem("profile") && (
                          <>
                            <Divider
                              orientation="vertical"
                              variant="middle"
                              flexItem
                            />
                            <Link
                              to={
                                localStorage.getItem("profile") == "Startup"
                                  ? `/dashboard/received?type=Investors`
                                  : `/dashboard/received?type=Companies`
                              }
                            >
                              <Button variant="text" color="secondary">
                                <span
                                  style={{
                                    borderBottom: `${
                                      location.pathname.includes(`/dashboard`)
                                        ? "2px solid #ee4b6a"
                                        : "none"
                                    }`,
                                  }}
                                >
                                  Dashboard
                                </span>
                              </Button>
                            </Link>
                            <Link to="/inbox?inboxIndex=1">
                              <Button variant="text" color="secondary">
                                <span
                                  style={{
                                    borderBottom: `${
                                      location.pathname.includes(`/inbox`) ||
                                      searchParams.get("tab") === "inbox"
                                        ? "2px solid #ee4b6a"
                                        : "none"
                                    }`,
                                  }}
                                >
                                  Inbox
                                </span>
                              </Button>
                            </Link>
                            <NotificationsNoneOutlinedIcon
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={(e) => {
                                if (popupIndex == 1) {
                                  setPopupIndex(0);
                                } else {
                                  setAnchorEl1(e.currentTarget);
                                  setPopupIndex(1);
                                }
                              }}
                              ref={anchorEl1}
                            />

                            <Popover
                              open={popupIndex == 1}
                              anchorEl={anchorEl1}
                              onClose={handlePopoverClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <Notifications onClose={() => setPopupIndex(0)} />
                            </Popover>
                          </>
                        )}
                        <>
                          <Box>
                            <Tooltip title={user?.user?.fullName}>
                              <IconButton
                                onClick={handleClick}
                                size="small"
                                style={{
                                  verticalAlign: "middle",
                                }}
                                aria-controls={
                                  open ? "account-menu" : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                              >
                                <Avatar
                                  {...stringAvatar(user?.user?.fullName)}
                                  id="basic-button"
                                  aria-controls={
                                    open ? "basic-menu" : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={open ? "true" : undefined}
                                  onClick={handleClick}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>

                            <Menu
                              classes={{
                                popover: mode ? "dark-bg-2" : "light-bg-2",
                              }}
                              anchorEl={anchorEl}
                              id="account-menu"
                              open={open}
                              onClose={handleClose}
                              onClick={handleClose}
                              PaperProps={{
                                className: mode ? "dark-bg-2" : "light-bg-2",
                                elevation: 0,
                                sx: {
                                  overflow: "visible",
                                  filter:
                                    "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                  mt: 1.5,
                                  "& .MuiAvatar-root": {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                  },
                                  "&:before": {
                                    content: '""',
                                    display: "block",
                                    position: "absolute",
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: mode ? "#333345" : "#fff",
                                    transform: "translateY(-50%) rotate(45deg)",
                                    zIndex: 0,
                                  },
                                },
                              }}
                              transformOrigin={{
                                horizontal: "right",
                                vertical: "top",
                              }}
                              anchorOrigin={{
                                horizontal: "right",
                                vertical: "bottom",
                              }}
                            >
                              {(profile?.data?.company?.status === "approved" ||
                                profile?.data?.company?.status ===
                                  "submitted" ||
                                profile?.data?.company?.status === "rejected" ||
                                profile?.data?.institution?.status ===
                                  "approved" ||
                                profile?.data?.institution?.status ===
                                  "submitted" ||
                                profile?.data?.institution?.status ===
                                  "rejected" ||
                                profile?.data?.individual?.status ===
                                  "approved" ||
                                profile?.data?.individual?.status ===
                                  "submitted" ||
                                profile?.data?.individual?.status ===
                                  "rejected" ||
                                profile?.data?.mentor?.status === "approved" ||
                                profile?.data?.mentor?.status === "submitted" ||
                                profile?.data?.mentor?.status === "rejected" ||
                                profile?.data?.expert?.status === "approved" ||
                                profile?.data?.expert?.status === "submitted" ||
                                profile?.data?.expert?.status ===
                                  "rejected") && (
                                <>
                                  <MenuItem
                                    onClick={() => {
                                      navigate({
                                        pathname: `/myprofile`,
                                      });
                                    }}
                                  >
                                    My Profile
                                  </MenuItem>
                                  <Divider />
                                </>
                              )}

                              {localStorage.getItem("profile") == "Startup" && (
                                <>
                                  <MenuItem
                                    onClick={() => {
                                      navigate({
                                        pathname: `/my-jobs`,
                                      });
                                    }}
                                  >
                                    My Jobs
                                  </MenuItem>
                                  <Divider />
                                </>
                              )}
                              {(companyProfile ||
                                investorProfile ||
                                mentorProfile ||
                                expertProfile) && (
                                <>
                                  <MenuItem
                                    onClick={() => {
                                      navigate({
                                        pathname: `/my-events`,
                                      });
                                    }}
                                  >
                                    My Events
                                  </MenuItem>

                                  <Divider />
                                </>
                              )}
                              {(companyProfile ||
                                investorProfile ||
                                mentorProfile ||
                                expertProfile) && (
                                <>
                                  <MenuItem
                                    onClick={() => {
                                      navigate({
                                        pathname: `/analytics`,
                                      });
                                    }}
                                  >
                                    Analytics
                                  </MenuItem>

                                  <Divider />
                                </>
                              )}
                              {(profile?.data?.company?.status === "approved" ||
                                profile?.data?.individual?.status ===
                                  "approved" ||
                                profile?.data?.institution?.status ===
                                  "approved" ||
                                profile?.data?.mentor?.status === "approved" ||
                                profile?.data?.expert?.status ===
                                  "approved") && (
                                <>
                                  <MenuItem
                                    onClick={() => {
                                      navigate({
                                        pathname: bookmarkLink(),
                                      });
                                    }}
                                  >
                                    Bookmarks
                                  </MenuItem>
                                  <Divider />
                                </>
                              )}

                              {/* {both ? (
                              <NestedMenuItem
                                label="Switch Profile"
                                parentMenuOpen={open}
                              >
                                <MenuItem
                                  onClick={() => {
                                    switchProfileHandler();
                                  }}
                                >
                                  Switch to Investor
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    switchProfileHandler();
                                  }}
                                >
                                  Switch to Mentor
                                </MenuItem>
                              </NestedMenuItem>
                            ) : (
                              <>
                                {profile?.data?.company?.status ==
                                "submitted" ? (
                                  <MenuItem
                                    onClick={() => {
                                      navigate(
                                        `/investor-create/type-of-investor`
                                      );
                                    }}
                                  >
                                    Become an Investor
                                  </MenuItem>
                                ) : (
                                  <MenuItem
                                    onClick={() => {
                                      navigate(
                                        `/startup-create/startup-details`
                                      );
                                    }}
                                  >
                                    Create a Startup
                                  </MenuItem>
                                )}
                              </>
                            )}
                             */}
                              {(companyProfile ||
                                investorProfile ||
                                mentorProfile) && (
                                <>
                                  <NestedMenuItem
                                    label="Switch Profile"
                                    parentMenuOpen={open}
                                    style={{
                                      paddingLeft: "8px",
                                    }}
                                  >
                                    {localStorage.getItem("profile") !=
                                      "Startup" &&
                                      companyProfile && (
                                        <MenuItem
                                          onClick={() => {
                                            switchProfileHandler("Startup");
                                          }}
                                        >
                                          Switch to Company
                                        </MenuItem>
                                      )}
                                    {localStorage.getItem("profile") !=
                                      "Startup" &&
                                      !companyProfile && (
                                        <MenuItem
                                          onClick={() => {
                                            navigate(
                                              `/startup-create/startup-details`
                                            );
                                          }}
                                        >
                                          Create Company
                                        </MenuItem>
                                      )}
                                    {localStorage.getItem("profile") !=
                                      "Investor" &&
                                      investorProfile && (
                                        <MenuItem
                                          onClick={() => {
                                            switchProfileHandler("Investor");
                                          }}
                                        >
                                          Switch to Investor
                                        </MenuItem>
                                      )}
                                    {localStorage.getItem("profile") !=
                                      "Investor" &&
                                      !investorProfile && (
                                        <MenuItem
                                          onClick={() => {
                                            navigate(
                                              `/investor-create/type-of-investor`
                                            );
                                          }}
                                        >
                                          Become Investor
                                        </MenuItem>
                                      )}
                                    {localStorage.getItem("profile") !=
                                      "Mentor" &&
                                      mentorProfile && (
                                        <MenuItem
                                          onClick={() => {
                                            switchProfileHandler("Mentor");
                                          }}
                                        >
                                          Switch to Mentor
                                        </MenuItem>
                                      )}
                                    {localStorage.getItem("profile") !=
                                      "Mentor" &&
                                      !mentorProfile && (
                                        <MenuItem
                                          onClick={() => {
                                            navigate(
                                              `/mentor-create/become-mentor`
                                            );
                                          }}
                                        >
                                          Become Mentor
                                        </MenuItem>
                                      )}
                                  </NestedMenuItem>
                                  <Divider
                                    sx={{
                                      marginTop: "8px",
                                    }}
                                  />
                                </>
                              )}

                              <MenuItem
                                onClick={() => {
                                  if (!searchParams.get("entry")) {
                                    navigate({
                                      pathname: `/preferences`,
                                    });
                                  } else {
                                    navigate(
                                      `/preferences?entry=${searchParams.get(
                                        "entry"
                                      )}`
                                    );
                                  }
                                }}
                              >
                                My Preferences
                              </MenuItem>

                              <Divider />

                              <MenuItem
                                onClick={() => {
                                  if (!searchParams.get("entry")) {
                                    navigate({
                                      pathname: `/settings/userdetails`,
                                    });
                                  } else {
                                    searchParams.set(
                                      "entry",
                                      `${location.pathname}${location.search}`
                                    );
                                    setSearchParams(searchParams);
                                    navigate(
                                      `/settings/userdetails?entry=${searchParams.get(
                                        "entry"
                                      )}`
                                    );
                                  }
                                }}
                              >
                                Account Settings
                              </MenuItem>

                              <Divider />
                              <MenuItem
                                onClick={() => {
                                  // removeCookie("user");
                                  // removeCookie("token");
                                  // removeCookie("refreshToken");
                                  // dispatch(logout());
                                  // dispatch(googleLogout());
                                  // dispatch(clearProfile());

                                  setOpenModal(true);
                                }}
                              >
                                <GoogleLogout
                                  clientId="658947181005-2vkuk8bh1lnsu6lkun36kktcd3oftf1m.apps.googleusercontent.com"
                                  render={(renderProps) => (
                                    <p
                                      onClick={renderProps.onClick}
                                      disabled={renderProps.disabled}
                                    >
                                      Logout
                                    </p>
                                  )}
                                  buttonText="Login"
                                  onSuccess={responseGoogleLogoutSuccess}
                                  onFailure={responseGoogleLogoutFail}
                                  cookiePolicy={"single_host_origin"}
                                />
                              </MenuItem>
                            </Menu>
                          </Box>
                        </>
                      </>
                    ) : (
                      <>
                        <Link to="/signin">
                          <Button variant="text" color="secondary">
                            Sign in
                          </Button>
                        </Link>
                        <Link to="/register">
                          <Button variant="contained">Get Started</Button>
                        </Link>
                      </>
                    )}
                  </Stack>
                  {dimension.width > 600 && <></>}
                </Stack>
              </Stack>
            </Box>
          </Container>
        </AppBar>
      </HideOnScroll>
    </React.Fragment>
  );
}
