import {
  Box,
  Typography,
  Stack,
  Button,
  TextField,
  Popover,
  InputLabel,
  InputAdornment,
} from "@mui/material";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import axiosApi from "../../../../utils/httpClient";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CloseIcon from "@mui/icons-material/Close";
import Tag from "../Tag";
import SelectedTag from "../SelectedTag";
import AddTagBlock from "../../AddTagBlock";
import { addTask } from "../../../../redux/tasks/tasks/tasksSlice";
const Index = ({ onClose }) => {
  const dispatch = useDispatch();
  const mode = useSelector((store) => store.mode.mode);
  const [tags, setTags] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [taskName, setTaskName] = useState("");
  const [description, setDescription] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [search, setSearch] = useState("");
  const [query, setQuery] = useState("");
  const [selectedGroup, setSelectedGroup] = useState(
    searchParams.get("group") ? searchParams.get("group") : ""
  );
  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };
  const searchUpdate = debounce(function (e) {
    setQuery(e.target.value);
  }, 1000);
  const getTags = async () => {
    const resp = await axiosApi.get(`tags?search_phrase=${query}`);
    setTags(resp.data.data);
  };
  useEffect(() => {
    if (query.length > 0) {
      getTags();
    }
    if (query == "") {
      setTags([]);
    }
  }, [query]);
  useEffect(() => {
    if (taskName && errorMessage) {
      setErrorMessage("");
    }
  }, [taskName]);
  const onSelectTag = (tag) => {
    setSelectedTags([...selectedTags, tag]);
  };
  const onRemoveTag = (tag) => {
    const filteredTags = selectedTags.filter((item) => item._id != tag._id);
    setSelectedTags(filteredTags);
  };
  const newTag = async () => {
    const resp = await axiosApi.post(`tags/create`, {
      name: query.toLowerCase(),
    });
    setSelectedTags([...selectedTags, resp?.data?.data]);
    setQuery("");
  };
  const createTask = async () => {
    const formData = {};
    if (taskName?.length > 0) {
      if (searchParams.get("list")) {
        formData["list"] = searchParams.get("list");
      }
      if (selectedTags?.length > 0) {
        let selectedTagss = selectedTags.map((item) => item._id);
        formData["tags"] = selectedTagss;
      }
      if (description) {
        formData["description"] = description;
      }
      formData["name"] = taskName;
      formData["dueDate"] = dueDate;
      const resp = await axiosApi.post(`/task`, formData);
      await dispatch(addTask({ data: resp.data.data }));
      onClose();
    } else {
      setErrorMessage("Task name is required");
    }
  };
  return (
    <React.Fragment>
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{
            background: "#ee4b6a",
            width: "100%",
            py: 1,
            px: 2,
            color: "#fff",
          }}
        >
          {" "}
          <Stack direction="row" spacing={2}>
            <LibraryAddIcon />
            <Typography fontSize="16px">Create Task</Typography>
          </Stack>
          <CloseIcon
            onClick={onClose}
            sx={{
              cursor: "pointer",
            }}
          />
        </Stack>
        <Stack
          spacing={2}
          sx={{
            p: 2,
          }}
        >
          <Box>
            <InputLabel shrink htmlFor="dueDate">
              <Typography color="secondary" variant="body1">
                Task
              </Typography>
            </InputLabel>
            <TextField
              value={taskName}
              onChange={(e) => {
                setTaskName(e.target.value);
              }}
              type="text"
              id="description"
              name="description"
              fullWidth
              placeholder="Enter task name"
              InputProps={{
                maxLength: 300,
                classes: {
                  input: `${mode ? "inputFieldDark" : "inputFieldLight"}`,
                },
                className: `${mode ? "dark-bg-2 " : "light-bg-2 "}`,
              }}
            />
            {errorMessage && (
              <Typography color="error" variant="body2">
                {errorMessage}
              </Typography>
            )}
          </Box>
          <Box>
            <InputLabel shrink htmlFor="dueDate">
              <Typography color="secondary" variant="body1">
                Description
              </Typography>
            </InputLabel>
            <TextField
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              multiline
              rows={3}
              type="text"
              id="description"
              name="description"
              fullWidth
              placeholder="Enter task description"
              InputProps={{
                maxLength: 300,
                classes: {
                  input: `${mode ? "inputFieldDark" : "inputFieldLight"}`,
                },
                className: `${mode ? "dark-bg-2 " : "light-bg-2 "}`,
              }}
            />
          </Box>
          <AddTagBlock
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
          />
          {/* <Stack>
            <InputLabel shrink htmlFor="dueDate">
              <Typography color="secondary" variant="body1">
                Add Tags
              </Typography>
            </InputLabel>
            <Stack direction="row" spacing={1} sx={{ pb: 2 }}>
              {selectedTags &&
                selectedTags?.length > 0 &&
                selectedTags.map((item) => (
                  <SelectedTag
                    removeTag={() => {
                      onRemoveTag(item);
                    }}
                    data={item}
                  />
                ))}
            </Stack>
            <TextField
              value={search}
              type="text"
              id="searchTerm"
              name="searchTerm"
              fullWidth
              placeholder="add tag"
              onChange={(e) => {
                setSearch(e.target.value);
                searchUpdate(e);
              }}
              InputProps={{
                classes: {
                  input: `${mode ? "inputFieldDark" : "inputFieldLight"}`,
                },
                className: `${mode ? "dark-bg-2 " : "light-bg-2 "}`,
                endAdornment: (
                  <InputAdornment position="end">
                    <Stack direction="row" alignItems="center" spacing={1}>
                      {search?.length > 0 && (
                        <CloseIcon
                          sx={{
                            color: "gray",
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            setSearch("");
                            setQuery("");
                          }}
                        />
                      )}
                    </Stack>
                  </InputAdornment>
                ),
              }}
            />
            <Stack direction="row" spacing={1} sx={{ pt: 1 }}>
              {tags &&
                tags.length > 0 &&
                tags.map((item) => (
                  <>
                    {selectedTags.filter((t) => t._id == item._id)?.length <
                      1 && (
                      <Tag
                        data={item}
                        onSelect={() => {
                          onSelectTag(item);
                        }}
                      />
                    )}
                  </>
                ))}
              {tags.length < 1 && query.length > 0 && (
                <Tag data={{ name: query }} onSelect={newTag} />
              )}
            </Stack>
          </Stack> */}
          <Box>
            <InputLabel shrink htmlFor="dueDate">
              <Typography color="secondary" variant="body1">
                Due Date and Time
              </Typography>
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                minDate={new Date()}
                inputFormat="yyyy-MM-dd HH:mm"
                value={dueDate}
                onChange={(e) => {
                  setDueDate(e);
                }}
                components={{
                  OpenPickerIcon: CalendarMonthIcon,
                }}
                renderInput={(params) => (
                  <TextField
                    id="dueDate"
                    name="dueDate"
                    fullWidth
                    className={`${mode ? "dark-bg-2 " : "light-bg-2 "}`}
                    value={dueDate}
                    InputProps={{
                      ...params.InputProps,
                      classes: {
                        input: `${mode ? "inputFieldDark" : "inputFieldLight"}`,
                      },
                    }}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>

          <Stack
            direction="row"
            justifyContent="flex-end"
            sx={{
              width: "100%",
            }}
          >
            <Button color="primary" variant="contained" onClick={createTask}>
              Create Task
            </Button>
          </Stack>
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default Index;
