import {
  Modal,
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Typography,
  Popover,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import React, { useContext, useState } from "react";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import Icon1 from "../../../../assets/icons/Card/1";
import Icon2 from "../../../../assets/icons/Card/2";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axiosApi from "../../../../utils/httpClient";
import CardMore from "../../Popups/CardMore";
import {
  selectConversation,
  setDirectMessagefromCard,
} from "../../../../redux/selectConversation/selectConversationSlice";
import CardNotes from "../../../CardNotes";
import { selectNote } from "../../../../redux/cardnotes/selectCardNote/selectCardNoteSlice";
import ApplyMessage from "../../../ApplyMessage";
import { removeBookmark } from "../../../../redux/bookmarks/investor/investorBookmarkSlice";
import * as qs from "qs";
import { SocketContext } from "../../../../context/socket";
import useQueryString from "../../../../utils/hooks/useQueryString";
const Index = ({ type, data, id, fromBookmark }) => {
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);
  const queryString = useQueryString({ type: "entry1", tab: "companies" });
  const [anchorEl, setAnchorEl] = useState(null);
  const [mouseHover, setMouseHover] = useState(false);
  const [description, setDescription] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const mode = useSelector((store) => store.mode.mode);
  const [isBookmarked, setIsBookmarked] = useState(data?.bookmark);
  const [status, setStatus] = useState(data?.interest?.status);
  const conversation = useSelector((store) => store.conversation);
  const [openPopup, setOpenPopup] = useState(false);
  const closePopup = () => {
    setOpenPopup(false);
  };
  const getColorOfIndustry = (stage) => {
    if (stage == "Scaling") {
      return "#F56F65";
    }
    if (stage == "Early revenue") {
      return "#6B43D4";
    } else {
      return "#00C0CC";
    }
  };

  const bookmarkHandler = async () => {
    try {
      const response = await axiosApi.patch(
        `${process.env.REACT_APP_BASE_URL}bookmark/company/${data._id}`
      );
      if (fromBookmark) {
        dispatch(removeBookmark({ id: data._id }));
      }
      setIsBookmarked(!isBookmarked);
    } catch (error) {}
  };

  const interestHanlder = async () => {
    try {
      var formData = {};
      const response = await axiosApi.patch(
        `${process.env.REACT_APP_BASE_URL}application/company/${data._id}`,
        {
          description: description,
        }
      );
      socket.emit("notification", {
        _id: data?._id,
      });
      setStatus("recieved");
    } catch (error) {}
  };

  const directMessageHandler = async () => {
    try {
      const response = await axiosApi.get(
        `${process.env.REACT_APP_BASE_URL}chat/check-chat/${data?._id}`
      );
      if (response?.data?.data) {
        await navigate(`/inbox?conversation=${response?.data?.data?._id}`);
      } else {
        await navigate(`/inbox?model=Company&modelId=${data?._id}`);
      }
    } catch (error) {}
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
    width: "1200px",
    height: "500px",
    maxHeight: "500px",
    margin: "auto",
    border: "none",
  };
  const [openModal, setOpenModal] = useState(false);
  const closeModal = () => {
    setOpenModal(false);
  };
  const style1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
    width: 600,
    bgcolor: mode ? "#333345" : "#fff",
    border: "2px solid #333345",

    boxShadow: 24,
    p: 5,
  };

  const onApplyMessageCancel = () => {
    closeModal();
  };
  const onApplyMessageSubmit = (message) => {
    closeModal();
    interestHanlder();
  };
  return (
    <React.Fragment>
      <Modal
        open={openModal}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style1}>
          <ApplyMessage
            description={description}
            setDescription={setDescription}
            onCancel={onApplyMessageCancel}
            onSubmit={onApplyMessageSubmit}
          />
        </Box>
      </Modal>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="dark-bg-1">
          <CardNotes onClose={handleClose} id={data?._id} model={"company"} />
        </Box>
      </Modal>
      <Box
        onMouseOver={() => {
          setMouseHover(true);
        }}
        onMouseLeave={() => {
          setMouseHover(false);
        }}
        sx={{
          height: "300px",
          cursor: "pointer",
          boxShadow: mouseHover ? "0.3rem 0.3rem #ee4b6a" : "",
          transition: "all .14s ease-out",
        }}
        onClick={() => {
          navigate({
            pathname: `/startup-detail/${data?._id}`,
            search: queryString,
          });
        }}
        p={3}
        className={mode ? "dark-bg-5" : "light-bg-5"}
      >
        <Stack
          direction="column"
          divider={<Divider orientation="horizontal" flexItem />}
          gap={2}
          justifyContent="space-between"
          sx={{
            height: "100%",
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            sx={{
              width: "100%",
            }}
          >
            {data?.profileUrl ? (
              <img
                src={data?.profileUrl}
                style={{
                  height: "100px",
                  aspectRatio: "1/1",

                  objectFit: "cover",
                }}
              />
            ) : (
              <Box
                height={"100px"}
                sx={{
                  aspectRatio: "1/1",
                }}
                // width="100px"
                className={mode ? "dark-bg-6" : "light-bg-6"}
                p={3}
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  sx={{
                    height: "100%",
                  }}
                >
                  <Typography
                    fontWeight={"bold"}
                    variant="h4"
                    align="center"
                    sx={{
                      textTransform: "uppercase",
                      color: "#fff",
                    }}
                  >
                    {data?.name?.slice(0, 2)}
                  </Typography>
                </Stack>
              </Box>
            )}

            <Box
              sx={{
                width: "100%",
              }}
            >
              <Stack
                sx={{
                  width: "100%",
                }}
                direction="row"
                justifyContent="space-between"
                spacing={1}
              >
                <Stack
                  direction="column"
                  spacing={1}
                  sx={{
                    width: "100%",
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent={"space-between"}
                    sx={{
                      width: "100%",
                      height: "30px",
                    }}
                  >
                    <Typography
                      color="secondary"
                      variant="body1"
                      sx={{
                        WebkitLineClamp: 1,
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      {data?.name}
                    </Typography>
                    {mouseHover && (
                      <Stack direction="row">
                        <Typography
                          color="secondary"
                          variant="body2"
                          sx={{
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            bookmarkHandler();
                          }}
                        >
                          {isBookmarked ? (
                            <BookmarkIcon />
                          ) : (
                            <BookmarkBorderOutlinedIcon />
                          )}
                        </Typography>
                        <MoreVertIcon
                          style={{
                            cursor: "pointer",
                          }}
                          ref={anchorEl}
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpenPopup(true);
                            setAnchorEl(e.currentTarget);
                          }}
                        />
                        <Popover
                          open={openPopup}
                          anchorEl={anchorEl}
                          onClose={closePopup}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          sx={{
                            maxWidth: "70%",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <CardMore
                            onOpen={(e) => {
                              e.stopPropagation();
                              dispatch(
                                selectNote({
                                  model: "company",
                                  id: data?._id,
                                })
                              );
                              handleOpen();
                              closePopup();
                            }}
                          />
                        </Popover>
                      </Stack>
                    )}
                  </Stack>

                  <Typography
                    variant="body2"
                    className={mode ? "dark-4" : "light-4"}
                    sx={{
                      WebkitLineClamp: 3,
                      display: "-webkit-box",
                      overflow: "hidden",
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    {data?.description}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Stack>
          <Stack
            direction="row"
            gap={1}
            divider={<Divider orientation="vertical" flexItem />}
            justifyContent="flex-start"
          >
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{
                width: "50%",
              }}
            >
              <Typography variant="body2">
                <Icon1 color={getColorOfIndustry(data?.stage[0]?.stage)} />
              </Typography>
              <Typography color="secondary" variant="body2">
                {data?.stage?.length > 0 && data?.stage[0].stage}{" "}
                {data?.stage?.length > 1 && (
                  <Link to={`/startup-detail/${data?._id}tab=companies`}>
                    {`+${data?.stage?.length - 1}`}
                  </Link>
                )}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{
                width: "50%",
              }}
            >
              <Typography variant="body2">
                <Icon2 color={"#ee4b6a"} />
              </Typography>
              <Typography color="secondary" variant="body2">
                {data?.industry?.length > 0 && data?.industry[0].name}{" "}
                {data?.industry?.length > 1 && (
                  <Link to={`/startup-detail/${data?._id}tab=companies`}>
                    {`+${data?.industry?.length - 1}`}
                  </Link>
                )}
              </Typography>
            </Stack>
          </Stack>
          <Grid container direction="row" justifyContent="center" spacing={2}>
            <Grid item xs={6}>
              {status ? (
                <Button color="warning" variant="contained" fullWidth>
                  Interest Sent
                </Button>
              ) : (
                <Button
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenModal(true);
                  }}
                >
                  Interested
                </Button>
              )}
            </Grid>
            {data?.dm && (
              <Grid item xs={6}>
                <Button
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  onClick={(e) => {
                    e.stopPropagation();
                    directMessageHandler();
                  }}
                >
                  Direct Message
                </Button>
              </Grid>
            )}
          </Grid>
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default Index;
