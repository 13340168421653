import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as qs from "qs";
import axiosApi from "../../../utils/httpClient";
const name = "exploreEvents";
const initialState = {
  exploreEvents: [],
  loading: false,
  error: null,
};

export const exploreEventsAPI = createAsyncThunk(
  "exploreEvents",
  async ({ skip, limit, country, category, keyword, kind, search }) => {
    var formData = {};
    // formData["id"] = id;
    formData["skip"] = skip;
    // formData["model"] = model;
    // formData["status"] = status;
    formData["limit"] = limit;
    if (country) {
      formData["country"] = country;
    }
    if (category) {
      formData["category"] = category;
    }
    if (keyword) {
      formData["keywords"] = keyword;
    }
    if (kind) {
      formData["kind"] = kind;
    }
    if (search) {
      formData["search"] = search;
    }

    const data = await axiosApi.get(`${process.env.REACT_APP_BASE_URL}event/`, {
      params: formData,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    });
    return data.data;
  }
);

const exploreEventsSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(exploreEventsAPI.pending, (state) => {
      state.exploreEvents = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(exploreEventsAPI.fulfilled, (state, action) => {
      state.exploreEvents = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(exploreEventsAPI.rejected, (state, action) => {
      state.exploreEvents = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default exploreEventsSlice.reducer;
