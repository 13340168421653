import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const name = "selectedJob";
const initialState = {
  jobName: "all",
  jobId: "all",
  loading: false,
  error: null,
};

export const selectJob = createAsyncThunk(
  "job/select",
  async ({ name, id }) => {
    return { name, id };
  }
);

const selectJobSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(selectJob.pending, (state) => {
      // state.selectedTimelines = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(selectJob.fulfilled, (state, action) => {
      state.jobName = action.payload.name;
      state.jobId = action.payload.id;
    });
    builder.addCase(selectJob.rejected, (state, action) => {
      // state.selectedTimelines = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default selectJobSlice.reducer;
