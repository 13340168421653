import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosApi from "../../utils/httpClient";

const name = "price";
const initialState = {
  price: [],
  loading: false,
  error: null,
};

export const fetchprice = createAsyncThunk("price/fetchprice", async () => {
  const data = await axiosApi.get(
    `${process.env.REACT_APP_BASE_URL}investmentStage`
  );
  return data.data;
});

const priceSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchprice.pending, (state) => {
      state.price = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchprice.fulfilled, (state, action) => {
      state.price = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(fetchprice.rejected, (state, action) => {
      state.price = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default priceSlice.reducer;
