import React, { useEffect } from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  Divider,
  Grid,
  Stack,
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Button,
} from "@mui/material";
import { Box } from "@mui/system";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useSelector, useDispatch } from "react-redux";
import { selectProject } from "../../../../redux/selectedproject/selectedProjectSlice";
import {
  useLocation,
  Link,
  Outlet,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

const Index = () => {
  const mode = useSelector((store) => store.mode.mode);
  const [searchParams, setSearchParams] = useSearchParams();
  const dimension = useSelector((store) => store.dimension);
  const location = useLocation();
  const navigate = useNavigate();
  const myprojects = useSelector((store) => store.myprojects?.myprojects);
  const dispatch = useDispatch();
  const selectedProject = useSelector((store) => store.selectedProject);

  var tabs;
  if (searchParams.get("type") == "Jobs") {
    tabs = [
      {
        id: 1,
        name: "Applications Sent",
        path: `/dashboard/sent${
          searchParams.get("type") ? "?type=" + searchParams.get("type") : ""
        }`,
      },
    ];
  } else {
    tabs = [
      {
        id: 1,
        name: "Interests Received",
        path: `/dashboard/received${
          searchParams.get("type") ? "?type=" + searchParams.get("type") : ""
        }`,
      },
      {
        id: 2,
        name:
          searchParams.get("type") == "Jobs"
            ? "Applications Sent"
            : "Interests Sent",
        path: `/dashboard/sent${
          searchParams.get("type") ? "?type=" + searchParams.get("type") : ""
        }`,
      },
    ];
  }

  useEffect(() => {
    if (searchParams.get("type") == "") {
      setSearchParams({ type: "Companies" });
    }
  }, [searchParams]);
  return (
    <React.Fragment>
      <Box mt={dimension.width > 600 ? 15 : 3} mb={10}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            width: "100%",
          }}
        >
          <Typography
            color="secondary"
            variant="h4"
            sx={{
              fontWeight: "600",
            }}
          >
            Dashboard
          </Typography>
          <Typography
            color="secondary"
            variant="h4"
            sx={{
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/");
            }}
          >
            <CloseOutlinedIcon />
          </Typography>
        </Stack>
        <Stack
          className="hide-scrollbar"
          direction="row"
          spacing={2}
          alignItems="center"
          alignContent="center"
          sx={{
            mt: 2,
            width: "100%",
            overflow: "scroll",
          }}
        >
          <Button
            color={
              searchParams.get("type") == "Companies" ? "primary" : "secondary"
            }
            variant={
              searchParams.get("type") == "Companies" ? "contained" : "outlined"
            }
            sx={{
              minWidth: "100px",
            }}
            onClick={() => {
              navigate("/dashboard/received?type=Companies");
            }}
          >
            Companies
          </Button>

          <Button
            color={searchParams.get("type") == "Jobs" ? "primary" : "secondary"}
            variant={
              searchParams.get("type") == "Jobs" ? "contained" : "outlined"
            }
            sx={{
              minWidth: "100px",
            }}
            onClick={() => {
              navigate("/dashboard/sent?type=Jobs");
            }}
          >
            Jobs
          </Button>
          <Button
            color={
              searchParams.get("type") == "Events" ? "primary" : "secondary"
            }
            variant={
              searchParams.get("type") == "Events" ? "contained" : "outlined"
            }
            sx={{
              minWidth: "100px",
            }}
            onClick={() => {
              navigate("/dashboard/received?type=Events");
            }}
          >
            Events
          </Button>
        </Stack>
        <Box
          mt={5}
          sx={{ width: "100%" }}
          p={dimension.width > 600 && 3}
          className={
            dimension.width > 600 ? (mode ? "dark-bg-1" : "light-bg-1") : ""
          }
        >
          <Stack
            container
            direction="row"
            // justifyContent="space-around"
            alignContent="center"
            alignItems="center"
            spacing={dimension.width <= 600 ? 3 : 7}
            sx={{
              overflowX: dimension.width <= 600 && "scroll",
              overflow: "hidden",
            }}
          >
            {tabs.map((item) => (
              <Box>
                <Link to={item.path}>
                  <Typography
                    className={
                      item.path.includes(location.pathname)
                        ? mode
                          ? "dark-0"
                          : "light-0"
                        : mode
                        ? "dark-4"
                        : "light-4"
                    }
                    variant={dimension.width > 600 ? "body1" : "body2"}
                    align="center"
                    mb={1}
                    style={{
                      borderBottom: `${
                        item.path.includes(location.pathname)
                          ? "2px solid #ee4b6a"
                          : "none"
                      }`,
                      lineHeight: "1.8rem",
                    }}
                  >
                    {item.name}
                  </Typography>
                </Link>
              </Box>
            ))}
          </Stack>

          <Outlet />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Index;
