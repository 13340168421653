import {
  Stack,
  Typography,
  Modal,
  Box,
  Popover,
  Divider,
  TextField,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import Lists from "../Lists";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationPop from "../../../../../ConfirmationsPopup";
import axiosApi from "../../../../../../utils/httpClient";
import {
  groupsAPI,
  updateGroupData,
} from "../../../../../../redux/tasks/groups/groupsSlice";
import useClickOutside from "../../../../../../utils/useClickOutside";
const Index = ({ data, handleOpen1 }) => {
  const mode = useSelector((store) => store.mode.mode);
  const [groupName, setGroupName] = useState(data?.name);
  const [groupName1, setGroupName1] = useState("");
  const [edit, setEdit] = useState(false);
  const groupNameRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const handleOpenDelete = () => setDeleteOpen(true);
  const handleCloseDelete = () => {
    setDeleteOpen(false);
    setOpenPopup(false);
  };
  const [openPopup, setOpenPopup] = useState(false);
  const closePopup = () => {
    setOpenPopup(false);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
    maxHeight: "500px",
    margin: "auto",
    border: "none",
    p: 2,
  };
  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const updateGroup = async () => {
    const formData = {};
    formData["name"] = groupName;
    const resp = await axiosApi.patch(`/group/${data._id}`, formData);
    await dispatch(
      updateGroupData({
        id: data._id,
        name: resp?.data?.data?.name,
      })
    );
  };

  const groupUpdate = debounce(function (e) {
    setGroupName1(e.target.value);
  }, 500);
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      updateGroup();
      setEdit(false);
    }
  };
  // useEffect(() => {
  //   setGroupName(data?.name);
  // }, [data]);
  useEffect(() => {
    updateGroup();
  }, [groupName1]);
  const deleteGroup = async () => {
    const resp = await axiosApi.delete(`group/${data?._id}`);
    if (data._id == searchParams.get("group")) {
      searchParams.delete("group");
      setSearchParams(searchParams);
    }
    await handleCloseDelete();
    await dispatch(groupsAPI());
  };

  useClickOutside(groupNameRef, () => {
    setEdit(false);
  });

  return (
    <React.Fragment>
      <Modal
        open={deleteOpen}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={mode ? "dark-bg-1" : "light-bg-2"}>
          <ConfirmationPop
            onSubmit={deleteGroup}
            onCancel={handleCloseDelete}
            message={"Do you want to delete the group?"}
            title={"Delete Group"}
          />
        </Box>
      </Modal>
      <Stack direction="column" spacing={2}>
        <Stack
          key={data._id}
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent={"space-between"}
          className={
            mode
              ? data._id == searchParams.get("group")
                ? "light-1"
                : "light-2"
              : data._id == searchParams.get("group")
              ? "dark-8"
              : "dark-2"
          }
          sx={{
            cursor: "pointer",
            width: "100%",
          }}
          onClick={() => {
            if (!edit) {
              if (searchParams.get("filter")) {
                searchParams.delete("filter");
              }
              if (searchParams.get("list")) {
                searchParams.delete("list");
              }
              if (data._id == searchParams.get("group")) {
                searchParams.delete("group");
                setSearchParams(searchParams);
              } else {
                searchParams.delete("list");
                searchParams.set("group", data._id);
                setSearchParams(searchParams);
              }
            }
          }}
        >
          {edit ? (
            <TextField
              ref={groupNameRef}
              value={groupName}
              type="text"
              id="description"
              name="description"
              placeholder="Enter group name"
              InputProps={{
                style: {
                  height: "30px",
                },
                maxLength: 300,
                classes: {
                  input: `${mode ? "inputFieldDark" : "inputFieldLight"}`,
                },
                className: `${mode ? "dark-bg-2 " : "light-bg-2 "}`,
              }}
              onChange={(e) => {
                e.stopPropagation();
                setGroupName(e.target.value);
                groupUpdate(e);
              }}
              onKeyDown={handleKeyDown}
            />
          ) : (
            <Typography fontSize="16px">{groupName}</Typography>
          )}

          <Stack direction="row" spacing={1}>
            {data._id == searchParams.get("group") && (
              <AddIcon
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpen1();
                }}
                sx={{
                  cursor: "pointer",
                }}
              />
            )}

            <MoreVertIcon
              onClick={(e) => {
                e.stopPropagation();
                setOpenPopup(true);
                setAnchorEl(e.currentTarget);
              }}
            />
            <Popover
              open={openPopup}
              anchorEl={anchorEl}
              onClose={closePopup}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              sx={{
                maxWidth: "70%",
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Stack
                className={mode ? "dark-bg-9" : "light-bg-9"}
                direction="column"
                spacing={1}
                divider={<Divider orientation="horizontal" />}
                sx={{
                  p: 1,
                }}
              >
                <Typography
                  fontSize="16px"
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenPopup(false);
                    handleOpenDelete();
                  }}
                >
                  Delete
                </Typography>
                <Typography
                  fontSize="16px"
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenPopup(false);
                    setEdit(true);
                  }}
                >
                  Edit
                </Typography>
              </Stack>
            </Popover>
          </Stack>
        </Stack>
        {data._id == searchParams.get("group") && <Lists groupId={data._id} />}
      </Stack>
    </React.Fragment>
  );
};

export default Index;
