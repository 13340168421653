import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getCookie } from "./httpClient";

import { useSelector } from "react-redux";
import Loader from "../components/Loader";

function MentorProtectedRoute({ children }) {
  const isAuthenticated = getCookie("token");

  const profile = useSelector((store) => store.profile);
  let location = useLocation();
  if (
    profile?.data?.mentor &&
    profile?.data?.mentor?.status &&
    profile?.data?.mentor?.status == "approved"
  ) {
    return (
      <Navigate
        to={"/search/companies?mode=discover"}
        replace
        state={{ from: location }}
      />
    );
  } else if (
    profile?.data?.mentor &&
    profile?.data?.mentor?.status &&
    profile?.data?.mentor?.status == "submitted"
  ) {
    return (
      <Navigate
        to={`/application-status/mentor/${profile?.data?.mentor?._id}`}
        replace
        state={{ from: location }}
      />
    );
  } else if (
    profile?.data?.mentor &&
    profile?.data?.mentor?.status &&
    profile?.data?.mentor?.status == "rejected"
  ) {
    return (
      <Navigate
        to={`/application-status/mentor/${profile?.data?.mentor?._id}`}
        replace
        state={{ from: location }}
      />
    );
  } else {
    return children;
  }
}

export default MentorProtectedRoute;
