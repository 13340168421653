import React from "react";
import io from "socket.io-client";

export const socket = io(process.env.REACT_APP_SOCKET_URL, {
  transports: ["websocket"],
  query: {
    token: localStorage.getItem("token"),
  },
  autoConnect: true,
  reconnection: true, // enable automatic reconnection
  reconnectionAttempts: Infinity, // try to reconnect 5 times
  reconnectionDelay: 1000,
});

// export const getNewSocket = () => {
//   return io(process.env.REACT_APP_SOCKET_URL, {
//     transports: ["websocket"],
//     query: {
//       token: localStorage.getItem("token"),
//     },
//     autoConnect: true,
//     reconnection: true, // enable automatic reconnection
//     reconnectionAttempts: Infinity, // try to reconnect 5 times
//     reconnectionDelay: 1000,
//   });
// };
export const SocketContext = React.createContext();
