import {
  Box,
  Drawer,
  Typography,
  Divider,
  Stack,
  IconButton,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import axiosApi from "../../utils/httpClient";
import { useSelector } from "react-redux";
import { NestedMenuItem } from "mui-nested-menu";
const Index = (props) => {
  const { window } = props;
  const drawerWidth = 240;
  const [companyProfile, setCompanyProfile] = useState(false);
  const [investorProfile, setInvestorProfile] = useState(false);
  const [mentorProfile, setMentorProfile] = useState(false);
  const [switchProfileOpen, setSwithProfileOpen] = useState(false);
  const [expertProfile, setExpertProfile] = useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const profile = useSelector((store) => store.profile);
  const homeHandler = () => {
    if (localStorage.getItem("profile")) {
      if (localStorage.getItem("profile") == "Startup") {
        navigate(`/search/investors?mode=discover`);
      } else if (localStorage.getItem("profile") == "Investor") {
        navigate(`/search/companies?mode=discover`);
      } else if (localStorage.getItem("profile") == "Mentor") {
        navigate(`/search/companies?mode=discover`);
      } else if (localStorage.getItem("profile") == "Expert") {
        navigate(`/search/companies?mode=discover`);
      }
    } else {
      navigate(`/`);
    }
  };

  const checkProfiles = async () => {
    if (profile?.success) {
      if (
        profile?.data?.company?.status == "approved" ||
        profile?.data?.company?.status == "rejected" ||
        profile?.data?.company?.status == "submitted"
      ) {
        setCompanyProfile(true);
      }
      if (
        profile?.data?.individual !== null ||
        profile?.data?.institution !== null
      ) {
        setInvestorProfile(true);
      }
      if (profile?.data?.mentor?.firstName) {
        setMentorProfile(true);
      }
      if (profile?.data?.expert?.firstName) {
        setExpertProfile(true);
      }
    }

    if (!localStorage.getItem("profile")) {
      if (
        profile?.data?.user?.whichProfile &&
        profile?.data?.user?.whichProfile != "User"
      ) {
        const wp = profile?.data?.user?.whichProfile;
        if (wp == "Individual" || wp == "Institution") {
          localStorage.setItem("profile", "Investor");
        } else if (wp == "Company") {
          localStorage.setItem("profile", "Startup");
        } else {
          localStorage.setItem("profile", wp);
        }
      } else {
        if (companyProfile) {
          localStorage.setItem("profile", "Startup");

          const resp = await axiosApi.get(`auth/switch-profile?to=Company`);
        } else if (investorProfile) {
          localStorage.setItem("profile", "Investor");
          const tt = (await profile?.data?.individual)
            ? "Individual"
            : "Institution";
          const resp = await axiosApi.get(`auth/switch-profile?to=${tt}`);
        } else if (mentorProfile) {
          localStorage.setItem("profile", "Mentor");
          const resp = await axiosApi.get(`auth/switch-profile?to=Mentor`);
        } else if (expertProfile) {
          localStorage.setItem("profile", "Expert");
          const resp = await axiosApi.get(`auth/switch-profile?to=Expert`);
        }
      }
    }
  };

  const switchProfileHandler = async (type) => {
    if (type == "Startup") {
      localStorage.setItem("profile", "Startup");

      const resp = await axiosApi.get(`auth/switch-profile?to=Company`);

      await navigate(`/search/investors?mode=discover`);
    } else if (type == "Investor") {
      localStorage.setItem("profile", "Investor");
      const tt = (await profile?.data?.individual)
        ? "Individual"
        : "Institution";
      const resp = await axiosApi.get(`auth/switch-profile?to=${tt}`);

      await navigate(`/search/companies?mode=discover`);
    } else {
      localStorage.setItem("profile", "Mentor");
      const resp = await axiosApi.get(`auth/switch-profile?to=Mentor`);

      await navigate(`/search/companies?mode=discover`);
    }
  };

  useEffect(() => {
    // checkBothProfiles();
    if (localStorage.getItem("token")) {
      checkProfiles();
    }
  }, [profile]);
  const drawer = (
    <Box className={{}} onClick={handleDrawerToggle} sx={{ py: 5, pl: 2 }}>
      <Stack>
        {localStorage.getItem("profile") == "Startup" && (
          <>
            <MenuItem
              onClick={() => {
                navigate(`/my-jobs`);
              }}
            >
              My Jobs
            </MenuItem>
            <Divider />
          </>
        )}
        {(companyProfile ||
          investorProfile ||
          mentorProfile ||
          expertProfile) && (
          <>
            <MenuItem
              onClick={() => {
                navigate(`/my-events`);
              }}
            >
              My Events
            </MenuItem>

            <Divider />
          </>
        )}
        {(companyProfile ||
          investorProfile ||
          mentorProfile ||
          expertProfile) && (
          <>
            <MenuItem
              onClick={() => {
                navigate(
                  `/analytics?entry=%2Fdashboard%2Freceived&filter=total`
                );
              }}
            >
              Analytics
            </MenuItem>

            <Divider />
          </>
        )}
        <MenuItem
          onClick={() => {
            navigate(`/bookmarks`);
          }}
        >
          Bookmarks
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            navigate(`/discussion`);
          }}
        >
          Discussion
        </MenuItem>
        <Divider />
        {(companyProfile || investorProfile || mentorProfile) && (
          <>
            {localStorage.getItem("profile") != "Startup" && companyProfile && (
              <MenuItem
                onClick={() => {
                  switchProfileHandler("Startup");
                }}
              >
                Switch to Company
              </MenuItem>
            )}
            {localStorage.getItem("profile") != "Startup" &&
              !companyProfile && (
                <MenuItem
                  onClick={() => {
                    navigate(`/startup-create/startup-details`);
                  }}
                >
                  Create Company
                </MenuItem>
              )}
            {localStorage.getItem("profile") != "Investor" &&
              investorProfile && (
                <MenuItem
                  onClick={() => {
                    switchProfileHandler("Investor");
                  }}
                >
                  Switch to Investor
                </MenuItem>
              )}
            {localStorage.getItem("profile") != "Investor" &&
              !investorProfile && (
                <MenuItem
                  onClick={() => {
                    navigate(`/investor-create/type-of-investor`);
                  }}
                >
                  Become Investor
                </MenuItem>
              )}
            {localStorage.getItem("profile") != "Mentor" && mentorProfile && (
              <MenuItem
                onClick={() => {
                  switchProfileHandler("Mentor");
                }}
              >
                Switch to Mentor
              </MenuItem>
            )}
            {localStorage.getItem("profile") != "Mentor" && !mentorProfile && (
              <MenuItem
                onClick={() => {
                  navigate(`/mentor-create/become-mentor`);
                }}
              >
                Become Mentor
              </MenuItem>
            )}
          </>
        )}
      </Stack>
    </Box>
  );
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <React.Fragment>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          width: "100%",
          pt: 3,
        }}
      >
        <Typography
          variant="h4"
          color="primary"
          className="logo"
          fontWeight={"600"}
          sx={{
            cursor: "pointer",
          }}
          onClick={homeHandler}
        >
          BeBlossom
        </Typography>
        <IconButton
          size="large"
          color="primary"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ display: { sm: "none", padding: "0px" } }}
        >
          <MenuIcon fontSize="32px" />
        </IconButton>
      </Stack>
      <Box component="nav">
        <Drawer
          anchor={"right"}
          color="primary"
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </React.Fragment>
  );
};

export default Index;
