import React, { useState } from "react";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import {
  Typography,
  Popover,
  Stack,
  Divider,
  Button,
  Box,
  Modal,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { Link, useLocation, Outlet, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";

import { GoogleLogout } from "react-google-login";
// import { googleLogout } from "../../../redux/auth/google/googleSlice";
import { logout, googleLogout } from "../../../redux/auth/signin/signinSlice";
import { clearProfile } from "../../../redux/auth/profile/profileSlice";
import ConfirmationPop from "../../../components/ConfirmationsPopup";
const Index = () => {
  const mode = useSelector((store) => store.mode.mode);
  const [openModal, setOpenModal] = useState(false);
  const closeModal = () => setOpenModal(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tabs = [
    {
      id: 0,
      name: "My Profile",
      path: "/myprofile",
    },
    {
      id: 1,
      name: "Username Details",
      path: "/settings/userdetails",
    },
    {
      id: 2,
      name: "Preferences",
      path: "/preferences",
    },
    {
      id: 3,
      name: "Password Settings",
      path: "/settings/change-password",
    },
    {
      id: 4,
      name: "Notification Settings",
      path: "/settings/notification",
    },
    {
      id: 5,
      name: "Delete Account",
      path: "/settings/delete-account",
    },
    {
      id: 6,
      name: "Help Center / FAQs",
      path: "/settings/help",
    },
  ];

  const logoutHandle = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("profile");

    // removeCookie("profileToken");

    dispatch(logout());
    dispatch(googleLogout());
    dispatch(clearProfile());

    closeModal();
    navigate("/signin");
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
    width: 400,
    bgcolor: mode ? "#333345" : "#fff",
    border: "2px solid #333345",

    boxShadow: 24,
    p: 3,
  };
  return (
    <React.Fragment>
      <Modal
        open={openModal}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ConfirmationPop
            onSubmit={logoutHandle}
            onCancel={closeModal}
            message={"Do you want to logout?"}
            title={"Logout"}
          />
        </Box>
      </Modal>
      {location.pathname == "/settings" ? (
        <React.Fragment>
          <Stack
            direction={"row"}
            spacing={1}
            alignItems="center"
            sx={{
              mt: 2,
            }}
          >
            <Typography color="secondary">
              <ArrowBackOutlinedIcon />
            </Typography>
            <Typography variant="h3" color="secondary" fontWeight="500">
              Settings
            </Typography>
          </Stack>

          <Stack
            sx={{
              mt: 4,
            }}
            direction="column"
            spacing={2}
            divider={<Divider orientation="horizontal" />}
          >
            {tabs.map((item) => (
              <Link to={item.path}>
                <Stack
                  direction="row"
                  justifyContent={"space-between"}
                  alignContent="center"
                >
                  <Typography color="secondary" variant="body1">
                    {item.name}
                  </Typography>
                  <Typography
                    className={mode ? "dark-2" : "light-2"}
                    variant="body1"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    <ArrowForwardIosOutlinedIcon />
                  </Typography>
                </Stack>
              </Link>
            ))}
          </Stack>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              mt: 4,
            }}
          >
            <Button
              color="primary"
              variant="outlined"
              sx={{
                px: 4,
              }}
              onClick={() => {
                // dispatch(logout());
                // dispatch(googleLogout());
                // navigate("/");
                setOpenModal(true);
              }}
            >
              Logout
            </Button>
          </Stack>
        </React.Fragment>
      ) : (
        <Outlet />
      )}
    </React.Fragment>
  );
};

export default Index;
