import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const name = "selectedEventsKeywords";
const initialState = {
  selectedEventsKeywords: [],
  loading: false,
  error: null,
};

export const selectEventsKeywords = createAsyncThunk(
  "eventskeywords/selected",
  async (keywords) => {
    return keywords;
  }
);

const selectedEventsKeywordsSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(selectEventsKeywords.pending, (state) => {
      state.selectedEventsKeywords = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(selectEventsKeywords.fulfilled, (state, action) => {
      state.selectedEventsKeywords = action.payload;
    });
    builder.addCase(selectEventsKeywords.rejected, (state, action) => {
      state.selectedEventsKeywords = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default selectedEventsKeywordsSlice.reducer;
