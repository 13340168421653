// const getTime = (date) => {
//   let elapsed;
//   let diff = (new Date() - new Date(date)) / 1000;

//   if (diff / 86400 < 1) {
//     if (diff / 3600 < 1) {
//       if (diff / 60 < 1) {
//         elapsed = "just now";
//       } else {
//         if (Math.round(diff / 60) == 1) elapsed = "1 m";
//         elapsed = Math.round(diff / 60) + " m";
//       }
//     } else {
//       if (Math.round(diff / 3600) == 1) elapsed = "1 h";
//       elapsed = Math.round(diff / 3600) + " h";
//     }
//   } else {
//     elapsed = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
//   }

//   return elapsed;
// };

function getTime(date) {
  const now = new Date();
  const seconds = Math.floor((now - date) / 1000);
  let interval = Math.floor(seconds / 31536000);

  if (interval > 1) {
    return interval + "y";
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return interval + "mo";
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return interval + "d";
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return interval + "h";
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return interval + "m";
  }
  return Math.floor(seconds) + "s";
}

export default getTime;
