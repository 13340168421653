import { Container, Divider, Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useSelector } from "react-redux";
import {
  Link,
  useLocation,
  Outlet,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import MobilseSettingsLayout from "./Mobile";
import useQueryString from "../../utils/hooks/useQueryString";
const Index = () => {
  const mode = useSelector((store) => store.mode.mode);
  const profile = useSelector((store) => store.profile);
  const dimension = useSelector((store) => store.dimension);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  let location = useLocation();
  let tabs;
  if (
    profile?.data?.institution?.status === "approved" ||
    profile?.data?.individual?.status === "approved" ||
    profile?.data?.company?.status === "approved" ||
    profile?.data?.mentor?.status === "approved" ||
    profile?.data?.expert?.status === "approved"
  ) {
    tabs = [
      {
        id: 1,
        name: "Username Details",
        path: searchParams.get("entry")
          ? `/settings/userdetails?entry=${searchParams.get("entry")}`
          : "/settings/userdetails",
      },
      {
        id: 2,
        name: "Password Settings",
        path: searchParams.get("entry")
          ? `/settings/change-password?entry=${searchParams.get("entry")}`
          : "/settings/change-password",
      },
      {
        id: 3,
        name: "Notification Settings",
        path: searchParams.get("entry")
          ? `/settings/notification?entry=${searchParams.get("entry")}`
          : "/settings/notification",
      },
      {
        id: 6,
        name: "Message Settings",
        path: searchParams.get("entry")
          ? `/settings/message-settings?entry=${searchParams.get("entry")}`
          : "/settings/message-settings",
      },
      {
        id: 4,
        name: "Delete Account",
        path: searchParams.get("entry")
          ? `/settings/delete-account?entry=${searchParams.get("entry")}`
          : "/settings/delete-account",
      },
      {
        id: 5,
        name: "Contact Us",
        path: searchParams.get("entry")
          ? `/settings/help?entry=${searchParams.get("entry")}`
          : "/settings/help",
      },
    ];
  } else {
    tabs = [
      {
        id: 1,
        name: "Username Details",
        path: searchParams.get("entry")
          ? `/settings/userdetails?entry=${searchParams.get("entry")}`
          : "/settings/userdetails",
      },
      {
        id: 2,
        name: "Password Settings",
        path: searchParams.get("entry")
          ? `/settings/change-password?entry=${searchParams.get("entry")}`
          : "/settings/change-password",
      },

      {
        id: 4,
        name: "Delete Account",
        path: searchParams.get("entry")
          ? `/settings/delete-account?entry=${searchParams.get("entry")}`
          : "/settings/delete-account",
      },
      {
        id: 5,
        name: "Contact Us",
        path: searchParams.get("entry")
          ? `/settings/help?entry=${searchParams.get("entry")}`
          : "/settings/help",
      },
    ];
  }

  const queryString = useQueryString({ type: "entry", close: true });

  return (
    <React.Fragment>
      {dimension.width > 600 ? (
        <Box
          className={
            dimension.width > 600 ? (mode ? "dark-bg-1" : "light-bg-1") : ""
          }
          sx={{
            width: "100vw",
            minHeight: "100vh",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <Container maxWidth="lg">
            <Stack
              spacing={2}
              sx={{
                mt: 4,
                mb: 10,
                p: 4,
              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  width: "100%",
                }}
              >
                <Typography
                  className={mode ? "dark-4" : "light-4"}
                  variant="h5"
                  sx={{
                    textTransform: "uppercase",
                  }}
                >
                  Settings
                </Typography>
                <CloseIcon
                  className={mode ? "dark-4" : "light-4"}
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (searchParams.get("entry")) {
                      navigate({
                        pathname: `${searchParams.get("entry")}`,
                        search: queryString,
                      });
                    } else {
                      navigate(`/`);
                    }
                  }}
                />
              </Stack>
              <Grid container direction="row" spacing={1}>
                <Grid
                  item
                  xs={3}
                  style={{
                    paddingLeft: "0px",
                  }}
                >
                  <Box>
                    <Stack alignContent="center" direction="column" spacing={3}>
                      {tabs.map((item) => (
                        <Link to={item.path}>
                          <Typography
                            variant={
                              item.path.includes(`${location.pathname}`)
                                ? "h6"
                                : "body1"
                            }
                            className={
                              // item.path == location.pathname
                              item.path.includes(`${location.pathname}`)
                                ? mode
                                  ? "dark-0"
                                  : "light-0"
                                : mode
                                ? "dark-4"
                                : "light-4"
                            }
                          >
                            {item.name}
                          </Typography>
                        </Link>
                      ))}
                    </Stack>
                  </Box>
                </Grid>

                <Grid item xs={9}>
                  <Box>
                    <Outlet />
                  </Box>
                </Grid>
              </Grid>
            </Stack>
          </Container>
        </Box>
      ) : (
        <MobilseSettingsLayout />
      )}
    </React.Fragment>
  );
};

export default Index;
