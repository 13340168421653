import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import LeftBlock from "./LeftBlock";
import RightBlock from "./RightBlock";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
const Index = ({ onClose }) => {
  const mode = useSelector((store) => store.mode.mode);
  const dimension = useSelector((store) => store.dimension);
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <React.Fragment>
      {dimension.width > 600 ? (
        <Grid
          container
          sx={{
            mt: 15,
          }}
          style={{
            height: "calc(100vh - 150px)",
          }}
        >
          <Grid
            item
            xs={3}
            className={mode ? "dark-bg-00" : "light-bg-2"}
            sx={{
              height: "100%",
            }}
          >
            <LeftBlock />
          </Grid>
          <Grid
            item
            xs={9}
            className={mode ? "dark-bg-2" : "light-bg-2"}
            sx={{
              height: "100%",
            }}
          >
            <RightBlock />
          </Grid>
        </Grid>
      ) : (
        <>
          <Box
            pt={2}
            style={{
              height: "calc(100vh - 150px)",
            }}
          >
            {searchParams.get("filter") || searchParams.get("list") ? (
              <>
                {" "}
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  sx={{
                    pb: 3,
                  }}
                >
                  <KeyboardBackspaceIcon
                    onClick={() => {
                      searchParams.delete("filter");
                      searchParams.delete("list");
                      setSearchParams(searchParams);
                    }}
                  />
                  <Typography color="secondary" fontSize="20px">
                    Menu
                  </Typography>
                </Stack>
                <RightBlock />
              </>
            ) : (
              <>
                <LeftBlock />
              </>
            )}
          </Box>
        </>
      )}
    </React.Fragment>
  );
};

export default Index;
