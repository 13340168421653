import getRelativeDate from "./getRelativeDate";
function getTimeDifference(specificDate) {
  // Get the current time
  const currentTime = new Date();

  // Calculate the difference in milliseconds between the two dates
  const differenceInTime = specificDate - currentTime;

  // Calculate the difference in seconds
  const differenceInSeconds = differenceInTime / 1000;

  // Calculate the difference in minutes
  const differenceInMinutes = differenceInSeconds / 60;

  // Calculate the difference in hours
  const differenceInHours = differenceInMinutes / 60;

  // Calculate the difference in days
  const differenceInDays = differenceInHours / 24;

  // Calculate the difference in months
  const differenceInMonths = differenceInDays / 30;

  // Calculate the difference in years
  const differenceInYears = differenceInMonths / 12;

  // Return an object with the different units of time

  const years = Math.floor(differenceInYears);
  const months = Math.floor(differenceInMonths);
  const days = Math.floor(differenceInDays);
  const hours = Math.floor(differenceInHours);
  const minutes = Math.floor(differenceInMinutes);
  const seconds = Math.floor(differenceInSeconds);

  if (years > 0) {
    return years + " years left";
  } else if (months > 0) {
    return months + " months left";
  } else if (days > 0) {
    return days + " days left";
  } else if (hours > 0) {
    return hours + " hours left";
  } else if (minutes > 0) {
    return minutes + " minutes left";
  } else if (seconds > 0) {
    return seconds + " seconds left";
  } else {
    return getRelativeDate(specificDate);
  }
}

export default getTimeDifference;
