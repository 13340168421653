import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const name = "swtich";
const initialState = {
  profile: "Startup",
  loading: false,
  error: null,
};

export const switchProfile = createAsyncThunk("switch", async (profile) => {
  return profile;
});

const switchProfileSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(switchProfile.fulfilled, (state, action) => {
      state.profile = action.payload;
      state.loading = false;
      state.error = null;
    });
  },
});

export default switchProfileSlice.reducer;
