import { Button, Icon, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import * as qs from "qs";
import { useDispatch, useSelector } from "react-redux";
import axiosApi from "../../../../../../utils/httpClient";
import { useSearchParams } from "react-router-dom";
import { groupListsAPI } from "../../../../../../redux/tasks/groups/lists/listsSlice";
import List from "../../../List";
const Index = ({ groupId }) => {
  const mode = useSelector((store) => store.mode.mode);
  const dispatch = useDispatch();
  const grouplists = useSelector((store) => store.grouplists.grouplists);
  const [activeTab, setActiveTab] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const getLists = () => {
    dispatch(groupListsAPI(groupId));
  };
  useEffect(() => {
    getLists();
  }, []);

  return (
    <React.Fragment>
      {" "}
      {grouplists && grouplists?.length > 0 && (
        <Stack
          direction="column"
          spacing={2}
          sx={{
            pl: 4,
          }}
        >
          <Stack direction="column" spacing={2}>
            {grouplists &&
              grouplists?.length > 0 &&
              grouplists.map((item) => (
                // <Stack
                //   key={item._id}
                //   direction="row"
                //   spacing={2}
                //   alignItems="center"
                //   justifyContent={"space-between"}
                //   className={
                //     mode
                //       ? item._id == searchParams.get("list")
                //         ? "light-1"
                //         : "light-2"
                //       : item._id == searchParams.get("list")
                //       ? "dark-8"
                //       : "dark-2"
                //   }
                //   sx={{
                //     cursor: "pointer",
                //     width: "100%",
                //   }}
                //   onClick={() => {
                //     searchParams.set("list", item._id);
                //     setSearchParams(searchParams);
                //   }}
                // >
                //   <Typography fontSize="16px">{item.name}</Typography>
                // </Stack>
                <List data={item} />
              ))}
          </Stack>
        </Stack>
      )}
    </React.Fragment>
  );
};

export default Index;
