import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as qs from "qs";
import axiosApi from "../../utils/httpClient";
const name = "receivedToMentor";
const initialState = {
  receivedToMentor: [],
  loading: false,
  error: null,
};

export const receivedToMentorAPI = createAsyncThunk(
  "receivedToMentor",
  async ({ search, skip, limit, status, country, stage }) => {
    var formData = {};
    if (status) {
      if (status == "Submited") {
        formData["status"] = "received";
      } else if (status == "Approved") {
        formData["status"] = "approved";
      } else if (status == "Rejected") {
        formData["status"] = "rejected";
      }
    }
    if (stage?.length > 0) {
      formData["stage"] = stage;
    }
    if (country?.length > 0) {
      formData["country"] = country;
    }
    if (search) {
      formData["search"] = search;
    }

    const data = await axiosApi.get(
      `${process.env.REACT_APP_BASE_URL}application/mentor/received/?skip=${skip}&limit=${limit}`,
      {
        params: formData,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      }
    );
    return data.data;
  }
);

const receivedToMentorSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(receivedToMentorAPI.pending, (state) => {
      state.receivedToMentor = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(receivedToMentorAPI.fulfilled, (state, action) => {
      state.receivedToMentor = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(receivedToMentorAPI.rejected, (state, action) => {
      state.receivedToMentor = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default receivedToMentorSlice.reducer;
