import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as qs from "qs";
import axiosApi from "../../../utils/httpClient";
const name = "exploreInvestor";
const initialState = {
  exploreInvestor: [],
  loading: false,
  error: null,
};

export const exploreInvestorAPI = createAsyncThunk(
  "explore/investor",
  async ({
    industries,
    stage,
    funding,
    country,
    search,
    skip,
    limit,
    id,
    model,
  }) => {
    var formData = {};
    if (industries?.length > 0) {
      formData.industries = industries;
    }
    if (stage?.length > 0) {
      formData["stage"] = stage;
    }
    if (funding?.length > 0) {
      formData["funding"] = funding;
    }
    if (country?.length > 0) {
      formData["country"] = country;
    }
    if (search?.length > 0) {
      formData["search"] = search;
    }
    formData["_id"] = id;
    formData["model"] = model;

    const data = await axiosApi.get(
      `${process.env.REACT_APP_BASE_URL}company?skip=${skip}&limit=${limit}`,
      {
        params: formData,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      }
    );
    return data.data;
  }
);

const exploreInvestorSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(exploreInvestorAPI.pending, (state) => {
      state.exploreInvestor = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(exploreInvestorAPI.fulfilled, (state, action) => {
      state.exploreInvestor = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(exploreInvestorAPI.rejected, (state, action) => {
      state.exploreInvestor = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default exploreInvestorSlice.reducer;
