import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const name = "profiletype";
const initialState = {
  profiletype: "",
  loading: false,
  error: null,
};

export const setProfileType = createAsyncThunk("profiletype", async (type) => {
  return type;
});

const profiletypeSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(setProfileType.fulfilled, (state, action) => {
      state.profiletype = action.payload;
      state.loading = false;
      state.error = null;
    });
  },
});

export default profiletypeSlice.reducer;
