import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosApi from "../../utils/httpClient";

const name = "projectType";
const initialState = {
  projectType: [],
  loading: false,
  error: null,
};

export const fetchProjectType = createAsyncThunk(
  "projectType/fetchProjectType",
  async () => {
    const data = await axiosApi.get(
      `${process.env.REACT_APP_BASE_URL}project-type`
    );
    return data.data;
  }
);

const projectTypeSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchProjectType.pending, (state) => {
      state.projectType = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchProjectType.fulfilled, (state, action) => {
      state.projectType = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(fetchProjectType.rejected, (state, action) => {
      state.projectType = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default projectTypeSlice.reducer;
