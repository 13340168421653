import React from 'react'
import { Outlet } from 'react-router-dom'

function SearchLayout() {
	return (
		<React.Fragment>
			<Outlet />
		</React.Fragment>
	)
}
export default SearchLayout
