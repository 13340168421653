import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as qs from "qs";
import axiosApi from "../../../utils/httpClient";
const name = "notes";
const initialState = {
  notes: [],
  filtered: [],
  loading: false,
  error: null,
};

export const fetchNotes = createAsyncThunk("fetchNotes", async () => {
  const data = await axiosApi.get(`${process.env.REACT_APP_BASE_URL}note/`);
  return data.data;
});

export const searchNotes = createAsyncThunk(
  "notes/searchNotes",
  async ({ query }, { getState }) => {
    const state = getState();
    const notes = state?.notes?.notes;

    if (query.length > 0) {
      const queryTerm = query.toLowerCase();
      const results = notes.filter(
        (item) =>
          item.title?.toLowerCase().includes(queryTerm) ||
          item.note?.toLowerCase().includes(queryTerm)
      );

      return results;
    } else {
      return notes;
    }
  }
);

export const newNote = createAsyncThunk(
  "fetchNotes/newNote",
  async ({ result }, { getState }) => {
    return { data: result };
  }
);

export const updateNoteAction = createAsyncThunk(
  "fetchNotes/updateNOte",
  async ({ data }) => {
    return data;
  }
);

const fetchNotesSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchNotes.pending, (state) => {
      state.notes = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchNotes.fulfilled, (state, action) => {
      state.notes = action.payload.data;
      state.filtered = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(fetchNotes.rejected, (state, action) => {
      state.notes = [];
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(newNote.fulfilled, (state, action) => {
      state.filtered = [...state.filtered, action.payload.data];
      state.loading = false;
      state.error = null;
    });
    builder.addCase(searchNotes.fulfilled, (state, action) => {
      state.filtered = action.payload;
      state.loading = false;
      state.error = null;
      state.success = true;
    });
    builder.addCase(updateNoteAction.fulfilled, (state, action) => {
      const objIndex = state.notes.findIndex(
        (obj) => obj._id == action.payload._id
      );

      state.filtered[objIndex] = action.payload;

      state.loading = false;
      state.error = null;
    });
  },
});

export default fetchNotesSlice.reducer;
