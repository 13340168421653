import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const name = "selectedNote";
const initialState = {
  note: "",
  loading: false,
  error: null,
};

export const selectNote = createAsyncThunk("job/note", async (id) => {
  return id;
});

const selectNoteSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(selectNote.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(selectNote.fulfilled, (state, action) => {
      state.note = action.payload;
    });
    builder.addCase(selectNote.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default selectNoteSlice.reducer;
