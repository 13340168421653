import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const name = "availbility";
const initialState = {
  availbility: 18,
  loading: false,
  error: null,
};

export const setAvailbility = createAsyncThunk("availbility", async (type) => {
  return type;
});

const availbilitySlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(setAvailbility.fulfilled, (state, action) => {
      state.availbility = action.payload;
      state.loading = false;
      state.error = null;
    });
  },
});

export default availbilitySlice.reducer;
