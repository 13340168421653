import { Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import stc from "string-to-color";
const Index = ({ data, onSelect }) => {
  const mode = useSelector((store) => store.mode.mode);
  return (
    <Stack
      className={mode ? "dark-bg-3" : "light-bg-3"}
      sx={{
        px: "3px",
        py: "2px",
        width: "max-content",
        cursor: "pointer",
        backgroundColor: `${stc(data?.tag)}`,
      }}
      onClick={onSelect}
    >
      <Typography
        fontSize="12px"
        sx={{
          color: "#fff",
        }}
      >
        {data.tag}
      </Typography>
    </Stack>
  );
};
export default Index;
