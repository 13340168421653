import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider, Container, CssBaseline } from "@mui/material";
import { darkTheme, lightTheme } from "./theme";
import Dimension from "./components/dimensions/Dimension";
import Routes from "./routes";
import "./app.css";
import "aos/dist/aos.css";
import "locomotive-scroll/dist/locomotive-scroll.css";
import ScrollToTop from "./utils/scrollToTop";
import Aos from "aos";
import Scrollbar from "smooth-scrollbar";
import OverscrollPlugin from "smooth-scrollbar/plugins/overscroll";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { dark, light } from "./redux/darkmode/modeSlice";
function App() {
  let store = useSelector((store) => store);
  const theme = useTheme();
  const dispatch = useDispatch();
  const isWeb = useMediaQuery(theme.breakpoints.up("sm"));
  useEffect(() => {
    Aos.init({
      duration: 1000,
      // disable: window.innerWidth < 768,
    });
  }, []);

  useEffect(() => {
    if (localStorage.getItem("darkmode") === "false") {
      dispatch(light());
      localStorage.setItem("darkmode", false);
    } else {
      dispatch(dark());
      localStorage.setItem("darkmode", true);
    }
  }, [localStorage]);

  return (
    <ThemeProvider theme={store.mode.mode ? darkTheme : lightTheme}>
      <CssBaseline />
      {/* <Dimension /> */}

      <BrowserRouter>
        <ScrollToTop>
          <Routes />
        </ScrollToTop>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
