import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddTask from "./AddTask";
import AddOptions from "./AddOptions";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useSearchParams } from "react-router-dom";
import { tasksAPI } from "../../../redux/tasks/tasks/tasksSlice";
import Task from "./Task";
import EditTask from "./Task/EditTask";
import CreateGroup from "../LeftBlock/Tabs/Groups/CreateGroup";
import CreateList from "../LeftBlock/CreateList";
import CreateTask from "./AddTask";
import toTitleCase from "../../../utils/toTitleCase";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import UpcomingIcon from "@mui/icons-material/Upcoming";
import AlarmOffIcon from "@mui/icons-material/AlarmOff";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TodayIcon from "@mui/icons-material/Today";
import CloseIcon from "@mui/icons-material/Close";
const Index = () => {
  const mode = useSelector((store) => store.mode.mode);
  const dimension = useSelector((store) => store.dimension);
  const tasks = useSelector((store) => store.tasks.tasks);
  const navigate = useNavigate();
  const groups = useSelector((store) => store.groups.groups);
  const lists = useSelector((store) => store.lists.lists);
  const grouplists = useSelector((store) => store.grouplists.grouplists);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selectedTask, setSelectedTask] = useState("");
  const style = {
    position: "absolute",
    bottom: "60px",
    right: "100px",
    outline: "none",
    width: "250px",
    margin: "auto",
    border: "none",
  };
  const styleMobile = {
    position: "absolute",
    bottom: "100px",
    left: "16px",
    outline: "none",
    width: "250px",
    margin: "auto",
    border: "none",
  };

  // useEffect(() => {
  //   let data = {};
  //   if (searchParams.get("list")) {
  //     data["list"] = searchParams.get("list");
  //   }
  //   if (searchParams.get("filter")) {
  //     data["filter"] = searchParams.get("filter");
  //   }
  //   dispatch(tasksAPI(data));
  // }, [searchParams.get("filter"), searchParams.get("list")]);

  const [taskOpen, setTaskOpen] = React.useState(false);
  const handleOpenTask = () => setTaskOpen(true);
  const handleCloseTask = () => setTaskOpen(false);

  const [listOpen, setListOpen] = React.useState(false);
  const handleOpenList = () => setListOpen(true);
  const handleCloseList = () => setListOpen(false);

  const [groupOpen, setGroupOpen] = React.useState(false);
  const handleOpenGroup = () => setGroupOpen(true);
  const handleCloseGroup = () => setGroupOpen(false);

  const styleGroup = {
    position: "absolute",
    bottom: "60px",
    right: "60px",
    outline: "none",
    width: "500px",
    margin: "auto",
    border: "none",
  };
  const styleGroupMobile = {
    position: "absolute",
    bottom: "100px",
    left: "16px",
    right: "16px",
    outline: "none",
    width: "auto",
    margin: "auto",
    border: "none",
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          p: { sm: 2 },
          height: "100%",
          position: "relative",
        }}
      >
        <Modal
          open={groupOpen}
          onClose={handleCloseGroup}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={dimension.width > 600 ? styleGroup : styleGroupMobile}
            className={mode ? "dark-bg-1" : "light-bg-2"}
          >
            <CreateGroup onClose={handleCloseGroup} />
          </Box>
        </Modal>
        <Modal
          open={listOpen}
          onClose={handleCloseList}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={dimension.width > 600 ? styleGroup : styleGroupMobile}
            className={mode ? "dark-bg-1" : "light-bg-2"}
          >
            <CreateList onClose={handleCloseList} />
          </Box>
        </Modal>
        <Modal
          open={taskOpen}
          onClose={handleCloseTask}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={dimension.width > 600 ? styleGroup : styleGroupMobile}
            className={mode ? "dark-bg-1" : "light-bg-2"}
          >
            <CreateTask onClose={handleCloseTask} />
          </Box>
        </Modal>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={dimension.width > 600 ? style : styleMobile}
            className={mode ? "dark-bg-1" : "light-bg-2"}
          >
            <AddOptions
              onClose={handleClose}
              handleOpenGroup={handleOpenGroup}
              handleOpenList={handleOpenList}
              handleOpenTask={handleOpenTask}
            />
          </Box>
        </Modal>
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent="space-between"
          sx={{
            width: "100%",
          }}
        >
          <>
            {searchParams.get("filter") && (
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                className={mode ? "light-1" : "dark-8"}
                sx={{
                  cursor: "pointer",
                  width: "max-content",
                }}
              >
                {searchParams.get("filter") === "inbox" && <MailOutlineIcon />}
                {searchParams.get("filter") === "today" && <TodayIcon />}
                {searchParams.get("filter") === "upcoming" && <UpcomingIcon />}
                {searchParams.get("filter") === "past" && <AlarmOffIcon />}
                {searchParams.get("filter") === "anytime" && <AccessTimeIcon />}
                {searchParams.get("filter") === "completed" && <DoneAllIcon />}
                <Typography fontSize="16px" fontWeight="500">
                  {toTitleCase(searchParams.get("filter"))}
                </Typography>
              </Stack>
            )}
            <Stack direction="row" spacing={1}>
              {searchParams.get("group") ? (
                <Typography
                  fontSize="16px"
                  fontWeight="500"
                  className={mode ? "dark-2" : "light-2"}
                >
                  {
                    groups.filter(
                      (item) => item._id == searchParams.get("group")
                    )[0]?.name
                  }
                  {searchParams.get("list") &&
                    " ⮞ " +
                      grouplists.filter(
                        (item) => item._id == searchParams.get("list")
                      )[0]?.name}
                </Typography>
              ) : (
                <>
                  {searchParams.get("list") && (
                    <Typography
                      fontSize="16px"
                      fontWeight="500"
                      className={mode ? "dark-2" : "light-2"}
                    >
                      {
                        lists.filter(
                          (item) => item._id == searchParams.get("list")
                        )[0]?.name
                      }
                    </Typography>
                  )}
                </>
              )}
            </Stack>
          </>
          {dimension.width > 600 && (
            <CloseIcon
              className={mode ? "dark-4" : "light-4"}
              sx={{
                cursor: "pointer",
              }}
              onClick={() => {
                if (searchParams.get("entry")) {
                  navigate(`${searchParams.get("entry")}`);
                } else {
                  navigate(`/`);
                }
              }}
            />
          )}
        </Stack>

        {dimension.width > 600 ? (
          <Button
            variant="contained"
            color="primary"
            sx={{
              width: "100px",
              position: "absolute",
              bottom: "20px",
              right: "20px",
              py: "15px",
              px: "10px",
              zIndex: "1000",
            }}
            startIcon={<AddIcon />}
            onClick={handleOpen}
          >
            New
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            sx={{
              width: "100px",
              position: "absolute",
              bottom: "60px",
              left: "0px",
              py: "15px",
              px: "10px",
              zIndex: "1000",
            }}
            startIcon={<AddIcon />}
            onClick={handleOpen}
          >
            New
          </Button>
        )}

        <Stack
          className="thin-scrollbar"
          direction="column"
          spacing={2}
          sx={{
            pt: 2,
            pr: { sm: 2 },
            height: "calc(100% - 40px)",
            overflowY: "auto",
          }}
        >
          {tasks &&
            tasks?.length > 0 &&
            tasks.map((item) => (
              <>
                {item._id === selectedTask ? (
                  <EditTask
                    selectedTask={selectedTask}
                    setSelectedTask={setSelectedTask}
                    data={item}
                  />
                ) : (
                  <Task
                    selectedTask={selectedTask}
                    setSelectedTask={setSelectedTask}
                    data={item}
                  />
                )}
              </>
            ))}
          {(searchParams.get("filter") == "inbox" ||
            searchParams.get("list")) && (
            <Stack
              direction="row"
              spacing={1}
              sx={{
                p: 1,
                border: `1px solid #77788c`,

                cursor: "pointer",
              }}
              onClick={handleOpenTask}
            >
              <AddIcon />
              <Typography fontSize="14px">Add New Task</Typography>
            </Stack>
          )}
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default Index;
