import {
  Box,
  InputAdornment,
  TextField,
  Stack,
  Divider,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import Tabs from "./Tabs";
import { useDispatch, useSelector } from "react-redux";
import {
  tasksAPI,
  tasksByListAPI,
} from "../../../redux/tasks/tasks/tasksSlice";
import { useSearchParams } from "react-router-dom";
const Index = () => {
  const [search, setSearch] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const mode = useSelector((store) => store.mode.mode);
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };
  useEffect(() => {
    if (query?.length > 0) {
      let data = {};

      data["search"] = query;
      dispatch(tasksAPI(data));
    } else {
      if (searchParams.get("filter")) {
        let data = {};
        data["filter"] = searchParams.get("filter");
        dispatch(tasksAPI(data));
      }
      if (searchParams.get("list")) {
        let data = {};
        data["id"] = searchParams.get("list");
        dispatch(tasksByListAPI(data));
      }
    }
  }, [query, searchParams.get("filter"), searchParams.get("list")]);

  const searchUpdate = debounce(function (e) {
    setQuery(e.target.value);
  }, 1000);
  useEffect(() => {
    if (query.length > 0) {
    }
  }, [query]);
  return (
    <React.Fragment>
      <Stack
        sx={{
          width: "100%",
          px: { sm: 2 },
          // pt: 4,
          height: "100%",
        }}
      >
        <Stack
          spacing={1}
          sx={{
            pb: 2,
          }}
        >
          <Typography
            className={mode ? "dark-4" : "light-4"}
            variant="h5"
            sx={{
              textTransform: "uppercase",
            }}
          >
            Tasks
          </Typography>

          <TextField
            autoComplete="off"
            value={search}
            fullWidth
            style={{
              border: `1px solid ${mode ? "#77788C" : "77788C"}`,
            }}
            onChange={(e) => {
              setSearch(e.target.value);
              searchUpdate(e);
            }}
            size="small"
            placeholder="Search here"
            InputProps={{
              style: {
                height: "36px",
              },
              endAdornment: (
                <InputAdornment position="end">
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    divider={<Divider orientation="vertical" flexItem />}
                  >
                    {search?.length > 0 && (
                      <CloseIcon
                        sx={{
                          color: "gray",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          dispatch(setSearch(""));
                          setQuery("");
                        }}
                      />
                    )}

                    <SearchIcon />
                  </Stack>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Tabs />
      </Stack>
    </React.Fragment>
  );
};

export default Index;
