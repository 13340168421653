import {
  Grid,
  Box,
  Stack,
  Typography,
  InputAdornment,
  TextField,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axiosApi from "../../utils/httpClient";
import NotePreview from "./NotePreview";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchNotes,
  newNote,
  searchNotes,
} from "../../redux/notes/fetchNotes/fetchNotesSlice";
import { selectNote } from "../../redux/notes/selectNote/selectNoteSlice";
import ContentBlock from "./ContentBlock";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
const Index = ({ onClose }) => {
  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  const mode = useSelector((store) => store.mode.mode);
  const dimension = useSelector((store) => store.dimension);
  const note = useSelector((store) => store.note.note);
  const [query, setQuery] = useState("");
  const notes = useSelector((store) => store.notes.notes);
  const notesFiltered = useSelector((store) => store.notes.filtered);
  const createNewNote = async () => {
    const response = await axiosApi.post(
      `${process.env.REACT_APP_BASE_URL}note`,
      {
        title: "New Note",
        note: "",
      }
    );
    await dispatch(newNote({ result: response?.data?.data }));
    await dispatch(selectNote(response?.data?.data?._id));
  };
  useEffect(() => {
    dispatch(fetchNotes());
  }, []);
  useEffect(() => {
    if (notes) {
      if (!note) {
        if (dimension.width > 600) {
          dispatch(selectNote(notes[0]?._id));
        }
      }
    }
  }, [note, notes]);
  useEffect(() => {
    dispatch(searchNotes({ query: query }));
  }, [query]);
  const LeftBlock = () => {
    return (
      <Box
        className={mode ? "dark-bg-1" : "light-bg-1"}
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <Stack
          direction="row"
          justifyContent={"space-between"}
          sx={{
            px: 2,
            pt: 2,
          }}
        >
          <Typography
            className={mode ? "dark-4" : "light-4"}
            variant="h5"
            sx={{
              textTransform: "uppercase",
            }}
          >
            Notes
          </Typography>
          <Typography
            fontWeight={"500"}
            fontSize="24px"
            color="primary"
            sx={{
              cursor: "pointer",
            }}
            onClick={createNewNote}
          >
            <AddBoxOutlinedIcon />
          </Typography>
        </Stack>
        <Box px={2} py={1}>
          <TextField
            onChange={(e) => {
              setQuery(e.target.value);
            }}
            value={query}
            fullWidth
            style={{
              border: `1px solid ${mode ? "#77788C" : "77788C"}`,

              marginTop: "1rem",
              marginBottom: "0.5rem",
            }}
            size="small"
            placeholder="Search here"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    divider={<Divider orientation="vertical" flexItem />}
                  >
                    {query?.length > 0 && (
                      <CloseIcon
                        sx={{
                          color: "gray",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          setQuery("");
                        }}
                      />
                    )}

                    <SearchIcon />
                  </Stack>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Stack
          className="thin-scrollbar"
          direction="column"
          style={{
            height: "calc(100% - 140px)",
            overflowY: "auto",
          }}
          spacing={1}
          divider={<Divider orientation="horizontal" />}
        >
          {notesFiltered &&
            notesFiltered?.length > 0 &&
            notesFiltered
              .slice(0)
              .reverse()
              .map((item) => (
                <NotePreview
                  title={item?.title}
                  content={item?.note}
                  id={item._id}
                />
              ))}
        </Stack>
      </Box>
    );
  };
  return (
    <React.Fragment>
      {dimension.width > 600 ? (
        <Grid
          mt={15}
          container
          direction="row"
          style={{
            height: "calc(100vh - 150px)",
          }}
        >
          <Grid
            item
            xs={3}
            sx={{
              height: "100%",
            }}
          >
            <LeftBlock />
          </Grid>
          <Grid
            item
            xs={9}
            sx={{
              height: "100%",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                background: mode ? "#333345" : "#f1f1f4",
              }}
            >
              <ContentBlock onClose={onClose} />
            </Box>
          </Grid>
        </Grid>
      ) : (
        <>
          <Box
            pt={2}
            style={{
              height: "calc(100vh - 150px)",
            }}
          >
            {note ? (
              <>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  sx={{
                    pb: 3,
                  }}
                >
                  <KeyboardBackspaceIcon
                    onClick={() => {
                      dispatch(selectNote(""));
                    }}
                  />
                  <Typography color="secondary" fontSize="20px">
                    Menu
                  </Typography>
                </Stack>
                <ContentBlock />
              </>
            ) : (
              <>
                <LeftBlock />
              </>
            )}
          </Box>
        </>
      )}
    </React.Fragment>
  );
};

export default Index;
