import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Outlet } from "react-router-dom";
import HeaderNav from "../HeaderNav";
import BottomNav from "../BottomNav";
import MobileSidebar from "../MobileSidebar";
import {
  Container,
  Stack,
  Typography,
  Modal,
  Box,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import JoinInnerIcon from "@mui/icons-material/JoinInner";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import AddIcon from "@mui/icons-material/Add";
import { profileAPI } from "../../redux/auth/profile/profileSlice";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import MDEditor, { commands } from "@uiw/react-md-editor";
import { Autosave, useAutosave } from "react-autosave";
import axiosApi from "../../utils/httpClient";
import Notes from "../../components/Notes";
import Match from "../../components/Match";
import Tasks from "../../components/Tasks";
function MainLayout() {
  let store = useSelector((store) => store);
  const [note, setNote] = React.useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [noteId, setNoteId] = useState(null);
  const profile = useSelector((store) => store.profile);
  const [newNote, setNewNote] = useState(false);
  const signin = useSelector((store) => store.signin);
  const dispatch = useDispatch();
  const mode = useSelector((store) => store.mode.mode);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openFloatOptions, setOpenFloatOptions] = useState(false);
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  // useEffect(() => {
  //   if (profile.success) {
  //     setCookie("userdata", profile.data);
  //   } else {
  //     if (profile.error) {
  //
  //     }
  //   }
  // }, [profile]);
  useEffect(() => {
    setOpenFloatOptions(false);
  }, [location]);

  const getNotes = async () => {
    try {
      const response = await axiosApi.get(
        `${process.env.REACT_APP_BASE_URL}note`
      );

      if (response?.data?.data) {
        await setNoteId(response?.data?.data?._id);
        await setNote(response?.data?.data?.note);
      } else {
        await setNewNote(true);
      }
    } catch (error) {}
  };
  const postNotes = async () => {
    if (newNote) {
      try {
        const response = await axiosApi.post(
          `${process.env.REACT_APP_BASE_URL}note`,
          {
            note: note,
          }
        );

        setNewNote(false);
      } catch (error) {}
    } else {
      try {
        const response = await axiosApi.patch(
          `${process.env.REACT_APP_BASE_URL}note/${noteId}`,
          {
            note: note,
          }
        );
      } catch (error) {}
    }
  };
  // useAutosave({ data: note, onSave: postNotes });
  useEffect(() => {
    getNotes();
  }, []);
  useEffect(() => {
    if (note) {
      postNotes();
    }
  }, [note]);
  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(profileAPI());
    }
  }, [localStorage]);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
    width: "1200px",
    height: "500px",
    maxHeight: "500px",
    margin: "auto",
    border: "none",
  };

  const style2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
    width: "1200px",
    height: "600px",
    maxHeight: "600px",
    margin: "auto",
    border: "none",
  };

  const ShowNavbar = () => {
    const paths = [
      "myprofile",
      "my-events",
      "create-event",
      "edit-event",
      "my-event-detail",
      "my-jobs",
      "create-job",
      "edit-job",
      "my-job-detail",
      "analytics",
      "bookmarks",
      "preferences",
      "settings",
    ];
    if (paths.some((v) => location.pathname.includes(v))) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Stack direction="column">
          {store.dimension.width >= 600 && ShowNavbar() && <HeaderNav />}
          {store.dimension.width < 600 && localStorage.getItem("token") && (
            <MobileSidebar />
          )}
          {store.dimension.width < 600 && localStorage.getItem("token") && (
            <BottomNav />
          )}
          <Outlet />
        </Stack>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="dark-bg-1">
            <Notes onClose={handleClose} />
          </Box>
        </Modal>
        <Modal
          open={open1}
          onClose={handleClose1}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className={mode ? "dark-bg-1" : "light-bg-2"}>
            <Match onClose={handleClose1} />
          </Box>
        </Modal>
        <Modal
          open={open2}
          onClose={handleClose2}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style2} className={mode ? "dark-bg-1" : "light-bg-2"}>
            <Tasks onClose={handleClose2} />
          </Box>
        </Modal>
      </Container>
      {ShowNavbar() && (
        <>
          {store.dimension.width > 600 ? (
            <>
              {" "}
              {(profile?.data?.company?.status === "approved" ||
                profile?.data?.individual?.status === "approved" ||
                profile?.data?.institution?.status === "approved" ||
                profile?.data?.mentor?.status === "approved" ||
                profile?.data?.expert?.status === "approved") && (
                <div
                  style={{
                    position: "fixed",
                    bottom: "50px",
                    left: "30px",
                  }}
                >
                  <Stack spacing={3}>
                    <Box
                      sx={{
                        height: "40px",
                        width: "40px",
                        background: "#ee4b6a",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <TaskAltIcon
                        sx={{
                          cursor: "pointer",
                          color: "#fff",
                        }}
                        onClick={() => {
                          getNotes();
                          if (!searchParams.get("entry")) {
                            navigate(
                              `/tasks?filter=inbox&entry=${location.pathname}${location.search}`
                            );
                          } else {
                            navigate(
                              `/tasks?filter=inbox&entry=${searchParams.get(
                                "entry"
                              )}`
                            );
                          }
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        height: "40px",
                        width: "40px",
                        background: "#ee4b6a",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <JoinInnerIcon
                        sx={{
                          cursor: "pointer",
                          color: "#fff",
                        }}
                        onClick={() => {
                          getNotes();
                          if (!searchParams.get("entry")) {
                            navigate(
                              `/match?entry=${location.pathname}${location.search}`
                            );
                          } else {
                            navigate(
                              `/match?entry=${searchParams.get("entry")}`
                            );
                          }
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        height: "40px",
                        width: "40px",
                        background: "#ee4b6a",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <EditIcon
                        sx={{
                          cursor: "pointer",
                          color: "#fff",
                        }}
                        onClick={() => {
                          getNotes();
                          if (!searchParams.get("entry")) {
                            navigate(
                              `/notes?entry=${location.pathname}${location.search}`
                            );
                          } else {
                            navigate(
                              `/notes?entry=${searchParams.get("entry")}`
                            );
                          }
                        }}
                      />
                    </Box>
                  </Stack>
                </div>
              )}
            </>
          ) : (
            <>
              {" "}
              {(profile?.data?.company?.status === "approved" ||
                profile?.data?.individual?.status === "approved" ||
                profile?.data?.institution?.status === "approved" ||
                profile?.data?.mentor?.status === "approved" ||
                profile?.data?.expert?.status === "approved") && (
                <div
                  style={{
                    position: "fixed",
                    bottom: "100px",
                    right: "16px",
                  }}
                >
                  {openFloatOptions ? (
                    <Stack spacing={3}>
                      <Box
                        sx={{
                          height: "40px",
                          width: "40px",
                          background: "#ee4b6a",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <TaskAltIcon
                          sx={{
                            cursor: "pointer",
                            color: "#fff",
                          }}
                          onClick={() => {
                            setOpenFloatOptions(false);
                            getNotes();
                            if (!searchParams.get("entry")) {
                              navigate(
                                `/tasks?&entry=${location.pathname}${location.search}`
                              );
                            } else {
                              navigate(
                                `/tasks?&entry=${searchParams.get("entry")}`
                              );
                            }
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          height: "40px",
                          width: "40px",
                          background: "#ee4b6a",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <JoinInnerIcon
                          sx={{
                            cursor: "pointer",
                            color: "#fff",
                          }}
                          onClick={() => {
                            setOpenFloatOptions(false);
                            getNotes();
                            if (!searchParams.get("entry")) {
                              navigate(
                                `/match?entry=${location.pathname}${location.search}`
                              );
                            } else {
                              navigate(
                                `/match?entry=${searchParams.get("entry")}`
                              );
                            }
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          height: "40px",
                          width: "40px",
                          background: "#ee4b6a",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <EditIcon
                          sx={{
                            cursor: "pointer",
                            color: "#fff",
                          }}
                          onClick={() => {
                            setOpenFloatOptions(false);
                            getNotes();
                            if (!searchParams.get("entry")) {
                              navigate(
                                `/notes?entry=${location.pathname}${location.search}`
                              );
                            } else {
                              navigate(
                                `/notes?entry=${searchParams.get("entry")}`
                              );
                            }
                          }}
                        />
                      </Box>
                    </Stack>
                  ) : (
                    <Box
                      sx={{
                        height: "40px",
                        width: "40px",
                        background: "#ee4b6a",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {location.pathname.includes("match") ||
                      location.pathname.includes("notes") ||
                      location.pathname.includes("tasks") ? (
                        <>
                          {location.pathname.includes("tasks") && (
                            <TaskAltIcon
                              sx={{
                                cursor: "pointer",
                                color: "#fff",
                              }}
                              onClick={() => {
                                setOpenFloatOptions(true);
                              }}
                            />
                          )}
                          {location.pathname.includes("notes") && (
                            <EditIcon
                              sx={{
                                cursor: "pointer",
                                color: "#fff",
                              }}
                              onClick={() => {
                                setOpenFloatOptions(true);
                              }}
                            />
                          )}
                          {location.pathname.includes("match") && (
                            <JoinInnerIcon
                              sx={{
                                cursor: "pointer",
                                color: "#fff",
                              }}
                              onClick={() => {
                                setOpenFloatOptions(true);
                              }}
                            />
                          )}
                        </>
                      ) : (
                        <AddIcon
                          sx={{
                            cursor: "pointer",
                            color: "#fff",
                          }}
                          onClick={() => {
                            setOpenFloatOptions(true);
                          }}
                        />
                      )}
                    </Box>
                  )}
                </div>
              )}
            </>
          )}
        </>
      )}
    </React.Fragment>
  );
}
export default MainLayout;
