import { Icon, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import stc from "string-to-color";
const Index = ({ data, removeTag }) => {
  const mode = useSelector((store) => store.mode.mode);
  return (
    <Stack
      className={mode ? "dark-bg-3" : "light-bg-3"}
      direction="row"
      alignItems={"center"}
      sx={{
        px: "5px",
        py: "3px",
        width: "max-content",
        cursor: "pointer",
        backgroundColor: `${stc(data.tag)}`,
        color: "#fff",
      }}
    >
      <Typography fontSize="12px">{data.tag}</Typography>

      <CloseIcon fontSize="8px" onClick={removeTag} />
    </Stack>
  );
};
export default Index;
