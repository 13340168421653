import { Box, Modal, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CreateList from "../../CreateList";
import { useSelector } from "react-redux";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { useSearchParams } from "react-router-dom";
import List from "../../List";
const Index = () => {
  const mode = useSelector((store) => store.mode.mode);
  const lists = useSelector((store) => store.lists.lists);
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(1);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const tabs = [
    {
      id: 0,

      title: "Social Media - Instagram",
    },
    {
      id: 1,
      title: "Business Development",
    },
  ];
  const style = {
    position: "absolute",
    bottom: "60px",
    right: "60px",
    outline: "none",
    width: "500px",
    margin: "auto",
    border: "none",
  };
  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={mode ? "dark-bg-1" : "light-bg-2"}>
          <CreateList onClose={handleClose} />
        </Box>
      </Modal>
      <Stack direction="column" spacing={2}>
        <Stack
          direction="row"
          justifyContent={"space-between"}
          sx={{
            width: "100%",
          }}
          className={mode ? "light-1" : "dark-9"}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <FormatListBulletedIcon />
            <Typography fontSize="14px">LISTS</Typography>
          </Stack>
          <AddIcon
            onClick={handleOpen}
            sx={{
              cursor: "pointer",
            }}
          />
        </Stack>
        <Stack direction="column" spacing={2}>
          {lists &&
            lists.length > 0 &&
            lists.map((item) => (
              // <Stack direction="column" spacing={2}>
              //   <Stack
              //     key={item._id}
              //     direction="row"
              //     spacing={2}
              //     alignItems="center"
              //     justifyContent={"space-between"}
              //     className={
              //       mode
              //         ? item._id == searchParams.get("list")
              //           ? "light-1"
              //           : "light-2"
              //         : item._id == searchParams.get("list")
              //         ? "dark-8"
              //         : "dark-2"
              //     }
              //     sx={{
              //       cursor: "pointer",
              //       width: "100%",
              //     }}
              //     onClick={() => {
              //       if (searchParams.get("filter")) {
              //         searchParams.delete("filter");
              //       }
              //       if (searchParams.get("group")) {
              //         searchParams.delete("group");
              //       }
              //       if (searchParams.get("list") == item._id) {
              //         searchParams.delete("list");
              //         setSearchParams(searchParams);
              //       } else {
              //         if (searchParams.get("group")) {
              //           searchParams.delete("group");
              //         }
              //         searchParams.set("list", item._id);
              //         setSearchParams(searchParams);
              //       }
              //     }}
              //   >
              //     <Typography fontSize="16px">{item.name}</Typography>

              //     {/* <DeleteOutlineIcon /> */}
              //   </Stack>
              // </Stack>
              <List data={item} />
            ))}
        </Stack>
      </Stack>
    </React.Fragment>
  );
};

export default Index;
