import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const name = "selectedTimelines";
const initialState = {
  selectedTimelines: [],
  loading: false,
  error: null,
};

export const selectTimelines = createAsyncThunk(
  "timelines/selected",
  async (timelines) => {
    return timelines;
  }
);

const selectedTimelinesSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(selectTimelines.pending, (state) => {
      state.selectedTimelines = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(selectTimelines.fulfilled, (state, action) => {
      state.selectedTimelines = action.payload;
    });
    builder.addCase(selectTimelines.rejected, (state, action) => {
      state.selectedTimelines = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default selectedTimelinesSlice.reducer;
