import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as qs from "qs";
import axiosApi from "../../utils/httpClient";
const name = "sentToExpert";
const initialState = {
  sentToExpert: [],
  loading: false,
  error: null,
};

export const sentToExpertAPI = createAsyncThunk(
  "sentToExpert",
  async ({ search, skip, limit, country, status, skills, profiletype }) => {
    var formData = {};
    if (skills) {
      formData["skill"] = skills;
    }
    if (profiletype) {
      formData["profileType"] = profiletype;
    }
    if (country) {
      formData["country"] = country;
    }
    if (search) {
      formData["search"] = search;
    }
    if (status) {
      if (status == "Submited") {
        formData["status"] = "received";
      } else if (status == "Approved") {
        formData["status"] = "approved";
      } else if (status == "Rejected") {
        formData["status"] = "rejected";
      }
    }
    const data = await axiosApi.get(
      `${process.env.REACT_APP_BASE_URL}application/expert/applied/?skip=${skip}&limit=${limit}`,
      {
        params: formData,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      }
    );
    return data.data;
  }
);

const sentToExpertSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(sentToExpertAPI.pending, (state) => {
      state.sentToExpert = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(sentToExpertAPI.fulfilled, (state, action) => {
      state.sentToExpert = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(sentToExpertAPI.rejected, (state, action) => {
      state.sentToExpert = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default sentToExpertSlice.reducer;
