import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const name = "dashboardProfile";
const initialState = {
  dashboardProfile: "",
  loading: false,
  error: null,
};

export const setDashboardProfile = createAsyncThunk(
  "setDashboardProfile",
  async (profile) => {
    return profile;
  }
);

const dashboardProfileSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(setDashboardProfile.fulfilled, (state, action) => {
      state.dashboardProfile = action.payload;
      state.loading = false;
      state.error = null;
    });
  },
});

export default dashboardProfileSlice.reducer;
