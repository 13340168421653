import { createSlice } from '@reduxjs/toolkit'

export const dimensionsSlice = createSlice({
	name: 'dimension',
	initialState: {
		height: window.innerHeight,
		width: window.innerWidth
	},
	reducers: {
		height: (state, action) => {
			state.height = action.payload
		},
		width: (state, action) => {
			state.width = action.payload
		}
	}
})

export const { height, width } = dimensionsSlice.actions

export default dimensionsSlice.reducer
