import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as qs from "qs";
import axiosApi from "../../utils/httpClient";
const name = "applicationsReceived";
const initialState = {
  applicationsReceived: [],
  loading: false,
  error: null,
};

export const applicationsReceivedAPI = createAsyncThunk(
  "applicationsreceived",
  async ({ search, skip, limit, id, type }) => {
    var formData = {};

    formData["id"] = id;
    if (search) {
      formData["search"] = search;
    }

    const data = await axiosApi.get(
      `${process.env.REACT_APP_BASE_URL}application/${
        type == "Individual" ? "individual" : "institution"
      }/${id}?skip=${skip}&limit=${limit}`
    );
    return data.data;
  }
);

const applicationsReceivedSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(applicationsReceivedAPI.pending, (state) => {
      state.applicationsReceived = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(applicationsReceivedAPI.fulfilled, (state, action) => {
      state.applicationsReceived = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(applicationsReceivedAPI.rejected, (state, action) => {
      state.applicationsReceived = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default applicationsReceivedSlice.reducer;
