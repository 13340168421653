import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const name = "selectedJobCategories";
const initialState = {
  selectedJobCategories: [],
  loading: false,
  error: null,
};

export const selectJobCategories = createAsyncThunk(
  "jobcategories/selected",
  async (categories) => {
    return categories;
  }
);

const selectedJobCategoriesSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(selectJobCategories.pending, (state) => {
      state.selectedJobCategories = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(selectJobCategories.fulfilled, (state, action) => {
      state.selectedJobCategories = action.payload;
    });
    builder.addCase(selectJobCategories.rejected, (state, action) => {
      state.selectedJobCategories = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default selectedJobCategoriesSlice.reducer;
