import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as qs from "qs";
import axiosApi from "../../../utils/httpClient";
const name = "exploreProjects";
const initialState = {
  exploreProjects: [],
  loading: false,
  error: null,
};

export const exploreProjectsAPI = createAsyncThunk(
  "exploreProjects",
  async ({
    skip,
    limit,
    id,
    status,
    projectType,
    timeline,
    country,
    price,
    model,
  }) => {
    var formData = {};
    formData["id"] = id;
    formData["skip"] = skip;
    formData["model"] = model;
    formData["status"] = status;
    formData["limit"] = limit;
    if (projectType) {
      formData["projectType"] = projectType;
    }
    if (country) {
      formData["country"] = country;
    }
    if (price) {
      formData["price"] = price;
    }
    if (timeline) {
      formData["timeline"] = timeline;
    }

    const data = await axiosApi.get(
      `${process.env.REACT_APP_BASE_URL}project/`,
      {
        params: formData,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      }
    );
    return data.data;
  }
);

const exploreProjectsSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(exploreProjectsAPI.pending, (state) => {
      state.exploreProjects = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(exploreProjectsAPI.fulfilled, (state, action) => {
      state.exploreProjects = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(exploreProjectsAPI.rejected, (state, action) => {
      state.exploreProjects = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default exploreProjectsSlice.reducer;
