import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getCookie } from "./httpClient";

import { useSelector } from "react-redux";
import CompanyProtectedRoute from "./compayProtectedRoutes";
import InvestorProtectedRoute from "./investorProtectedRoutes";
import MentorProtectedRoute from "./mentorProtectedRoutes";
import ExpertProtectedRoute from "./expertProtectedRoutes";

function CompanyAccess({ children }) {
  const isAuthenticated = getCookie("token");

  const profile = useSelector((store) => store.profile);
  let location = useLocation();
  if (localStorage.getItem("profile") == "Investor") {
    return <InvestorProtectedRoute>{children}</InvestorProtectedRoute>;
  } else if (localStorage.getItem("profile") == "Mentor") {
    return <MentorProtectedRoute>{children}</MentorProtectedRoute>;
  } else if (localStorage.getItem("profile") == "Expert") {
    return <ExpertProtectedRoute>{children}</ExpertProtectedRoute>;
  }
}

export default CompanyAccess;
