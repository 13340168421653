const Index = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="30" rx="4" fill="#EE4B6A" />
      <path
        d="M15 6L4 12L8 14.18V20.18L15 24L22 20.18V14.18L24 13.09V20H26V12L15 6ZM21.82 12L15 15.72L8.18 12L15 8.28L21.82 12ZM20 18.99L15 21.72L10 18.99V15.27L15 18L20 15.27V18.99Z"
        fill="white"
      />
    </svg>
  );
};

export default Index;
