import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const name = "selectedProject";
const initialState = {
  projectName: "all",
  projectId: "all",
  loading: false,
  error: null,
};

export const selectProject = createAsyncThunk(
  "project/select",
  async ({ name, id }) => {
    return { name, id };
  }
);

const selectProjectSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(selectProject.pending, (state) => {
      // state.selectedTimelines = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(selectProject.fulfilled, (state, action) => {
      state.projectName = action.payload.name;
      state.projectId = action.payload.id;
    });
    builder.addCase(selectProject.rejected, (state, action) => {
      // state.selectedTimelines = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default selectProjectSlice.reducer;
