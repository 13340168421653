import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosApi from "../../utils/httpClient";

const name = "messages";
const initialState = {
  messages: [],
  searchresults: [],
  loading: false,
  error: null,
};

export const fetchMessages = createAsyncThunk(
  "messages/fetchMessages",
  async ({ conversation, query }) => {
    const data = await axiosApi.get(
      `${process.env.REACT_APP_BASE_URL}chat/conversations/messages/${conversation}?skip=1&limit=2000`
    );
    return data.data;
  }
);

export const searchMessages = createAsyncThunk(
  "messages/searchMessages",
  async ({ query, conversation }) => {
    const data = await axiosApi.get(
      `${process.env.REACT_APP_BASE_URL}chat/conversations/messages/${conversation}?skip=1&limit=2000&text=${query}`
    );

    return data.data;
  }
);

export const clearSearchMessages = createAsyncThunk(
  "messages/clearSearchMessages",
  async () => {
    return true;
  }
);

export const newMessage = createAsyncThunk(
  "messages/newMessage",
  async ({ result, selectedConversation }, { getState }) => {
    const state = getState();

    return { data: result, id: selectedConversation };
  }
);

export const updateDeliver = createAsyncThunk(
  "messages/updateDeliver",
  async ({ id }) => {
    return id;
  }
);
export const updateRead = createAsyncThunk(
  "messages/updateRead",
  async ({ id }) => {
    return id;
  }
);
export const updateDelete = createAsyncThunk(
  "messages/updateDelete",
  async ({ id }) => {
    return id;
  }
);
export const deleteChat = createAsyncThunk("messages/deleteChat", async () => {
  return true;
});

const messagesSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchMessages.pending, (state) => {
      state.messages = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchMessages.fulfilled, (state, action) => {
      state.messages = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(fetchMessages.rejected, (state, action) => {
      state.messages = [];
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(searchMessages.pending, (state) => {
      state.searchresults = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(searchMessages.fulfilled, (state, action) => {
      state.searchresults = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(searchMessages.rejected, (state, action) => {
      state.searchresults = [];
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(clearSearchMessages.fulfilled, (state, action) => {
      state.searchresults = [];
      state.loading = false;
      state.error = null;
    });
    builder.addCase(deleteChat.fulfilled, (state, action) => {
      state.messages = [];
      state.loading = false;
      state.error = null;
    });
    builder.addCase(newMessage.fulfilled, (state, action) => {
      if (action.payload.data.chatId === action.payload.id) {
        if (
          state.messages.messages.filter(
            (item) => item._id === action.payload.data._id
          ).length < 1
        ) {
          state.messages.messages = [
            action.payload.data,
            ,
            ...state.messages.messages,
          ];
        }
      }

      state.loading = false;
      state.error = null;
    });
    builder.addCase(updateDeliver.fulfilled, (state, action) => {
      const objIndex = state.messages.messages.findIndex(
        (obj) => obj._id == action.payload
      );
      state.messages.messages[objIndex].deliver = true;
      // state.messages.messages = [...state.messages.messages, action.payload];
      state.loading = false;
      state.error = null;
    });
    builder.addCase(updateRead.fulfilled, (state, action) => {
      const objIndex = state.messages.messages.findIndex(
        (obj) => obj._id == action.payload
      );
      state.messages.messages[objIndex].read = true;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(updateDelete.fulfilled, (state, action) => {
      const objIndex = state.messages.messages.findIndex(
        (obj) => obj._id == action.payload
      );
      state.messages.messages[objIndex].delete = true;
      state.loading = false;
      state.error = null;
    });
  },
});

export default messagesSlice.reducer;
