import { Box, Typography, Stack, Divider } from "@mui/material";
import React, { useEffect, useContext, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import getTime from "../../utils/getTime";
import axiosApi from "../../utils/httpClient";
import Skeleton from "@mui/material/Skeleton";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
const Index = ({ onClose }) => {
  const mode = useSelector((store) => store.mode.mode);
  const location = useLocation();
  const profile = useSelector((store) => store.profile);
  const [searchParams, setSearchParams] = useSearchParams();
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();

  const getNotfications = async () => {
    const resp = await axiosApi.get(`/notifications?skip=1&limit=100`);
    setNotifications(resp?.data?.data?.notifications);
  };
  useEffect(() => {
    getNotfications();
  }, []);

  const getType = (model) => {
    if (model == "Company") {
      return "Companies";
    } else if (model === "Expert") {
      return "Experts";
    } else if (model === "Individual") {
      return "Investors";
    } else if (model === "Mentor") {
      return "Mentors";
    }
  };

  const redirectTo = (data) => {
    return (
      `/dashboard/${
        data?.notification?.includes("your") ? "sent" : "received"
      }?type=` + getType(data?.from?.model)
    );
  };

  return (
    <React.Fragment>
      <Box className={mode ? "dark-bg-1" : "light-bg-1"} pt={1}>
        {notifications?.length > 0 ? (
          <>
            <Stack
              className={mode ? "dark-bg-1" : "light-bg-1"}
              spacing={2}
              sx={{
                width: { sm: "300px" },
                p: 1,
              }}
            >
              <>
                {notifications &&
                  notifications.slice(0, 10).map((notification) => (
                    <Stack spacing={2}>
                      <Stack
                        onClick={() => {
                          navigate(`${redirectTo(notification)}`);
                        }}
                        direction="row"
                        spacing={2}
                        justifyContent={"space-between"}
                        alignItems={"baseline"}
                        sx={{
                          cursor: "pointer",
                        }}
                      >
                        <Typography fontSize="14px" color="secondary">
                          {notification?.notification}
                        </Typography>
                        <Typography
                          fontSize="12px"
                          className={mode ? "dark-2" : "light-2"}
                        >
                          {getTime(new Date(notification?.updatedAt))}
                        </Typography>
                      </Stack>
                      <Divider orientation="horizontal" />
                    </Stack>
                  ))}
              </>
            </Stack>
            {notifications?.length > 0 && (
              <Typography
                className={mode ? "dark-bg-2" : "light-bg-2"}
                align="center"
                color="secondary"
                sx={{
                  cursor: "pointer",
                  py: 1,
                }}
                onClick={() => {
                  onClose();
                  if (!searchParams.get("entry")) {
                    navigate(
                      `/notifications?entry=${location.pathname}${location.search}`
                    );
                  } else {
                    navigate(
                      `/notifications?entry=${searchParams.get("entry")}`
                    );
                  }
                }}
              >
                View All
              </Typography>
            )}
          </>
        ) : (
          <Stack
            className={mode ? "dark-bg-1" : "light-bg-1"}
            spacing={1}
            sx={{
              width: { sm: "300px" },
            }}
          >
            <Skeleton variant="rectangular" width={"300px"} height={"30px"} />
            <Skeleton variant="rectangular" width={"300px"} height={"30px"} />
            <Skeleton variant="rectangular" width={"300px"} height={"30px"} />
          </Stack>
        )}
      </Box>
    </React.Fragment>
  );
};

export default Index;
