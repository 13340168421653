function getRelativeDate(date) {
  // Get the current date and time
  var currentDate = new Date();

  // Calculate the number of milliseconds in one day
  var oneDayInMilliseconds = 24 * 60 * 60 * 1000;

  // Calculate the difference in milliseconds between the input date and the current date
  var differenceInMilliseconds = date.getTime() - currentDate.getTime();

  // If the difference is less than one day in milliseconds, return "today"
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var month = months[date.getMonth()];
  var day = date.getDate();
  var year = date.getFullYear();
  if (differenceInMilliseconds < 0) {
    return month + " " + day + ", " + year;
  } else if (differenceInMilliseconds < oneDayInMilliseconds) {
    return "Today";
  }
  // If the difference is more than one day in milliseconds but less than two days, return "tomorrow"
  else if (
    differenceInMilliseconds >= oneDayInMilliseconds &&
    differenceInMilliseconds < 2 * oneDayInMilliseconds
  ) {
    return "Tomorrow";
  }
  // If the difference is more than two days in milliseconds but less than three days, return "yesterday"
  else if (
    differenceInMilliseconds >= 2 * oneDayInMilliseconds &&
    differenceInMilliseconds < 3 * oneDayInMilliseconds
  ) {
    return "Yesterday";
  }
  // Otherwise, return the date in the format "Month Day, Year"
  else {
    return month + " " + day + ", " + year;
  }
}

export default getRelativeDate;
