import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const name = "jobtype";
const initialState = {
  jobtype: "",
  loading: false,
  error: null,
};

export const setJobType = createAsyncThunk("jobtype", async (jobtype) => {
  return jobtype;
});

const jobtypeSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(setJobType.fulfilled, (state, action) => {
      state.jobtype = action.payload;
      state.loading = false;
      state.error = null;
    });
  },
});

export default jobtypeSlice.reducer;
