import { Box, Button, Icon, Modal, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import CreateGroup from "./CreateGroup";
import CreateList from "../../CreateList";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Group from "./Group";
const Index = () => {
  const mode = useSelector((store) => store.mode.mode);
  const [searchParams, setSearchParams] = useSearchParams();
  const groups = useSelector((store) => store.groups.groups);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const style = {
    position: "absolute",
    bottom: "60px",
    right: "60px",
    outline: "none",
    width: "500px",
    margin: "auto",
    border: "none",
  };
  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={mode ? "dark-bg-1" : "light-bg-2"}>
          <CreateGroup onClose={handleClose} />
        </Box>
      </Modal>

      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={mode ? "dark-bg-1" : "light-bg-2"}>
          <CreateList onClose={handleClose1} />
        </Box>
      </Modal>
      <Stack direction="column" spacing={2}>
        <Stack
          direction="row"
          justifyContent={"space-between"}
          sx={{
            width: "100%",
          }}
          className={mode ? "light-1" : "dark-9"}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <GroupWorkIcon />
            <Typography fontSize="14px">GROUPS</Typography>
          </Stack>

          <AddIcon
            onClick={handleOpen}
            sx={{
              cursor: "pointer",
            }}
          />
        </Stack>
        {groups && groups?.length > 0 && (
          <Stack direction="column" spacing={2}>
            {groups &&
              groups?.length > 0 &&
              groups.map((item) => (
                <Group data={item} handleOpen1={handleOpen1} />
              ))}
          </Stack>
        )}
      </Stack>
    </React.Fragment>
  );
};

export default Index;
