import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axiosApi from "../../../utils/httpClient";
import { Stack, Typography, Modal, Box } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ConfirmationPopup from "../../ConfirmationsPopup";
import {
  fetchNotes,
  updateNoteAction,
} from "../../../redux/notes/fetchNotes/fetchNotesSlice";
import { selectNote } from "../../../redux/notes/selectNote/selectNoteSlice";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useSearchParams } from "react-router-dom";

const Index = ({ onClose }) => {
  const [value, setValue] = useState("");
  const [updateValue, setUpdateValue] = useState("");
  const mode = useSelector((store) => store.mode.mode);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [openModal, setOpenModal] = useState(false);
  const closeModal = () => setOpenModal(false);
  const note = useSelector((store) => store.note.note);
  const notes = useSelector((store) => store.notes.notes);
  const [title, setTitle] = useState("");
  const [defaultValue, setDefaultValue] = useState("");
  const getNoteDetails = async () => {
    if (note) {
      const response = await axiosApi.get(
        `${process.env.REACT_APP_BASE_URL}note/${note}`
      );
      setValue(response?.data?.data?.note);
      setUpdateValue(response?.data?.data?.note);
      setDefaultValue(response?.data?.data?.note);
      setTitle(response?.data?.data?.title);
    }
  };

  const updateNote = async () => {
    const response = await axiosApi.patch(
      `${process.env.REACT_APP_BASE_URL}note/${note}`,
      {
        title: title,
        note: value,
      }
    );
    await dispatch(updateNoteAction({ data: response?.data?.data }));
  };
  const deleteNote = async () => {
    const response = await axiosApi.delete(
      `${process.env.REACT_APP_BASE_URL}note/${note}`
    );
    await selectNote("");
    await dispatch(fetchNotes());
    if (notes?.length > 0) {
      await dispatch(selectNote(notes[0]._id));
    }
  };
  useEffect(() => {
    getNoteDetails();
  }, [note]);
  useEffect(() => {
    if (defaultValue != value) {
      updateNote();
    }
  }, [value]);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
    width: 400,
    bgcolor: mode ? "#333345" : "#fff",
    border: "2px solid #333345",

    boxShadow: 24,
    p: 3,
  };

  return (
    <React.Fragment>
      <Modal
        open={openModal}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ConfirmationPopup
            onSubmit={deleteNote}
            onCancel={closeModal}
            message={"Do you want to Delete the Note"}
            title={"Delete Note"}
          />
        </Box>
      </Modal>
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          height: "480px",
        }}
      >
        <ReactQuill
          placeholder="Add some notes"
          theme="snow"
          value={value}
          onChange={setValue}
          // onChange={(newValue, delta, source) => {
          //   if (source === "user") {
          //     setValue(newValue);
          //   }
          // }}
          style={{
            width: "100%",
            // height: "400px",
            // overflowY: "auto",
          }}
        />
        <Typography>
          <CloseIcon
            className={mode ? "dark-4" : "light-4"}
            sx={{
              mr: 2,
              mt: 2,
              cursor: "pointer",
            }}
            onClick={() => {
              if (searchParams.get("entry")) {
                navigate(`${searchParams.get("entry")}`);
              } else {
                navigate(`/`);
              }
            }}
          />
        </Typography>
      </Stack>
    </React.Fragment>
  );
};

export default Index;
