import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import SearchLayout from "../layout/search";
import MainLayout from "../layout/main";
import LandingLayout from "../layout/landing";
import ConditionalLayout from "../layout/condlayout";
import LegalLayout from "../layout/legal";
import HeaderLessLayout from "../layout/headerless";
import StartupCreateLayout from "../components/StartupStepper";
import MentorCreateLayout from "../components/MentorStepper";
import ExpertCreateLayout from "../components/ExpertStepper";
import InvestorCreateLayout from "../components/InvestorStepper";
import InstitutionCreateLayout from "../components/InstitutionalStepper";
import SettingsLayout from "../layout/Settings";
import PovLayout from "../components/pov/layout";
import ProtectedRoute from "../utils/protectRoute";
import StartupProtectedRoute from "../utils/startupProtectedRoutes";
import CompanyProtectedRoute from "../utils/compayProtectedRoutes";
import InvestorProtectedRoute from "../utils/investorProtectedRoutes";
import ExpertProtectedRoute from "../utils/expertProtectedRoutes";
import MentorProtectedRoute from "../utils/mentorProtectedRoutes";
import MentorCreateProtectedRoutes from "../utils/mentorCreateProtectedRoutes";
import MultiStartupAccount from "../utils/MultiStartupAccount";
import MultiInvestorAccount from "../utils/MultiInvestorAccount";
import HomeRedirect from "../utils/homeRedirect";
import CompanyAccess from "../utils/companyAccess";
import EventAccess from "../utils/eventAccess";
import JobAccess from "../utils/jobAccess";
import ScrollToTop from "../utils/scrollToTop";
const Home = React.lazy(() => import("../pages/home"));
const NotFound = React.lazy(() => import("../pages/404"));
const Welcome = React.lazy(() => import("../pages/welcome"));
const LandingPage = React.lazy(() => import("../pages/landingpage"));
const Faq = React.lazy(() => import("../components/Faq"));
const Privacy = React.lazy(() => import("../components/Privacy"));
const LegalTerms = React.lazy(() => import("../components/LegalTerms"));
const CookiePolicy = React.lazy(() => import("../components/CookiePolicy"));
const Blog = React.lazy(() => import("../components/Blog"));
const Imprint = React.lazy(() => import("../components/Imprint"));
const StartupLandingPage = React.lazy(() =>
  import("../pages/landingpage/startup")
);
const InvestorLandingPage = React.lazy(() =>
  import("../pages/landingpage/investor")
);
const MentorLandingPage = React.lazy(() =>
  import("../pages/landingpage/mentor")
);
const ExpertLandingPage = React.lazy(() =>
  import("../pages/landingpage/expert")
);
const PartnershipLandingPage = React.lazy(() =>
  import("../pages/landingpage/partnership")
);
const AboutUs = React.lazy(() => import("../pages/aboutus"));
const Register = React.lazy(() => import("../pages/register"));
const VerifyOtp = React.lazy(() => import("../pages/verify-otp"));
const SignIn = React.lazy(() => import("../pages/sign-in"));
const ForgotPassword = React.lazy(() => import("../pages/forgotPassword"));
const ResetPassword = React.lazy(() => import("../pages/resetpassword"));

const StartupDetails = React.lazy(() =>
  import("../pages/startup-create/startup")
);
const BecomeMentor = React.lazy(() =>
  import("../pages/mentor-create/become-mentor")
);
const BecomeExpert = React.lazy(() =>
  import("../pages/expert-create/become-expert")
);
const MentorDetails = React.lazy(() =>
  import("../pages/mentor-create/mentor-details")
);

const MentorReview = React.lazy(() => import("../pages/mentor-create/review"));
const ExpertDetails = React.lazy(() =>
  import("../pages/expert-create/expert-details")
);
const ExpertReview = React.lazy(() => import("../pages/expert-create/review"));
const Preferences = React.lazy(() => import("../components/preferences"));
const ContactDetails = React.lazy(() =>
  import("../pages/startup-create/contact")
);
const FundingDetails = React.lazy(() =>
  import("../pages/startup-create/funding")
);
const StartupReview = React.lazy(() =>
  import("../pages/startup-create/review")
);
const TypeOfInvestor = React.lazy(() =>
  import("../pages/investor-create/type-of-investor")
);
const InvestorDetails = React.lazy(() =>
  import("../pages/investor-create/investor-details")
);
const InvestmentPlanning = React.lazy(() =>
  import("../pages/investor-create/investment-planning")
);
const InvestorReview = React.lazy(() =>
  import("../pages/investor-create/review")
);
const TypeOfInvestitution = React.lazy(() =>
  import("../pages/institution-create/type-of-institution")
);
const InstitutionDetails = React.lazy(() =>
  import("../pages/institution-create/institution-details")
);
const InstitutionContactDetails = React.lazy(() =>
  import("../pages/institution-create/contact-details")
);
const InstitutionInvestmentPlanning = React.lazy(() =>
  import("../pages/institution-create/investment-planning")
);
const InstitutionReview = React.lazy(() =>
  import("../pages/institution-create/review")
);
const StartupAcceptTerms = React.lazy(() =>
  import("../pages/accept-terms/startup")
);
const MentorAcceptTerms = React.lazy(() =>
  import("../pages/accept-terms/mentor")
);
const ExpertAcceptTerms = React.lazy(() =>
  import("../pages/accept-terms/expert")
);
const InstitutionAcceptTerms = React.lazy(() =>
  import("../pages/accept-terms/institution")
);
const IndividualAcceptTerms = React.lazy(() =>
  import("../pages/accept-terms/individual")
);
const ThankYou = React.lazy(() => import("../pages/thank-you"));
const ApplicationStage = React.lazy(() =>
  import("../components/ApplicationStage")
);
const InvestorDashboard = React.lazy(() =>
  import("../pages/dashboard/investor")
);
const StartupDashboard = React.lazy(() => import("../pages/dashboard/startup"));
const MentorDashboard = React.lazy(() => import("../pages/dashboard/mentor"));
const ExpertDashboard = React.lazy(() => import("../pages/dashboard/expert"));
const ProjectDashboard = React.lazy(() =>
  import("../pages/dashboard/projects")
);
const StartupDetail = React.lazy(() => import("../pages/startup-detail"));
const IndInvestorDetail = React.lazy(() =>
  import("../pages/investor-detail/individual")
);
const MentorDetail = React.lazy(() => import("../pages/mentor-detail"));
const SignalDetail = React.lazy(() =>
  import("../../src/components/SignalDetail")
);

const ExpertDetail = React.lazy(() => import("../pages/expert-detail"));
const InsInvestorDetail = React.lazy(() =>
  import("../pages/investor-detail/institution")
);
const MyProfile = React.lazy(() => import("../pages/myprofile"));
const AllNotifications = React.lazy(() =>
  import("../components/AllNotifications")
);
const Inbox = React.lazy(() => import("../pages/inbox"));
const UserDetails = React.lazy(() => import("../pages/settings/userdetail"));
const Notification = React.lazy(() => import("../pages/settings/notification"));
const MessageSettings = React.lazy(() =>
  import("../components/Settings/MessageSettings")
);
const Delete = React.lazy(() => import("../pages/settings/delete"));
const Help = React.lazy(() => import("../pages/settings/help"));
const Password = React.lazy(() => import("../pages/settings/password"));
const ReceivedApplications = React.lazy(() =>
  import("../pages/pov/received-applications")
);
const InterestsSent = React.lazy(() => import("../pages/pov/interests-sent"));
const Bookmarks = React.lazy(() => import("../pages/pov/bookmarks"));
const Discussion = React.lazy(() => import("../pages/Discussion/Index"));
const MarketPlace = React.lazy(() => import("../components/Marketplace"));
const MarketPlaceDetail = React.lazy(() =>
  import("../components/Marketplace/Details")
);
const JobDetail = React.lazy(() => import("../components/JobDetail"));
const MyJobDetail = React.lazy(() => import("../components/MyJobDetail"));
const EventDetail = React.lazy(() => import("../components/EventDetail"));
const MyEventDetail = React.lazy(() => import("../components/MyEventDetail"));
// const CreateProject = React.lazy(() =>
//   import("../components/Marketplace/CreateProject")
// );
const MyProject = React.lazy(() =>
  import("../components/Marketplace/MyProjectDetail")
);
const MyJobs = React.lazy(() => import("../pages/myprojects"));
const MyEvents = React.lazy(() => import("../pages/myevents"));
const ExploreJobs = React.lazy(() => import("../pages/dashboard/job"));
const ExploreEvents = React.lazy(() => import("../pages/dashboard/event"));
const Tools = React.lazy(() => import("../pages/dashboard/Tools"));
const Blogs = React.lazy(() => import("../pages/dashboard/Blogs/Index"));

const CreateJob = React.lazy(() => import("../pages/createproject"));
const CreateEvent = React.lazy(() => import("../pages/createevent"));
const EditProject = React.lazy(() => import("../pages/editproject"));
const EditJob = React.lazy(() => import("../pages/editjob"));
const EditEvent = React.lazy(() => import("../pages/editevent"));
const Tasks = React.lazy(() => import("../components/Tasks"));
const Analytics = React.lazy(() => import("../components/Analytics"));
const Match = React.lazy(() => import("../components/Match"));
const Notes = React.lazy(() => import("../components/Notes"));

const index = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={<ConditionalLayout />}>
          <Route
            index
            element={
              <React.Suspense fallback={<Loader />}>
                <HomeRedirect>
                  <LandingPage />
                </HomeRedirect>
              </React.Suspense>
            }
          />
          <Route
            path="welcome"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={<Loader />}>
                  {/* <HomeRedirect> */}
                  <Welcome />
                  {/* </HomeRedirect> */}
                </React.Suspense>
              </ProtectedRoute>
            }
          />

          <Route
            path="marketplace"
            element={
              <React.Suspense fallback={<Loader />}>
                <MarketPlace />
              </React.Suspense>
            }
          />
          <Route
            path="marketplace/detail"
            element={
              <React.Suspense fallback={<Loader />}>
                <MarketPlaceDetail />
              </React.Suspense>
            }
          />
          <Route
            path="marketplace/myproject-detail"
            element={
              <React.Suspense fallback={<Loader />}>
                <MyProject />
              </React.Suspense>
            }
          />
          <Route
            path="marketplace/create-project"
            element={
              <React.Suspense fallback={<Loader />}>
                <CreateJob />
              </React.Suspense>
            }
          />

          <Route
            path="register"
            element={
              <React.Suspense fallback={<Loader />}>
                <Register />
              </React.Suspense>
            }
          />
          <Route
            path="forgot-password"
            element={
              <React.Suspense fallback={<Loader />}>
                <ForgotPassword />
              </React.Suspense>
            }
          />
          <Route
            path="reset-password/:token"
            element={
              <React.Suspense fallback={<Loader />}>
                <ResetPassword />
              </React.Suspense>
            }
          />
          <Route
            path="verify-otp"
            element={
              <React.Suspense fallback={<Loader />}>
                <VerifyOtp />
              </React.Suspense>
            }
          />
          <Route
            path="signin"
            element={
              <React.Suspense fallback={<Loader />}>
                <SignIn />
              </React.Suspense>
            }
          />
          {/* <Route
            path="accept-terms"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={<Loader />}>
                  <AcceptTerms />
                </React.Suspense>
              </ProtectedRoute>
            }
          /> */}
          <Route
            path="myprofile"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={<Loader />}>
                  <MyProfile />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="notifications"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={<Loader />}>
                  <AllNotifications />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="inbox"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={<Loader />}>
                  <Inbox />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="tasks"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={<Loader />}>
                  <Tasks />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="analytics"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={<Loader />}>
                  <Analytics />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="notes"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={<Loader />}>
                  <Notes />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="match"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={<Loader />}>
                  <Match />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="preferences"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={<Loader />}>
                  <Preferences />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="thank-you"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={<Loader />}>
                  <ThankYou />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="application-status/:type/:id"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={<Loader />}>
                  <ApplicationStage />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="bookmarks"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={<Loader />}>
                  <Bookmarks />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="search/jobs"
            element={
              <ProtectedRoute>
                <JobAccess>
                  <React.Suspense fallback={<Loader />}>
                    <ExploreJobs />
                  </React.Suspense>
                </JobAccess>
              </ProtectedRoute>
            }
          />
          <Route
            path="search/events"
            element={
              <ProtectedRoute>
                <EventAccess>
                  <React.Suspense fallback={<Loader />}>
                    <ExploreEvents />
                  </React.Suspense>
                </EventAccess>
              </ProtectedRoute>
            }
          />
          <Route
            path="discussion"
            element={
              <ProtectedRoute>
                <EventAccess>
                  <React.Suspense fallback={<Loader />}>
                    <Discussion />
                  </React.Suspense>
                </EventAccess>
              </ProtectedRoute>
            }
          />
          <Route
            path="search/tools"
            element={
              <ProtectedRoute>
                <EventAccess>
                  <React.Suspense fallback={<Loader />}>
                    <Tools />
                  </React.Suspense>
                </EventAccess>
              </ProtectedRoute>
            }
          />
          <Route
            path="blogs"
            element={
              <ProtectedRoute>
                <EventAccess>
                  <React.Suspense fallback={<Loader />}>
                    <Blogs />
                  </React.Suspense>
                </EventAccess>
              </ProtectedRoute>
            }
          />
          <Route
            path="blog/:id"
            element={
              <ProtectedRoute>
                <EventAccess>
                  <React.Suspense fallback={<Loader />}>
                    <Blog />
                  </React.Suspense>
                </EventAccess>
              </ProtectedRoute>
            }
          />
          <Route
            path="search/companies"
            element={
              <ProtectedRoute>
                <CompanyAccess>
                  <React.Suspense fallback={<Loader />}>
                    <InvestorDashboard />
                  </React.Suspense>
                </CompanyAccess>
              </ProtectedRoute>
            }
          />
          <Route
            path="search/investors"
            element={
              <ProtectedRoute>
                <CompanyProtectedRoute>
                  <React.Suspense fallback={<Loader />}>
                    <StartupDashboard />
                  </React.Suspense>
                </CompanyProtectedRoute>
              </ProtectedRoute>
            }
          />
          <Route
            path="search/mentor"
            element={
              <ProtectedRoute>
                <CompanyProtectedRoute>
                  <React.Suspense fallback={<Loader />}>
                    <MentorDashboard />
                  </React.Suspense>
                </CompanyProtectedRoute>
              </ProtectedRoute>
            }
          />
          <Route
            path="search/expert"
            element={
              <ProtectedRoute>
                <CompanyProtectedRoute>
                  <React.Suspense fallback={<Loader />}>
                    <ExpertDashboard />
                  </React.Suspense>
                </CompanyProtectedRoute>
              </ProtectedRoute>
            }
          />
          <Route
            path="my-jobs"
            element={
              <ProtectedRoute>
                <CompanyProtectedRoute>
                  <React.Suspense fallback={<Loader />}>
                    <MyJobs />
                  </React.Suspense>
                </CompanyProtectedRoute>
              </ProtectedRoute>
            }
          />
          <Route
            path="my-events"
            element={
              <ProtectedRoute>
                <EventAccess>
                  <React.Suspense fallback={<Loader />}>
                    <MyEvents />
                  </React.Suspense>
                </EventAccess>
              </ProtectedRoute>
            }
          />
          <Route
            path="my-project/:id"
            element={
              <ProtectedRoute>
                <InvestorProtectedRoute>
                  <React.Suspense fallback={<Loader />}>
                    <MyProject />
                  </React.Suspense>
                </InvestorProtectedRoute>
              </ProtectedRoute>
            }
          />

          <Route
            path="create-job"
            element={
              <ProtectedRoute>
                <CompanyProtectedRoute>
                  <React.Suspense fallback={<Loader />}>
                    <CreateJob />
                  </React.Suspense>
                </CompanyProtectedRoute>
              </ProtectedRoute>
            }
          />
          <Route
            path="create-event"
            element={
              <ProtectedRoute>
                <EventAccess>
                  <React.Suspense fallback={<Loader />}>
                    <CreateEvent />
                  </React.Suspense>
                </EventAccess>
              </ProtectedRoute>
            }
          />
          <Route
            path="edit-project/:id"
            element={
              <ProtectedRoute>
                <InvestorProtectedRoute>
                  <React.Suspense fallback={<Loader />}>
                    <EditProject />
                  </React.Suspense>
                </InvestorProtectedRoute>
              </ProtectedRoute>
            }
          />
          <Route
            path="search/projects"
            element={
              <ProtectedRoute>
                <StartupProtectedRoute>
                  <React.Suspense fallback={<Loader />}>
                    <ProjectDashboard />
                  </React.Suspense>
                </StartupProtectedRoute>
              </ProtectedRoute>
            }
          />

          <Route
            path="project-detail/:id"
            element={
              <ProtectedRoute>
                <StartupProtectedRoute>
                  <React.Suspense fallback={<Loader />}>
                    <MarketPlaceDetail />
                  </React.Suspense>
                </StartupProtectedRoute>
              </ProtectedRoute>
            }
          />
          <Route
            path="edit-job/:id"
            element={
              <ProtectedRoute>
                {/* <InvestorProtectedRoute> */}
                <React.Suspense fallback={<Loader />}>
                  <EditJob />
                </React.Suspense>
                {/* </InvestorProtectedRoute> */}
              </ProtectedRoute>
            }
          />
          <Route
            path="job-detail/:id"
            element={
              <ProtectedRoute>
                {/* <StartupProtectedRoute> */}
                <React.Suspense fallback={<Loader />}>
                  <JobDetail />
                </React.Suspense>
                {/* </StartupProtectedRoute> */}
              </ProtectedRoute>
            }
          />
          <Route
            path="my-job-detail/:id"
            element={
              <ProtectedRoute>
                {/* <StartupProtectedRoute> */}
                <React.Suspense fallback={<Loader />}>
                  <MyJobDetail />
                </React.Suspense>
                {/* </StartupProtectedRoute> */}
              </ProtectedRoute>
            }
          />
          <Route
            path="event-detail/:id"
            element={
              <ProtectedRoute>
                {/* <StartupProtectedRoute> */}
                <React.Suspense fallback={<Loader />}>
                  <EventDetail />
                </React.Suspense>
                {/* </StartupProtectedRoute> */}
              </ProtectedRoute>
            }
          />
          <Route
            path="my-event-detail/:id"
            element={
              <ProtectedRoute>
                {/* <StartupProtectedRoute> */}
                <React.Suspense fallback={<Loader />}>
                  <MyEventDetail />
                </React.Suspense>
                {/* </StartupProtectedRoute> */}
              </ProtectedRoute>
            }
          />
          <Route
            path="edit-event/:id"
            element={
              <ProtectedRoute>
                <EventAccess>
                  <React.Suspense fallback={<Loader />}>
                    <EditEvent />
                  </React.Suspense>
                </EventAccess>
              </ProtectedRoute>
            }
          />

          <Route
            path="startup-detail/:id"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={<Loader />}>
                  <StartupDetail />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="mentor-detail/:id"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={<Loader />}>
                  <MentorDetail />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="signal-detail/:id"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={<Loader />}>
                  <SignalDetail />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="expert-detail/:id"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={<Loader />}>
                  <ExpertDetail />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="investor-detail/individual/:id"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={<Loader />}>
                  <IndInvestorDetail />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="investor-detail/institution/:id"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={<Loader />}>
                  <InsInvestorDetail />
                </React.Suspense>
              </ProtectedRoute>
            }
          />

          <Route
            path="dashboard"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={<Loader />}>
                  <PovLayout />
                </React.Suspense>
              </ProtectedRoute>
            }
          >
            <Route
              path="received"
              element={
                <ProtectedRoute>
                  <React.Suspense fallback={<Loader />}>
                    <ReceivedApplications />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path="sent"
              element={
                <ProtectedRoute>
                  <React.Suspense fallback={<Loader />}>
                    <InterestsSent />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path="bookmarks"
              element={
                <ProtectedRoute>
                  <React.Suspense fallback={<Loader />}>
                    <Bookmarks />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/settings" element={<SettingsLayout />}>
            <Route
              path="userdetails"
              element={
                <ProtectedRoute>
                  <React.Suspense fallback={<Loader />}>
                    <UserDetails />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path="notification"
              element={
                <ProtectedRoute>
                  <React.Suspense fallback={<Loader />}>
                    <Notification />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path="message-settings"
              element={
                <ProtectedRoute>
                  <React.Suspense fallback={<Loader />}>
                    <MessageSettings />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path="delete-account"
              element={
                <ProtectedRoute>
                  <React.Suspense fallback={<Loader />}>
                    <Delete />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path="help"
              element={
                <ProtectedRoute>
                  <React.Suspense fallback={<Loader />}>
                    <Help />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path="change-password"
              element={
                <ProtectedRoute>
                  <React.Suspense fallback={<Loader />}>
                    <Password />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
          </Route>

          <Route
            path="/mentor-create"
            element={
              <MentorCreateProtectedRoutes>
                <MentorCreateLayout />
              </MentorCreateProtectedRoutes>
            }
          >
            <Route
              path="become-mentor"
              element={
                <ProtectedRoute>
                  <React.Suspense fallback={<Loader />}>
                    <BecomeMentor />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path="mentor-details"
              element={
                <ProtectedRoute>
                  <React.Suspense fallback={<Loader />}>
                    <MentorDetails />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path="mentor-review/:id"
              element={
                <ProtectedRoute>
                  <React.Suspense fallback={<Loader />}>
                    <MentorReview />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/mentor-create/accept-terms/:id"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={<Loader />}>
                  <MentorAcceptTerms />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="/expert-create"
            element={
              <MultiStartupAccount>
                <ExpertCreateLayout />
              </MultiStartupAccount>
            }
          >
            <Route
              path="become-expert"
              element={
                <ProtectedRoute>
                  <React.Suspense fallback={<Loader />}>
                    <BecomeExpert />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path="expert-details"
              element={
                <ProtectedRoute>
                  <React.Suspense fallback={<Loader />}>
                    <ExpertDetails />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path="expert-review/:id"
              element={
                <ProtectedRoute>
                  <React.Suspense fallback={<Loader />}>
                    <ExpertReview />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/expert-create/accept-terms/:id"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={<Loader />}>
                  <ExpertAcceptTerms />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="/startup-create"
            element={
              <MultiStartupAccount>
                <StartupCreateLayout />
              </MultiStartupAccount>
            }
          >
            <Route
              path="startup-details"
              element={
                <ProtectedRoute>
                  <React.Suspense fallback={<Loader />}>
                    <StartupDetails />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />

            <Route
              path="contact-details/:id"
              element={
                <ProtectedRoute>
                  <React.Suspense fallback={<Loader />}>
                    <ContactDetails />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path="funding-details/:id"
              element={
                <ProtectedRoute>
                  <React.Suspense fallback={<Loader />}>
                    <FundingDetails />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path="review/:id"
              element={
                <ProtectedRoute>
                  <React.Suspense fallback={<Loader />}>
                    <StartupReview />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/startup-create/accept-terms/:id"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={<Loader />}>
                  <StartupAcceptTerms />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="/investor-create"
            element={
              <MultiInvestorAccount>
                <InvestorCreateLayout />
              </MultiInvestorAccount>
            }
          >
            <Route
              path="type-of-investor"
              element={
                <ProtectedRoute>
                  <React.Suspense fallback={<Loader />}>
                    <TypeOfInvestor />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path="investor-details"
              element={
                <ProtectedRoute>
                  <React.Suspense fallback={<Loader />}>
                    <InvestorDetails />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path="investment-planning/:id"
              element={
                <ProtectedRoute>
                  <React.Suspense fallback={<Loader />}>
                    <InvestmentPlanning />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path="review/:id"
              element={
                <ProtectedRoute>
                  <React.Suspense fallback={<Loader />}>
                    <InvestorReview />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/investor-create/accept-terms/:id"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={<Loader />}>
                  <IndividualAcceptTerms />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="/institution-create"
            element={
              <MultiInvestorAccount>
                <InstitutionCreateLayout />
              </MultiInvestorAccount>
            }
          >
            <Route
              path="type-of-institution"
              element={
                <ProtectedRoute>
                  <React.Suspense fallback={<Loader />}>
                    <TypeOfInvestitution />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path="institution-details"
              element={
                <ProtectedRoute>
                  <React.Suspense fallback={<Loader />}>
                    <InstitutionDetails />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path="contact-details/:id"
              element={
                <ProtectedRoute>
                  <React.Suspense fallback={<Loader />}>
                    <InstitutionContactDetails />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path="investment-planning/:id"
              element={
                <ProtectedRoute>
                  <React.Suspense fallback={<Loader />}>
                    <InstitutionInvestmentPlanning />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path="review/:id"
              element={
                <ProtectedRoute>
                  <React.Suspense fallback={<Loader />}>
                    <InstitutionReview />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/institution-create/accept-terms/:id"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={<Loader />}>
                  <InstitutionAcceptTerms />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="/" element={<LandingLayout />}>
          <Route
            path="/landingpage"
            element={
              <React.Suspense fallback={<LandLoader />}>
                {/* <HomeRedirect> */}
                <LandingPage />
                {/* </HomeRedirect> */}
              </React.Suspense>
            }
          />
          <Route
            path="/landingpage/startup"
            element={
              <React.Suspense fallback={<LandLoader />}>
                {/* <HomeRedirect> */}
                <StartupLandingPage />
                {/* </HomeRedirect> */}
              </React.Suspense>
            }
          />
          <Route
            path="/landingpage/investor"
            element={
              <React.Suspense fallback={<LandLoader />}>
                {/* <HomeRedirect> */}
                <InvestorLandingPage />
                {/* </HomeRedirect> */}
              </React.Suspense>
            }
          />
          <Route
            path="/landingpage/mentor"
            element={
              <React.Suspense fallback={<LandLoader />}>
                {/* <HomeRedirect> */}
                <MentorLandingPage />
                {/* </HomeRedirect> */}
              </React.Suspense>
            }
          />
          <Route
            path="/landingpage/expert"
            element={
              <React.Suspense fallback={<LandLoader />}>
                {/* <HomeRedirect> */}
                <ExpertLandingPage />
                {/* </HomeRedirect> */}
              </React.Suspense>
            }
          />
          <Route
            path="/landingpage/partnership"
            element={
              <React.Suspense fallback={<LandLoader />}>
                {/* <HomeRedirect> */}
                <PartnershipLandingPage />
                {/* </HomeRedirect> */}
              </React.Suspense>
            }
          />
          <Route
            path="/aboutus"
            element={
              <React.Suspense fallback={<LandLoader />}>
                <AboutUs />
              </React.Suspense>
            }
          />
          <Route
            path="/faq"
            element={
              <React.Suspense fallback={<LandLoader />}>
                <Faq />
              </React.Suspense>
            }
          />
          <Route path="/legal-links" element={<LegalLayout />}>
            <Route
              path="privacy-policy"
              element={
                <React.Suspense fallback={<LandLoader />}>
                  <Privacy />
                </React.Suspense>
              }
            />
            <Route
              path="cookie-policy"
              element={
                <React.Suspense fallback={<LandLoader />}>
                  <CookiePolicy />
                </React.Suspense>
              }
            />
            <Route
              path="terms-and-conditions"
              element={
                <React.Suspense fallback={<LandLoader />}>
                  <LegalTerms />
                </React.Suspense>
              }
            />
          </Route>

          {/* <Route
            path="/imprint"
            element={
              <React.Suspense fallback={<LandLoader />}>
                <Imprint />
              </React.Suspense>
            }
          /> */}
          <Route
            path="/blog/:id"
            element={
              <React.Suspense fallback={<LandLoader />}>
                <Blog />
              </React.Suspense>
            }
          />
        </Route>

        <Route path="/" element={<HeaderLessLayout />}>
          <Route
            path="notifications"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={<Loader />}>
                  <Home />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
        </Route>

        <Route path="/" element={<SearchLayout />}>
          <Route
            path="search"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={<Loader />}>
                  <Home />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
        </Route>

        <Route
          path="*"
          element={
            <React.Suspense fallback={<Loader />}>
              <NotFound />
            </React.Suspense>
          }
        />
      </Routes>
    </React.Fragment>
  );
};

function LandLoader() {
  return (
    <div
      className="d-flex items-center justify-center mt-20"
      style={{
        height: "100vh",
        zIndex: "100",
        margin: "auto",
        background: "#fff",
        width: "100%",
      }}
    >
      <CircularProgress fontSize="small" />
    </div>
  );
}
function Loader() {
  return (
    <div
      className="d-flex items-center justify-center mt-20"
      style={{
        height: "100vh",
        zIndex: "100",
        margin: "auto",
        width: "100%",
      }}
    >
      <CircularProgress fontSize="small" />
    </div>
  );
}

export default index;
