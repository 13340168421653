import {
  Checkbox,
  Typography,
  TextField,
  Stack,
  Modal,
  Box,
  Popover,
  Divider,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import DoneIcon from "@mui/icons-material/Done";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import useClickOutside from "../../../../../utils/useClickOutside";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import React, { useEffect, useState, useRef } from "react";
import axiosApi from "../../../../../utils/httpClient";
import { updateTask } from "../../../../../redux/tasks/tasks/tasksSlice";
import Tag from "../Tag";
import {
  updateTaskData,
  deleteTask,
} from "../../../../../redux/tasks/tasks/tasksSlice";
import { listsAPI } from "../../../../../redux/tasks/lists/listsSlice";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import leftTime from "../../../../../utils/leftTime";
import getRelativeDate from "../../../../../utils/getRelativeDate";
import EditTags from "./EditTags";
import ConfirmationPop from "../../../../ConfirmationsPopup";
const Index = ({ data, selectedTask, setSelectedTask }) => {
  const dispatch = useDispatch();
  const clickRef = useRef(null);
  const [moveToList, setMoveToList] = useState(null);
  const lists = useSelector((store) => store.lists.lists);
  const [dueDate, setDueDate] = useState("");
  const [openDate, setOpenDate] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [taskName, setTaskName] = useState("");
  const [description, setDescription] = useState("");
  const mode = useSelector((store) => store.mode.mode);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const handleOpenDelete = () => setDeleteOpen(true);
  const handleCloseDelete = () => setDeleteOpen(false);
  const [openPopup, setOpenPopup] = useState(false);
  const closePopup = () => {
    setOpenPopup(false);
  };
  const [openMovePopup, setOpenMovePopup] = useState(false);
  const closeMovePopup = () => {
    setOpenMovePopup(false);
  };
  const style = {
    position: "absolute",
    bottom: "60px",
    right: "60px",
    outline: "none",
    width: "500px",
    margin: "auto",
    border: "none",
  };
  const statusChange = async () => {
    const status = data?.status == "completed" ? "created" : "completed";
    const resp = axiosApi.patch(`task/${data._id}`, {
      status: status,
    });
    dispatch(
      updateTask({
        id: data._id,
        status: status,
      })
    );
  };
  useEffect(() => {
    setTaskName(data?.name);
    setDescription(data?.description);
    setSelectedTags(data?.tags);
    setDueDate(data?.dueDate);
    dispatch(listsAPI());
  }, []);
  const deleteTaskFunc = async () => {
    const resp = await axiosApi.delete(`task/${data?._id}`);
    await handleCloseDelete();
    await dispatch(deleteTask({ id: data?._id }));
  };
  const style1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
    maxHeight: "500px",
    margin: "auto",
    border: "none",
    p: 2,
  };
  const updateTaskFunction = async (list) => {
    const formData = {};
    let selectedTagss = selectedTags.map((item) => item._id);
    formData["tags"] = selectedTagss;
    formData["description"] = description;
    formData["name"] = taskName;
    formData["dueDate"] = dueDate;
    if (list) {
      formData["list"] = list;
    }
    const resp = await axiosApi.patch(`/task/${data?._id}`, formData);
    await dispatch(
      updateTaskData({
        id: data?._id,
        data: resp.data.data,
      })
    );
  };
  // useClickOutside(clickRef, () => {
  //   if (selectedTask) {
  //     updateTaskFunction();
  //     setSelectedTask("");
  //   }
  // });
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      updateTaskFunction();
      setSelectedTask("");
    }
  };
  return (
    <React.Fragment>
      <Modal
        open={deleteOpen}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style1} className={mode ? "dark-bg-1" : "light-bg-2"}>
          <ConfirmationPop
            onSubmit={deleteTaskFunc}
            onCancel={handleCloseDelete}
            message={"Do you want to delete the task?"}
            title={"Delete Task"}
          />
        </Box>
      </Modal>
      <Stack
        onKeyDown={handleKeyDown}
        // ref={clickRef}
        spacing={2}
        sx={{
          p: 1,
          border: `1px solid #77788c`,
          position: "relative",
        }}
        onClick={(e) => {
          e.stopPropagation();
          setSelectedTask("");
          updateTaskFunction();
        }}
      >
        {/* <Box
          style={{
            position: "absolute",
            bottom: "8px",
            right: "8px",
            zIndex: "10",
          }}
        >
          <DeleteOutlineIcon
            sx={{
              cursor: "pointer",
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleOpenDelete();
            }}
          />
        </Box> */}
        <Stack
          direction="row"
          justifyContent={"space-between"}
          sx={{
            height: "100%",
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Checkbox
              checked={data?.status == "completed" ? true : false}
              sx={{
                p: 0,
              }}
              onClick={(e) => {
                e.stopPropagation();
                statusChange();
              }}
            />
            <Typography fontSize="12px" className={"dark-2"}>
              Mark as complete
            </Typography>
          </Stack>
          <MoreVertIcon
            sx={{
              cursor: "pointer",
            }}
            onClick={(e) => {
              e.stopPropagation();
              setOpenPopup(true);
              setAnchorEl(e.currentTarget);
            }}
          />
          <Popover
            open={openPopup}
            anchorEl={anchorEl}
            onClose={closePopup}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{
              maxWidth: "70%",
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Stack
              className={mode ? "dark-bg-9" : "light-bg-9"}
              direction="column"
              spacing={1}
              divider={<Divider orientation="horizontal" />}
              sx={{
                p: 1,
              }}
            >
              <Typography
                fontSize="16px"
                sx={{
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenPopup(false);
                  handleOpenDelete();
                }}
              >
                Delete
              </Typography>
              <Typography
                fontSize="16px"
                sx={{
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenMovePopup(true);
                  setAnchorEl1(e.currentTarget);
                }}
              >
                Move
              </Typography>
              <Popover
                open={openMovePopup}
                anchorEl={anchorEl1}
                onClose={closeMovePopup}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                sx={{
                  maxWidth: "70%",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Stack
                  className={mode ? "dark-bg-8" : "light-bg-9"}
                  direction="column"
                  spacing={1}
                  divider={<Divider orientation="horizontal" />}
                  sx={{
                    p: 1,
                  }}
                >
                  {lists &&
                    lists?.length > 0 &&
                    lists?.map((item) => (
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Typography
                          fontSize="12px"
                          sx={{
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpenMovePopup(false);
                            setOpenPopup(false);
                            updateTaskFunction(item._id);
                            setSelectedTask("");
                            // moveList(item._id);
                          }}
                        >
                          {item?.name}
                        </Typography>
                        {data?.list === item.name && <DoneIcon />}
                      </Stack>
                    ))}
                </Stack>
              </Popover>
            </Stack>
          </Popover>
        </Stack>
        <Stack spacing={1}>
          <TextField
            value={taskName}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => {
              e.stopPropagation();
              setTaskName(e.target.value);
            }}
            type="text"
            id="description"
            name="description"
            fullWidth
            placeholder="Enter task name"
            InputProps={{
              style: {
                padding: "0rem",
              },
              maxLength: 300,
              classes: {
                input: `input-nopadding ${
                  mode ? "inputFieldDark" : "inputFieldLight"
                }`,
              },
              className: `${mode ? "dark-bg-2 " : "light-bg-2 "}`,
            }}
          />
          {data.group ? (
            <Typography fontSize="12px" className={mode ? "dark-2" : "light-2"}>
              {data?.group}
              {data?.list && " ⮞ " + data?.list}
            </Typography>
          ) : (
            <>
              {data?.list && (
                <Typography
                  fontSize="12px"
                  fontWeight="500"
                  className={mode ? "dark-2" : "light-2"}
                >
                  {data?.list}
                </Typography>
              )}
            </>
          )}
        </Stack>

        <TextField
          value={description}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(e) => {
            e.stopPropagation();
            setDescription(e.target.value);
          }}
          type="text"
          id="description"
          name="description"
          fullWidth
          multiline
          rows={2}
          placeholder="add description"
          InputProps={{
            style: {
              padding: "0rem",
              fontSize: "12px",
            },
            maxLength: 300,
            classes: {
              input: `dark-2 input-nopadding ${
                mode ? "inputFieldDark" : "inputFieldLight"
              }`,
            },
            className: `${mode ? "dark-bg-2 " : "light-bg-2 "}`,
          }}
        />

        <EditTags
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
        />
        {openDate && (
          <Stack
            sx={{
              width: "230px",
            }}
          >
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <DateTimePicker
                onDayClick={(e) => {
                  e.stopPropagation();
                }}
                minDate={new Date()}
                inputFormat="yyyy-MM-dd HH:mm"
                value={dueDate}
                onChange={(e) => {
                  e.stopPropagation();
                  setDueDate(e);
                }}
                components={{
                  OpenPickerIcon: CalendarMonthIcon,
                }}
                renderInput={(params) => (
                  <TextField
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    id="dueDate"
                    name="dueDate"
                    className={`${mode ? "dark-bg-2 " : "light-bg-2 "}`}
                    value={dueDate}
                    InputProps={{
                      ...params.InputProps,
                      classes: {
                        input: `input-nopadding ${
                          mode ? "inputFieldDark" : "inputFieldLight"
                        }`,
                      },
                      style: {
                        fontSize: "12px",
                        padding: "0rem",
                        width: "400px",
                      },
                    }}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Stack>
        )}

        {data.dueDate ? (
          <>
            {!openDate && (
              <Stack direction="row" spacing={2}>
                <Typography
                  fontSize="12px"
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenDate(true);
                  }}
                >
                  {getRelativeDate(new Date(data.dueDate))}
                </Typography>
                {data.dueDate &&
                  leftTime(new Date(data.dueDate)) !==
                    getRelativeDate(new Date(data.dueDate)) && (
                    <Stack
                      direction="row"
                      spacing={1}
                      alignItems="center"
                      className={mode ? "dark-3" : "light-2"}
                    >
                      <CalendarMonthIcon fontSize="small" />
                      <Typography fontSize="12px">
                        {leftTime(new Date(data.dueDate))}
                      </Typography>
                    </Stack>
                  )}
              </Stack>
            )}
          </>
        ) : (
          <>
            {!openDate && (
              <Typography
                fontSize="12px"
                className="dark-3"
                sx={{
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenDate(true);
                }}
              >
                Due Date
              </Typography>
            )}
          </>
        )}
      </Stack>
    </React.Fragment>
  );
};

export default Index;
