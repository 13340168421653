import {
  Box,
  Typography,
  Stack,
  Button,
  TextField,
  Popover,
} from "@mui/material";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import axiosApi from "../../../../utils/httpClient";
import AddTagBlock from "../../AddTagBlock";
import SelectGroup from "../SelectGroup";
import { addList } from "../../../../redux/tasks/lists/listsSlice";
import { addGroupList } from "../../../../redux/tasks/groups/lists/listsSlice";
const Index = ({ onClose }) => {
  const mode = useSelector((store) => store.mode.mode);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [listName, setListName] = useState("");
  const [selectedGroup, setSelectedGroup] = useState(
    searchParams.get("group") ? searchParams.get("group") : "-1"
  );
  const createList = async () => {
    const formData = {};

    if (selectedGroup !== "-1") {
      formData["group"] = {
        _id: selectedGroup,
      };
    }

    formData["name"] = listName;
    const resp = await axiosApi.post(`/list`, formData);
    if (!resp.data.data.group) {
      await dispatch(addList({ data: resp.data.data }));
    } else {
      if (resp.data.data.group === searchParams.get("group")) {
        await dispatch(addGroupList({ data: resp.data.data }));
      }
    }

    onClose();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      createList();
    }
  };
  return (
    <React.Fragment>
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{
            background: "#ee4b6a",
            width: "100%",
            py: 1,
            px: 2,
            color: "#fff",
          }}
        >
          {" "}
          <Stack direction="row" spacing={2}>
            <LibraryAddIcon />
            <Typography fontSize="16px">Create List</Typography>
          </Stack>{" "}
          <CloseIcon
            onClick={onClose}
            sx={{
              cursor: "pointer",
            }}
          />
        </Stack>
        <Stack
          spacing={2}
          sx={{
            p: 2,
          }}
        >
          <TextField
            onKeyDown={handleKeyDown}
            value={listName}
            onChange={(e) => {
              setListName(e.target.value);
            }}
            type="text"
            id="description"
            name="description"
            fullWidth
            placeholder="Enter list name"
            InputProps={{
              maxLength: 300,
              classes: {
                input: `${mode ? "inputFieldDark" : "inputFieldLight"}`,
              },
              className: `${mode ? "dark-bg-2 " : "light-bg-2 "}`,
            }}
          />

          <SelectGroup
            setSelectedGroup={setSelectedGroup}
            selectedGroup={selectedGroup}
          />

          <Stack
            direction="row"
            justifyContent="flex-end"
            sx={{
              width: "100%",
            }}
          >
            <Button
              color="primary"
              variant="contained"
              disabled={!listName?.length > 0}
              onClick={createList}
            >
              Create List
            </Button>
          </Stack>
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default Index;
