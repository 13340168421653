import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as qs from "qs";
import axiosApi from "../../../utils/httpClient";
const name = "lists";
const initialState = {
  lists: [],
  loading: false,
  error: null,
};

export const listsAPI = createAsyncThunk("lists", async () => {
  const data = await axiosApi.get(`${process.env.REACT_APP_BASE_URL}list/`);
  return data.data;
});
export const addList = createAsyncThunk("lists/addList", async ({ data }) => {
  return { data };
});

export const updateListData = createAsyncThunk(
  "lists/updateListData",
  async ({ id, data }) => {
    return { id, data };
  }
);

export const deleteList = createAsyncThunk(
  "lists/deleteList",
  async ({ id }) => {
    return { id };
  }
);

const listsSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(listsAPI.pending, (state) => {
      state.lists = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(listsAPI.fulfilled, (state, action) => {
      state.lists = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(listsAPI.rejected, (state, action) => {
      state.lists = [];
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(deleteList.fulfilled, (state, action) => {
      const filteredLists = state.lists.filter(
        (item) => item._id !== action.payload.id
      );
      state.lists = filteredLists;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(updateListData.fulfilled, (state, action) => {
      const objIndex = state.lists.findIndex(
        (obj) => obj._id == action.payload.id
      );
      state.lists[objIndex] = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(addList.fulfilled, (state, action) => {
      state.lists = [action.payload.data, ...state.lists];
      state.loading = false;
      state.error = null;
    });
  },
});

export default listsSlice.reducer;
