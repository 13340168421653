import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const name = "selectedEventsCategories";
const initialState = {
  selectedEventsCategories: [],
  loading: false,
  error: null,
};

export const selectEventsCategories = createAsyncThunk(
  "eventscategories/selected",
  async (categories) => {
    return categories;
  }
);

const selectedEventsCategoriesSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(selectEventsCategories.pending, (state) => {
      state.selectedEventsCategories = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(selectEventsCategories.fulfilled, (state, action) => {
      state.selectedEventsCategories = action.payload;
    });
    builder.addCase(selectEventsCategories.rejected, (state, action) => {
      state.selectedEventsCategories = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default selectedEventsCategoriesSlice.reducer;
